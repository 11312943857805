@import "../globle/css-variables.scss";

.logo {
  height: 20px;
  width: auto;
}
.navHeader {
  padding: 0px 10px;
  justify-content: space-between;
}
.appName {
  display: inline-block;
  margin-left: 10px;
  color: var(--white);
  font-size: 22px;
  font-weight: 500;
  text-decoration-line: none;
}
.appVersionName {
  font-size: 11px;
  vertical-align: middle;
}
.pageName {
  font-size: 24px;
  font-weight: 400;
}
.logo-container {
  display: inline-flex;
  padding: 5px;
  margin-right: 5px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.logo-container:hover {
  text-decoration: none;
}
.links {
  color: var(--white);
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  display: block;
  padding: 0.5rem 1rem;
  font-size: 20px;
}
.links:hover {
  color: var(--white);
  background-color: #be2216cc;
  border-radius: 5px;
  text-decoration: none;
}
.dBtn {
  margin-right: 5px;
}
.sBtn {
  margin-left: 20px;
}
.headerIcon {
  color: var(--white);
  border-color: transparent;
  display: flex;
  background-color: transparent;
  border: 1px transparent;
  padding: 5px 10px;
  font-size: 23px;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.drag-handle {
  cursor: move;
}

.sBtn:hover,
.dBtn:hover,
.profileBtn:hover {
  background-color: #be2216cc;
}
.MuiBadge-badge {
  background-color: var(--white);
  color: var(--primary);
}

//search popUp Css
.search {
  margin-left: auto;
}
#searchPopUp {
  font-size: 18px;
  font-family: $rogers-font;
  max-width: 710px;
}

.searchExtLinkPopUp {
  font-size: 18px;
  font-family: $rogers-font;
  max-width: 700px;
  max-height: 600px;
}
.searchPopHead {
  background-color: var(--primary);
  color: var(--white);
  font-weight: 700;
  align-items: center;
}
.sPopUpClose {
  background-color: transparent;
  border: transparent;
}
.searchPopContainer {
  margin-left: 20px;
}
.sPoplable {
  font-size: 12px;
  font-weight: 400;
}
div.searchPopRadio .MuiTypography-body1 {
  font-size: 12px;
}
.sPopcat {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 20px;
}
.sPopcatFirst {
  font-size: 12px;
  font-weight: 600;
}
#searchPopDd {
  display: flex;
  margin-top: 5px;
  background-color: transparent;
  border: 1px solid var(--pureBlack);
  color: var(--pureBlack);
  width: 150px;
  justify-content: space-between;
  align-items: center;
}
.sPopcat label,
.sPopcatFirst label {
  margin-bottom: 0px;
}
.searchPopValue {
  font-size: 14px;
  border: transparent;
  font-weight: 600;
  height: 35px;
}
.searchPopValue:focus {
  outline: none;
  box-shadow: none;
}
.sPopBtn,
.sPopCloseBtn {
  border: transparent;
  border-radius: 5px;
}
.sPopBtn {
  width: 20%;
  background-color: var(--lightGreen);
  color: var(--white);
  font-size: 12px;
  padding: 5px;
}
.sPopCloseBtn {
  width: 15%;
  background-color: transparent;
  color: var(--link);
  text-decoration: underline;
  font-size: 12px;
  padding: 5px;
}

///SearchResult CSS
.SearchResultCont {
  padding-left: 35px;
  margin: 15px 0px;
}
.SearchResultCont p {
  margin-bottom: 5px;
  font-size: 17px;
  font-weight: 600;
}
.resultCard {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border-color: var(--black75);
  border-radius: 10px;
  margin-bottom: 15px;
  width: 70%;
}
.resultCard:hover {
  color: var(--black75);
  background-color: var(--backgroundLight);
  cursor: pointer;
}
.resultCardIcon {
  display: inline-flex;
  margin: 0px 10px;
}
.resultCardIcon img {
  width: 35px;
}
.resultCardDetails {
  padding: 5px;
  display: inline-block;
}
.resultCardDetails h5 {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}
.resultCardDetails h5:first-child {
  font-size: 14px;
  font-weight: 700;
}
.resultCardDetails span {
  display: inline-block;
  font-weight: 400;
  margin-right: 15px; //Added for node to display RPHY
}
.btnWidth {
  width: 60px;
  height: 43px;
}
.profileTriangle {
  margin-left: -6px;
  margin-top: -4px;
  color: white;
}
.feedbackSrcImg {
  width: 25px;
  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(189deg)
    brightness(106%) contrast(101%);
}
.externalLinkIcon {
  width: 25px;
}
.searchBtnWidth {
  font-size: 25px;
}
.profileBtn {
  margin-left: 15px;
  padding: 3px 10px;
  display: flex;
  width: 70px;
  justify-content: space-between;
  align-items: center;
}
/* .profileMenu div.MuiMenu-paper {
box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
  0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
} */
.profileMenu ul.MuiList-padding {
  padding: 0px;
}

@media screen and (max-width: 550px) and (min-width: 425px) {
  .navHeader {
    padding: 0px 5px;
    margin-left: 0px;
  }
  .sBtn,
  .profileBtn {
    margin-left: 0px;
  }
}

.notificationbar-container {
  height: 30px;
  margin: 0 auto;
  margin-left: 5px;
  width: 40%;
  //white-space: nowrap;
  //flex-grow: 4;
  margin-left: 28%;
  resize: horizontal;
  margin-right: auto;
  overflow: hidden;
  position: absolute;
  color: white;
}
.notificationbar-container p {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 30px;
  text-align: center;
  /* Starting position */
  transform: translateX(50%);
  transform: translateX(-90%);
  /* Apply animation to this element */
  animation: scroll-left 15s linear infinite;
}
.notificationbar-container p:hover {
  opacity: 0.7;
}
/* Move it (define the animation) */
@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 424px) and (min-width: 320px) {
  @media screen and (max-width: 424px) and (min-width: 370px) {
    .navHeader {
      padding: 0px;

      margin-left: 0px;
    }

    .sBtn,
    .profileBtn {
      margin-left: 0px;
    }

    .headerIcon {
      padding: 5px;
    }
  }
  @media screen and (max-width: 370px) {
    .navHeader {
      padding: 0px;
      margin-left: 0px;
    }
    .sBtn,
    .profileBtn {
      margin-left: 0px;
    }
    .headerIcon {
      padding: 0px;
    }
    .appName {
      margin-left: 5px;
      font-size: 20px;
    }
    .appVersionName {
      font-size: 9px;
    }
  }
}
