@import "../../../design-tokens/globle/css-variables.scss";

.clickFltInputCont{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.clickFltInputCont .inputItemsCont{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-right: 15px;
}
.clickFltInputCont .inputItemsCont input{
  border-radius: 5px;
  border: 0.3px solid var(--greyMedium);
  padding: 1.5px 3px
}
.clickMarkerLabel {
    font-weight: 600;
    padding: 4px;
    background-color: var(--clickService60);
    color: var(--white);
  }

.clickClusterMarkerLabel {
    font-weight: 600;
    background-color: var(--clickService60);
    padding: 4px;
    min-width: 20px;
  }

.batchTimeStamp > span:nth-child(1){
  font-weight:600;
}

.batchTimeStamp > span:nth-child(2){
  font-weight:400;
  font-size:0.9rem;
}

.clickSearchFilter {
  display: flex;
  overflow: hidden;
  width: 50%;
  align-items: center;
  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 3px 5px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.clickSearchFilter input {
  outline: none;
  border: none;
  font-size: 13px;
}
