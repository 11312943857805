@import "../globle/css-variables.scss";

.itemBoxWide {
  margin-right: 15px;
  border: 3px solid var(--greyLight);
  border-radius: 11.5px;
  border-style: solid;
  padding-right: 10px;
  padding-left: 10px;
}

.itemBoxWide:hover {
  color: var(--primary);
  border-color: var(--primary);
}

.title {
  font-weight: bold;
  margin: 0px;
}

.modalBody {
  align-items: center;
  display: flex;
}

.itemBoxWide .img {
  height: 25px;
  width: 35px;
  margin: 5px;
}
