@import "../../../design-tokens/globle/css-variables.scss";

.layerCont {
  position: absolute;
  bottom: 80px;
  left: 10px;
}
.layerCont .layerIconCont {
  background-color: var(--white);
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  padding: 10px;
  cursor: pointer;
}
.layerCont .layerIconCont svg {
  width: 25px;
  height: 25px;
}
.layerCont .layerIconCont:hover {
  background-color: var(--greyLight);
}
.layerCont .layerItemsCont {
  padding: 10px 10px 0.1px 10px;
  background-color: var(--white);
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.25);
  transition: 500ms;
  max-height: 400px;
  max-width: 300px;
}
.layerCont .closedLayerItemsCont {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  transition: 500ms;
  max-height: 0px;
  max-width: 0px;
  overflow: hidden;
}
.layerCont .layerSingleItemCont {
  margin-bottom: 10px;
  cursor: pointer;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.layerCont .layerSingleItemCont img {
  width: 60px;
  height: 40px;
}
.layerCont .layerSingleItemCont:hover {
  color: var(--primary);
}
.layerCont .layerSingleItemCont span {
  margin-left: 5px;
  font-weight: 500;
}
.layerCont .selectedLayerSingleItemCont {
  margin-bottom: 10px;
  cursor: pointer;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.layerCont .selectedLayerSingleItemCont img {
  width: 60px;
  height: 40px;
}
.layerCont .selectedLayerSingleItemCont span {
  margin-left: 5px;
  font-weight: 700;
  color: var(--primary);
}
.layerCont .selectedLayerSingleItemCont .imgBorder {
  padding: 1px;
  border: 1.5px solid var(--primary);
}
.layerCont .layerSingleItemCont .imgBorder {
  padding: 1px;
  border: 1.5px solid transparent;
}

.userLocationCont {
  position: absolute;
  bottom: 190px;
  right: 10px;
  background-color: var(--white);
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.userLocationCont svg {
  width: 25px;
  height: 25px;
  color: var(--greyDark);
}

.chartPopUpCont {
  font-family: $rogers-font;
  overflow: auto;
  background: white;
  border-radius: 5px;
}
.chartPopUpCont b {
  font-weight: 700;
}

.chartPopUpCont section.chChartCont {
  box-shadow: none;
  padding: 0px;
}

.chartPopUpCont div.chChartBody {
  margin-top: 10px;
  margin-right: 10px;
}
.chartPopUpCont div.recharts-responsive-container {
  min-width: 35vw !important;
}

div.chartActionCont {
  display: flex;
  flex-direction: row;
  align-items: center;
}
div.chartActionCont span.chartCloseBtn {
  padding: 5px;
  margin-right: 5px;
}
div.chartActionCont span.chartCloseBtn:hover {
  background-color: var(--primary);
  color: var(--white);
  border-radius: 5px;
}

div.chartActionCont span.chartCloseBtn svg {
  font-size: 25px;
  cursor: pointer;
}
div.chartPopUpCont p.cmChDetailHead {
  margin-top: 5px;
}
div.chartPopUpCont div.chChartBody {
  margin-top: 0px;
}

.addMemoCont {
  position: absolute;
  bottom: 290px;
  right: 10px;
  background-color: var(--white);
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.addMemoCont svg {
  width: 25px;
  height: 25px;
  color: var(--greyDark);
}
