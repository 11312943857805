@import "../globle/css-variables.scss";
@import "./Forms.scss";

.formCheckboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.formCheckboxContainer label {
  flex: 1;
  margin-right: 30px;
  font-size: 14px;
}
.titleLabel {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 16px;
}
.formHeadingTitle {
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 10px;
}
.hazardsCosntainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.viewPJIContainer {
  align-self: center;
  margin: 25px;
}
.viewPJIContainer .titleLabel {
  font-weight: 700;
  font-size: 18px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.viewPJIContainer .textLabel {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
}
.viewPJIContainer .textValue {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.smallLabel {
  font-weight: 500;
  font-size: 11px;
  margin-top: 5px;
}

.WorkDetailscontainer {
  width: 100%;
  display: contents;
}
.WorkDetailscontainer #dropdownBtn {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 14px;
}

.WorkDetailscontainer .dropdown-menu {
  font-size: 13px;
}

.stepNextBtn {
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
}
.stepNextBtn:hover {
  background: var(--greyLight);
}
.stepNextBtn:focus {
  background: var(--primary);
}

.pjiSubmitBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.pjiSubmitButton,
.pjiSubmitButton:hover {
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid;
  padding: 5px 15px;
  font-size: 15px;
}

.pjiSubmitButton:disabled,
.pjiSubmitButton.disabled {
  background-color: var(--disabled);
  color: var(--white);
  border: 1px solid var(--disabled);
  font-size: 15px;
}
