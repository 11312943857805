@import "../globle/css-variables.scss";
.accTabs {
  padding: 5px 0px;
}

.accTabs .allAccountsNav {
  border-bottom: 2px solid #00000024;
  display: flex;
  align-items: center;
}
.allAccountsNav div {
  display: flex;
  align-items: center;
  margin-right: 1px;
  padding: 7px 15px;
  margin-bottom: -2px;
  border-radius: 2px;
  border: 2px solid var(--pureBlack);
}
.accTabs .accTab-active-Btn {
  color: var(--black75);
  background-color: var(--background);
  font-size: 14px;
  font-weight: 700;
  border-color: #00000024 #00000024 var(--background);
}
.accTabs .accTab--Btn {
  color: var(--greyMedium);
  font-size: 14px;
  font-weight: 600;
  border: 2px solid transparent;
}
.accTabs .accTab--Btn svg,
.accTabs .accTab-active-Btn svg {
  margin-left: 10px;
  padding: 3px;
  font-size: 20px;
  border-radius: 50%;
}
.accTabs .accTab--Btn svg:hover,
.accTabs .accTab-active-Btn svg:hover {
  background-color: var(--black10);
}
.accTabs .accTab--Btn:hover {
  border-color: #00000024 #00000024 transparent #00000024;
  cursor: pointer;
}
.custCountainer,
.custCountainerSm {
  font-family: $rogers-font;
  padding: 10px;
  border-bottom: 1px solid var(--greyLight);
}

.custInfo {
  display: inline-flex;
  width: 100%;
  align-items: center;
}
.accTopology {
  font-size: 11px;
  border-radius: 5px;
  background-color: var(--link);
  padding: 3px 5px;
  color: var(--white);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
#custMenu {
  font-size: 20px;
  color: var(--pureBlack);
}
.accDetails {
  font-size: 12px;
  font-weight: 400;
  width: 50%;
  display: flex;
  flex-direction: row;
}
.accDetailsSm:first-child {
  font-size: 12px;
  font-weight: 400;
  width: 35%;
}
.accDetailsSm:last-child {
  font-size: 12px;
  font-weight: 400;
  width: 65%;
  display: flex;
  justify-content: space-between;
}
.accDetails p,
.accDetailsSm p {
  margin-bottom: 0px;
}
.accDetails span,
.accDetailsSm span {
  margin: 3px;
}
.accDetails span:nth-child(odd),
.accDetailsSm span:nth-child(odd) {
  font-weight: 600;
}
.basicAccDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.basicAccDetails hr.MuiDivider-vertical {
  height: 15px !important;
}
.accDetailsActions {
  font-size: 13px;
  font-weight: 400;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formsSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

div.subAccDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;
}

p.nodeDetailsCont {
  display: flex;
  align-items: center;
}
p.nodeDetailsCont hr.MuiDivider-vertical {
  height: 15px !important;
}
p.nodeDetailsCont .nDetailsLabel {
  font-weight: 600;
  margin: 0px 3px;
}
p.nodeDetailsCont .nDetailsValue {
  font-weight: 400;
  margin: 0px 3px;
}

div.subAccDetails:first-child {
  min-width: 35%;
}
div.subAccDetails:last-child {
  min-width: 65%;
}
#custName,
#custNameEx {
  font-size: 20px;
  font-weight: 700;
}
#custName {
  margin-bottom: 5px;
}
#custNameEx {
  margin-bottom: 0px;
}
.sADropd {
  display: inline-flex;
}
div.sADropd button {
  background-color: transparent;
  border: none;
  color: inherit;
  padding: 0px;
  font-size: inherit;
}
#sAddressDd,
#pCStatusDd,
#sAddressDdSm {
  background: transparent;
  color: var(--pureBlack);
  border: transparent;
  font-weight: 400;
}

div.custInfo hr.MuiDivider-vertical {
  width: 2px;
  height: auto;
  margin: 0px 10px;
}
.custTglBtnCont {
  position: absolute;
  text-align: center;
  margin-top: -1px;
  transition: 500ms;
}
main.main-section .custTglBtnCont {
  width: calc(95vw - 5%);
}
main.main-sm-section .custTglBtnCont {
  width: calc(95vw - 15%);
}
.custTglBtn {
  height: 25px;
  border-bottom-left-radius: 100px;
  border: 1px solid var(--greyLight);
  border-bottom-right-radius: 100px;
  width: 125px;
  font-size: 14px;
  font-weight: 500;
  font-family: $rogers-font;
}
.custTglBtn:active {
  transform: translateY(1px);
}
.accTabsBar {
  padding-top: 0px;
  padding-left: 20px;
}
.accTabsCont {
  background-color: var(--background);
  min-height: 94vh;
}
header.MuiAppBar-colorPrimary {
  color: var(--pureBlack);
  background-color: var(--white);
}
div.accTabsCont div.MuiTabs-root,
div.accTabsCont button.MuiTab-root {
  min-height: auto;
}
div.accTabsCont button.MuiTab-root {
  font-size: 12px;
  font-weight: 700;
}
button.MuiTab-textColorSecondary.Mui-selected {
  color: var(--pureBlack);
}
// span.PrivateTabIndicator-colorSecondary-3 {
//   background-color: var(--primary);
// } //Team restrict this css code for a specific component, the css impacts every component, thats the reason for commenting it.

.tableHeaderWithRefresh {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 25px;
}
.tableHeader {
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
}
.refreshCont {
  text-align: end;
}
.refreshBtn {
  margin-top: 10px;
  background-color: var(--background);
  border: none;
  border-radius: 4px;
  color: var(--black75);
  box-shadow: 0px 0px 2px var(--black75);
}
.refreshBtn svg {
  font-size: 20px;
}
.workOrderRefreshBtn {
  margin-top: 16px;
  margin-left: 53px;
  background-color: var(--link) !important;
  color: var(--white) !important;
  border: 1px solid var(--link) !important;
}
.blueHollowBtn {
  background-color: transparent;
  border: 1px solid var(--link);
  border-radius: 5px;
  color: var(--link);
  font-size: 14px;
  margin: 0px 15px;
}
.blueHollowBtn:hover:enabled {
  background-color: var(--link);
  color: var(--white);
}
.blueHollowBtn:disabled:hover {
  border: 1px solid var(--greyLight);
}
.blueHollowBtn:disabled {
  border: 1px solid var(--greyLight);
}
.marginTop10 {
  margin: 10px 0px 0px 0px;
}
.modalExternalAppMenu {
  width: 50%;
  position: absolute;
  top: 15%;
  right: 25%;
}

.topContainer {
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  margin-bottom: 20px;
}

.bottonCont {
  flex-direction: row;
  align-items: flex-start;
  justify-items: flex-start;
  margin-bottom: 10px;
}

.externalAppText {
  font-family: $rogers-font;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.knowledgeBaseText {
  font-family: $rogers-font;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  justify-items: flex-end;
}

.applicationBox {
  color: rgba(0, 0, 0, 0.5);
  height: 95px;
  width: 80px;
  flex-direction: column;
  background-color: transparent;
  padding: 10px 15px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  align-items: center;
  font-size: 15px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.applicationBox:hover {
  color: var(--primary);
  border-color: var(--primary);
}

.applicationBoxWide {
  color: rgba(0, 0, 0, 0.5);
  height: 95px;
  width: 110px;
  flex-direction: column;
  background-color: transparent;
  padding: 10px 15px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  align-items: center;
  font-size: 15px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.applicationBoxWide:hover {
  color: var(--primary);
  border-color: var(--primary);
}

.loginFixContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.warningTitle {
  color: var(--danger);
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
  font-style: italic;
}

.succes {
  color: var(--veryGood);
}

.error {
  color: var(--danger);
}

.errorComment {
  color: var(--white);
  width: 100%;
  background-color: var(--primary);
  font-size: 12px;
  line-height: 12px;
  margin: 0;
  padding: 10px;
}

.warningText {
  font-style: italic;
  font-size: 15px;
  font-weight: 700;
}
.warnState {
  color: var(--warning);
  font-weight: 600;
}

.loginFixButtonContainer {
  display: flex;
  flex-direction: row;
}

.loginFixButtons {
  background-color: var(--primary);
  border-color: var(--danger);
  margin: 10px;
  height: 55px;
  height: auto;
  width: 100px;
}
.mobileLogInResult {
  text-align: center;
  width: -webkit-fill-available;
}
.profManText {
  align-self: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.profManTitle {
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.profManInnerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#cancel {
  background-color: var(--white);
  color: var(--greyMedium);
  border-color: var(--greyMedium);
}

.letterInCircle {
  font-family: $rogers-font;
  color: inherit;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  bottom: 78px;
}

.appNameText {
  font-family: $rogers-font;
  font-weight: 700;
  font-size: 12px;
  position: relative;
  top: 5px;
  height: 33px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.letterUnderBox {
  font-family: $rogers-font;
  font-weight: 550;
  font-size: 11px;
  position: relative;
  top: 35px;
  right: 26px;
  height: 25px;
  width: 0px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.appMenuCont {
  display: flex;
  align-items: center;
}
div.appMenuCont .appMenuBtn {
  background-color: transparent;
  border-color: transparent;
  float: none;
  padding: 5px;
}
div.exceptionCont {
  background-color: var(--white);
  text-align: center;
  border-radius: 10px;
  margin: 20px 10px;
  width: 90%;
  border: 1px solid var(--greyLight);
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.exceptionCont img {
  margin-bottom: 30px;
}
.exceptionCont h4 {
  font-size: 16px;
  font-weight: 600;
}
.exceptionCont p {
  font-size: 14px;
  font-weight: 400;
}
p.errorMsgCont {
  max-height: 200px;
  overflow: auto;
}
.exceptionCont div {
  margin-bottom: 10px;
}
.exceptionCont button.refreshExcBtn {
  font-size: 12px;
  font-weight: 400;
  background-color: var(--link);
  color: var(--white);
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
}
.exceptionCont button.feedbackExcBtn {
  font-size: 10px;
  font-weight: 600;
  background-color: transparent;
  color: var(--link);
  border: none;
  text-decoration: underline;
}
.infoBanner div.MuiAlert-icon {
  align-items: center;
}
.infoBanner p {
  margin-bottom: 0px;
}
button.custDDBtn {
  background-color: var(--black10);
  border: transparent;
  border-radius: 5px;
  display: flex;
  font-size: 15px;
  //@at-root: 125px;
  //max-width: 100px;
  width: 100%;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
}
// .custDDCont {
//   margin-right: 15px;
// }
.custDDBtn b {
  font-weight: 600;
  margin-right: 5px;
}
.custDDBtn span {
  margin-right: 5px;
}
div.custDDOptionsCont {
  position: absolute;
  border: none;
  border-radius: 5px;
  background-color: var(--white);
  width: max-content;
  min-width: 125px;
  margin-top: 5px;
  padding: 10px 0px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 2000;
}
div.optionsList {
  overflow: auto;
  max-height: 170px;
}
div.custDDOption {
  //display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  padding: 5px 10px;
  width: 100%;
}
div.custDDOption:hover {
  cursor: pointer;
  background-color: rgba(218, 41, 28, 0.1);
}
.custDDSearch {
  border: none;
  width: 85%;
}
.custDDSearch span {
  width: 15%;
}
.custDDSearch:focus {
  outline: none;
}
.custDDOptionsCont div.searchContHead {
  border: 1px solid var(--black75);
  border-radius: 5px;
  padding: 0px 5px;
  margin: 5px 10px;
}
div.custDDOption label {
  margin-bottom: 0px;
}

div.diagAPICont {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1266px) {
  .accTabsBar {
    padding-left: 0px;
  }
  .workOrderRefreshBtn {
    margin-top: 16px;
    margin-left: 53px;
    background-color: var(--link) !important;
    color: var(--white) !important;
    border: 1px solid var(--link) !important;
  }
}
@media screen and (max-width: 544px) and (min-width: 320px) {
  .custTglBtnCont {
    width: 100vw;
  }
  .workOrderRefreshBtn {
    margin-top: 16px;
    margin-left: 16px;
    background-color: var(--link) !important;
    color: var(--white) !important;
    border: 1px solid var(--link) !important;
  }
}

@media screen and (max-width: 689px) and (min-width: 320px) {
  #custName {
    font-size: 24px;
  }
  .accDetails,
  .deviceCont {
    font-size: 13px;
    width: auto;
    margin-bottom: 10px;
  }
  .custInfo {
    display: block;
  }
  div.custCountainerSm .custInfo {
    display: block;
  }
  .accDetailsSm:first-child,
  .accDetailsSm:last-child {
    width: auto;
  }
  #custNameEx {
    font-size: 20px;
    margin-left: 0px;
  }
  .serviceAreas {
    margin-left: 5px;
  }
  .colIcon {
    margin-right: 20px;
    margin-bottom: 10px;
    height: 30px;
  }
  #deviceLabel {
    font-weight: 600;
  }
  .deviceBox {
    padding: 5px 10px;
    margin-right: 10px;
  }
  .custCountainer {
    display: flex;
    padding: 15px;
  }
  .appMenuBtn {
    padding: 0px;
  }
  #custMenu {
    display: flex;
    float: right;
  }
  hr.MuiDivider-vertical {
    width: auto;
    height: 2px;
    margin: 10px 5px;
  }
  button.MuiTab-root {
    font-size: 12px;
    font-weight: 700;
  }
  .accDetailsActions {
    width: auto;
  }
  div#customPopUp {
    max-width: none;
  }
  .workOrderRefreshBtn {
    margin-top: 16px;
    margin-left: 25px;
    background-color: var(--link) !important;
    color: var(--white) !important;
    border: 1px solid var(--link) !important;
  }
}

@media screen and (max-width: 1005px) and (min-width: 690px) {
  #custName {
    font-size: 24px;
  }
  .accDetails,
  .deviceCont {
    font-size: 13px;
  }
  #custNameEx {
    font-size: 20px;
  }
  .bgIcon {
    height: 30px;
    width: 30px;
  }
  .smIcon {
    font-size: 15px;
  }
  .colIcon {
    margin-right: 10px;
    height: 30px;
  }
  hr.MuiDivider-vertical {
    margin: 10px;
  }
  .deviceBox {
    margin-right: 10px;
  }
  .accDetailsSm:nth-child(odd) {
    width: 30%;
  }
  .accDetailsSm:nth-child(even) {
    width: 70%;
  }
  .workOrderRefreshBtn {
    margin-top: 16px;
    margin-left: 25px;
    background-color: var(--link) !important;
    color: var(--white) !important;
    border: 1px solid var(--link) !important;
  }
}
@media screen and (max-width: 1144px) and (min-width: 1006px) {
  .deviceBox {
    margin-right: 10px;
  }
  .colIcon {
    margin-right: 20px;
  }
  .workOrderRefreshBtn {
    margin-top: 16px;
    margin-left: 46px;
    background-color: var(--link) !important;
    color: var(--white) !important;
    border: 1px solid var(--link) !important;
  }
}
