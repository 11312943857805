@import "../globle/css-variables.scss";

.certiTabBox {
  min-height: 500px;
}
.startCertiBtn {
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 600;
}
.refreshCertiBtn {
  background-color: transparent;
  color: var(--link);
  border: 1px solid var(--link);
  border-radius: 10px;
  padding: 2px 5px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  &:disabled {
    border: 1px solid var(--greyLight);
    color: var(--greyLight);
    svg {
      color: var(--greyLight);
    }
    &:hover {
      background-color: transparent;
      color: var(--greyLight);
      border: 1px solid var(--greyLight);
    }
  }
  
}
.refreshCertiBtn:hover {
  background-color: var(--link);
  color: var(--white);
  border: 1px solid var(--link);
}
.refreshCertiBtn svg {
  font-size: 20px;
  margin-left: 5px;
}
.certiRefreshBtn {
  background-color: transparent;
  color: var(--link);
  border: 1px solid var(--link);
  border-radius: 5px;
  padding: 2px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.certiRefreshBtn:disabled {
  border: 1px solid var(--greyLight);
}
.refreshBtnCont {
  margin-bottom: 10px;
}
.techImg {
  width: 30px;
}
.techName {
  vertical-align: middle;
}
.hsiCertiStatus p,
.wifiCertiStatus p {
  display: flex;
  margin-bottom: 0px;
}
.hsiCertiStatus p span,
.wifiCertiStatus p span {
  display: flex;
  align-items: center;
  border: 1px solid var(--greyLight);
  border-radius: 15px;
  margin-right: 5px;
  padding: 2px 10px;
}
.hsiCertiStatus p span svg,
.wifiCertiStatus p span svg,
.hsiCertiStatus p span img,
.wifiCertiStatus p span img {
  margin-left: 3px;
}
.certiPageCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.certiPageCont p {
  margin-bottom: 0px;
  padding-left: 20px;
  font-family: $rogers-font;
  font-size: 13px;
  font-weight: 600;
}
div.MuiTablePagination-root p {
  font-family: $rogers-font;
  font-size: 15px;
  font-weight: 600;
}
.newCertiCont {
  display: flex;
  justify-content: space-between;
}
.newCertiCont p {
  margin-bottom: 0px;
  display: flex;
  margin-right: 50px;
  align-items: center;
}
.newCertiCont span {
  margin-right: 5px;
}
.inProgressCerti {
  font-size: 12px;
  font-weight: 600;
}
.contiCerti {
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  color: #006acc;
}
.certiStepsCont {
  font-family: $rogers-font;
}
.certiStepsHeader {
  background-color: var(--background);
  text-align: center;
}
.certiStepsHeader h3 {
  font-size: 18px;
  padding: 10px;
  font-weight: 600;
}

.certiStepsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
}
.stepperCont {
  padding: 0px 2px;
  width: 15%;
  cursor: pointer;
}

.stepConnecterLine,
.step-Active-ConnecterLine,
.step-Completed-ConnecterLine {
  height: 4px;
  width: 100%;
  display: block;
  border-radius: 4px;
}
.stepConnecterLine {
  background-color: var(--greyLight);
}
.step-Active-ConnecterLine {
  background-color: var(--black75);
}
.step-Completed-ConnecterLine {
  background-color: rgba(218, 41, 28, 1);
}
.stepInfo,
.step-Active-Info,
.step-Completed-Info {
  text-align: left;
  display: flex;
  align-items: center;
}
.stepInfo svg,
.step-Active-Info svg,
.step-Completed-Info svg {
  font-size: 16px;
  margin-right: 3px;
}
.stepInfo span,
.step-Completed-Info span {
  font-size: 14px;
  font-weight: 600;
  color: var(--brightGray);
}
.step-Active-Info span {
  font-size: 14px;
  font-weight: 700;
  color: var(--brightGray);
}
.certiStepCont {
  background-color: var(--white);
  padding: 40px;
}
.certiCustInfo,
.hsiCertiStep {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.certiCustInfo h4,
.hsiCertiStep h4 {
  font-size: 20px;
  font-weight: 600;
}

.certiCustInfo p {
  font-size: 18px;
  font-weight: 400;
  color: var(--brightGray);
  width: 40%;
  margin: 10px 0px;
}
.hsiCertiStep p {
  font-size: 18px;
  font-weight: 400;
  color: var(--brightGray);
  width: 60%;
  margin: 10px 0px;
}
.certiInputCont {
  width: 30%;
  margin: 10px 0px;
}
div.hsiCertiStep .MuiDivider-root {
  width: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.hsiCertiInputCont {
  width: 50%;
  display: flex;
  margin: 25px 0px;
  align-items: flex-end;
  justify-content: space-between;
}
.certiInputCont label,
.hsiCertiInputCont label{
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: left;
}
.postCheckActions .reasons label {
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: center;
}
.certiInputCont input {
  display: block;
  width: 100%;
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  padding: 3px 5px;
}
.certiInputCont div {
  margin-bottom: 10px;
}
.certiInputCont #certiTestTypeDd {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyLight);
  font-weight: 400;
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: center;
}
div.certiTestTypeDropd .dropdown-menu {
  width: 100%;
}
.runDiagBtn {
  background-color: var(--link);
  color: var(--white);
  border: none;
  border-radius: 5px;
  padding: 4px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.runDiagBtn span {
  margin-right: 5px;
}
.certiStepBtnCont {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  margin-top: 40px;
  justify-content: center;
}
.certiStepBtnCont button {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.certiCancelBtn {
  background-color: transparent;
  border: none;
}
.certiPrevBtn {
  background-color: var(--white);
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  padding: 5px 15px;
  justify-content: space-between;
}
.certiPrevBtn span {
  margin-left: 10px;
}
.certiNextBtn {
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  padding: 5px 15px;
}
.certiSubmitBtn {
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  padding: 5px 15px;
}
.certiSubmitBtn:disabled {
  background-color: var(--greyLight);
  color: var(--white);

  border-radius: 5px;
  padding: 5px 15px;
}
.certiNextBtn span {
  margin-right: 10px;
}
p.hsiCertiWarnText {
  font-size: 14px;
  width: 45%;
  font-weight: 600;
  font-style: italic;
}
p.wifiCertiWarnText {
  font-size: 14px;
  width: 45%;
  font-weight: 600;
  color: var(--greyLight);
  font-style: italic;
}
.hsiCertiSummary {
  color: var(--greyLight);
  width: 40%;
}
.hsiCertiSummary p,
.disabledResults div {
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  font-size: 11px;
  font-weight: 400;
  color: var(--greyLight);
  width: 100%;
  display: flex;
  padding: 10px 0px;
  margin: 10px 0px;
  justify-content: center;
}
.hsiCertiSummary span,
.disabledResults span {
  border: 1px solid var(--greyLight);
  border-radius: 10px;
  margin: 2px 5px;
  display: flex;
  padding: 3px 10px;
  align-items: center;
}
.hsiCertiSummary span svg,
.disabledResults span svg,
.hsiCertiSummary span img,
.disabledResults span img {
  margin-left: 5px;
  font-size: 13px;
}
label.wifiCertiCheck {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  margin: 0px 0px;
}
label.wifiCertiCheck svg {
  font-size: 16px;
}
.reviewCertiCont {
  width: 90%;
}
section.certiAccordian .card-header {
  text-align: left;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 15px;
  font-size: 14px;
  font-weight: 600;
}
section.certiAccordian .card-header svg {
  font-size: 17px;
  color: var(--greyLight);
}
section.certiAccordian .card-body {
  display: flex;
  padding: 0px 1.25rem;
}
section.certiAccordian .card-body p {
  font-size: 14px;
  font-weight: 400;
  width: 40%;
  text-align: left;
}
section.certiAccordian .card-body .certiInputCont {
  margin-left: 10%;
  width: 50%;
}

section.certiAccordian .reviewBody{
  display: flex;
  flex-direction: column;
  padding: 0px 1.25rem;
}

section.certiAccordian .reviewBody .postCheckActions{
  margin-bottom: 10px;
  margin-top: 10px;
}

section.certiAccordian .reviewBody .reviewActions{
  display: flex;
}

section.certiAccordian .reviewBody .reviewActions .certiInputCont{
  margin-bottom: 0;
}

.postCheckActions .reviewActions p{
  padding-bottom: 15px;
}

section.certiAccordian .activeCertiResults {
  display: flex;
  border: 1px solid var(--black50);
  padding: 10px 15px;
  justify-content: center;
  border-radius: 5px;
}

.textArea{
  display: flex;
}

section.certiAccordian .reviewBody .custom-div-1{
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 1;
}

section.certiAccordian .reviewBody .custom-div-2{
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 1;
  padding-left: 5px;
}

section.certiAccordian .reviewBody .custom-area-cont{
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}

section.certiAccordian .reviewBody .custom-area-cont textarea{
  display: flex;
  margin-left: 1px;
  border-color: var(--greyLight);
  border-radius: 5px;
  width: 50%;
}

section.certiAccordian .reviewBody .custom-area-cont label{
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: left;
}

.activeCertiCont {
  width: 40%;
}
.activeCertiCont label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}
div.activeCertiCont .activeCertiResults {
  display: flex;
  border: 1px solid var(--black50);
  padding: 10px 15px;
  justify-content: center;
  border-radius: 5px;
}
.activeCertiResults span {
  display: flex;
  border: 1px solid var(--black50);
  align-items: center;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 400;
  padding: 2px 15px;
  margin-right: 5px;
}
div.activeCertiResults h4 {
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 0px;
  margin-right: 2px;
}
div.activeCertiResults svg,
div.activeCertiResults img {
  font-size: 12px;
  margin-left: 5px;
}
div.certiInputCont i,
div.certiDetails i,
div.activeCertiCont i {
  font-size: 12px;
  font-weight: 600;
  margin: 10px;
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
  color: var(--link);
}
div.certiInputCont #certiReasonTypeDd,
div.certiInputCont #custReviewDd {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyLight);
  font-weight: 400;
  justify-content: space-between;
  display: flex;
  width: 60%;
  align-items: center;
  margin-bottom: 10px;
}
div.custReviewDd .dropdown-menu {
  width: 60%;
}
textarea.taNotesCont {
  resize: none;
  background-color: var(--white);
  border-radius: 5px;
}
div.certiInputCont textarea {
  display: flex;
  margin-left: 1px;
  border-color: var(--greyLight);
  border-radius: 5px;
}
div.certiDetails {
  border: 1px solid var(--pureBlack);
  border-radius: 5px;
  margin-top: 25px;
  width: 35%;
  padding: 10px 30px;
}
div.certiDetails p {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  width: 100%;
}
div.certiDetails span:nth-child(odd) {
  font-size: 14px;
  font-weight: 600;
  width: 50%;
  text-align: left;
}
div.certiDetails span:nth-child(even) {
  font-size: 14px;
  width: 50%;
  text-align: left;
  font-weight: 400;
}

.certiRefreshBtn:active {
  transform: translateY(1px);
}

div.nodeTeleTableCont {
  min-height: 80vh;
}
div.certiTableCont .deviceTable {
  min-width: 1160px;
}
div.certiTableCont .pTabHead th {
  text-align: left;
}
div.certiTableCont .deviceTable th.smallCol {
  width: 10%;
}
div.certiTableCont .deviceTable th.mediumCol {
  width: 15%;
}
div.certiTableCont .deviceTable th.largeCol {
  width: 20%;
}
a.idLink {
  color: var(--link);
  text-decoration: underline;
  cursor: pointer;
}
a.selectedIdLink {
  color: var(--link);
  text-decoration: underline;
  cursor: pointer;
  background-color: var(--primary20);
  border-radius: 5px;
  padding: 2px 5px;
}
a.idLink:hover {
  color: var(--black75);
}
span.taCaption {
  font-size: 11px;
}
section.certiDetailsSec {
  width: 50%;
}
div.statusCont {
  display: flex;
  width: 50%;
  background-color: var(--white);
  padding: 20px 10px;
  justify-content: center;
}
div.statusCont section {
  margin: 0px 10px;
}
div.certiTableCont .deviceTable .active {
  background-color: var(--good);
  color: var(--white);
}
div.certiTableCont .deviceTable .inActive {
  background-color: var(--danger);
  color: var(--white);
}
div.certiTableCont .deviceTable .selected {
  background-color: var(--marginal);
  color: var(--pureBlack);
}
