@import "../globle/css-variables.scss";

.gponTopScroll::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}
.gponTopScroll {
  overflow-x: auto;
  width: 100%;
  height: 8px;
}
.gponTopScroll::-webkit-scrollbar {
  border-radius: 5px;
  height: 8px;
  width: 14px;
}
.gponTopScroll::-webkit-scrollbar-thumb {
  background: var(--greyLight);
  border-radius: 5px;
}

.gponTableScrollBar::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}
.gponTableScrollBar::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 5px;
}
.gponTableScrollBar::-webkit-scrollbar-thumb {
  background: var(--greyLight);
  border-radius: 5px;
}

.gponBannerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px 8px;
  outline: 1px solid #bbbdc0;
  border-bottom: 1px solid #bbbdc0;
}
.multiDeviceBannerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 20px;
  outline: 1px solid #bbbdc0;
  border-bottom: 1px solid #bbbdc0;
}

.tabBarContainer {
  display: flex;
  overflow: hidden;
  min-height: auto;
  max-height: 40px;
}

.tableHeaderGpon {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-height: 8%;
  justify-content: space-between;
}

.tableHeaderTextGpon {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: 0.875rem;
  white-space: pre;
  font-weight: 700;
  font-family: "Ted Next";
}

.tableHeaderContainerGpon {
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 1px 1px 4px -2px;
}

.tablePaginationContainerGpon {
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  font-size: 0.813rem;
  max-height: 40px !important;
  .MuiIconButton-root {
    padding: 8px;
  }
}

.tablePaginationGpon {
  flex: 1 1 auto;
  // for all element in table pagination toolbar set font size
  .MuiTablePagination-toolbar p {
    font-size: 0.813rem;
  }
  .MuiTablePagination-spacer {
    display: none;
  }
  .MuiToolbar-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: auto;
  }
  .MuiTablePagination-input {
    order: 3;
    font-size: 0.813rem;
  }
  .MuiTablePagination-toolbar p[id] {
    order: 2;
    margin-left: 5%;
  }
  // check second p tag have id or not if not then order 1
  .MuiTablePagination-toolbar p:not([id]) {
    order: 1;
    margin-left: auto;
  }
  div > div:last-child {
    order: 4;
    margin-left: auto;
  }
}

.tablePaginationTextGpon {
  margin-left: 20px;
  font-size: 0.813rem;
  font-weight: 600;
  white-space: pre;
}

.certifyTableCellForDs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.gponTableCell {
  display: flex;
  span > span:not(:last-child) {
    display: flex;
    white-space: pre-wrap;
    border-bottom: 1px solid #6c757d7a;
  }
}

.gponTableCustoms .MuiTableCell-head {
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 600;
  white-space: pre;
  padding: 4px 8px 4px 4px !important;
}

.gponTableCustoms {
  tbody {
    tr:nth-child(odd) {
      background-color: #f4f4f4;
    }
    tr:nth-child(even) {
      background-color: var(--black10);
    }
  }
}

.gponTableCustoms .MuiTableCell-body {
  font-weight: 500;
  font-size: 0.813rem;
  white-space: pre-wrap;
  padding: 4px 8px 4px 4px !important;
}

.gponTableCellForSpace {
  padding: 4px;
  font-size: 0.813rem;
  white-space: pre-wrap;
}

.gponTableSearchBar {
  width: 85%;
  border: none;
  outline: none;
  background: transparent;
}

.tableCellGpon {
  padding: 5px !important;
}

.gponBannerContainer__externalAppMenu {
  align-self: baseline;
}

#gponNodeId {
  font-size: large;
  font-weight: 600;
  white-space: pre;
}

#gponNodeId_value {
  font-size: large;
  font-weight: 400;
  white-space: pre;
}

.gponLandingPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.gponBodyContainer {
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  padding: 8px;
}

.exportButtonStyle {
  display: inline-flex;
  align-items: center;
  
  background: none rgb(29, 111, 66);
  color: white;
  padding: 4px 12px;
  border-radius: 5px;
  border: 0px;

  // disable button
  &[disabled] {
    background: none rgb(29, 111, 66);
    opacity: 0.5;
    color: white;
    padding: 4px 12px;
    border-radius: 5px;
    border: 0px;
    cursor: not-allowed;
  }

}

.gponOltBodyContainer {
  flex: 1 1 auto;
  height: 100%;
  width: 80%;
  padding: 8px;
}

.gponBannerIconsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.gponMenuIcon {
  &:hover {
    background-color: #e6e6e6;
    border-radius: 50%;
  }
  // inside button tag 
  button {
    all: unset !important;
    &:hover {
      all: unset;
    }
    &:focus {
      all: unset;
    }
    &:active {
      all: unset !important;
    }
    &:after {
      all: unset;
    }
  }
  div > a {
    color: inherit;
  }

  div > a > span > img {
    width: 24px;
    height: 24px;
  }
}

.gponMenuListItemContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.gponBannerIconsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0px 10px;
}

.gponHyperLink {
  color: var(--link);
  text-decoration: underline;
  cursor:pointer;
}

.gponBannerIcons {
  padding-left: 8px;
  button {
    padding: 2px !important;
    vertical-align: middle;
    display: flex;
    align-items: center;
  }
  button > svg {
    width: 24px;
    height: 24px;
  }
  button > img {
    width: 24px;
    height: 24px;
  }
}

.gponCollapseContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-right: 10px;
  background-color: #fafafa;
  box-shadow: 0px 1px 4px 0.5px #808284;
  border-radius: 50%;
  transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0s;
  &:hover {
    background-color: #e0e0e0;
    height: auto;
    width: auto;
    box-shadow: 0px 1px 4px 0.5px #808284;

  }
}
