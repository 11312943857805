@import "../../../../design-tokens/globle/css-variables.scss";

.btnContainer {
  flex-direction: row;
  background-color: var(--white);
  padding: 1px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--greyLight);
}

.otdrPopUpCont {
  font-family: $rogers-font;
  overflow: auto;
  background: white;
  border-radius: 5px;
  min-width: 30vw;
}
.otdrPopUpCont b {
  font-weight: 700;
  margin-left: 5px;
}

.otdrPopUpCont section.otdrCont {
  box-shadow: none;
  padding: 0px;
}

.otdrDetailHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.otdrPopUpCont div.otdrBody {
  margin: 10px;
}
.otdrPopUpCont div.reotdrs-responsive-container {
  min-width: 30vw !important;
}

.otdrActionCont {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.otdrActionCont span.otdrCloseBtn:hover {
  background-color: var(--primary);
  color: var(--white);
  border-radius: 5px;
}

.otdrActionCont span.otdrCloseBtn svg {
  font-size: 25px;
  cursor: pointer;
}

.otdrSearchCont {
  font-size: 14px;
  margin: 10px 0px;
}
.otdrSearchCont section {
  border: 1px solid var(--primary);
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.otdrSearchCont input {
  border: none;
  margin-left: 5px;
  border-radius: 10px;
  width: 100%;
}
.otdrSearchCont input:focus {
  outline: none;
}
.otdrSearchCont button {
  border: none;
  background-color: var(--primary);
  color: var(--white);
  padding: 4px 10px;
  border-radius: 0px 10px 10px 0px;
}

.otdrMarkerDistanceTxt {
  // font-size: 14px !important;
  background-color: var(--white);
  padding: 3px;
  border-radius: 5px;
  border: solid var(--pureBlack);
  border-width: 1px;
}
.inActivefiberTraceBtn {
  display: inline-flex;
  align-items: center;
  padding: 2px 5px;
  border-radius: 5px;
  background: #e2e8f0;
  color: var(--greyMedium);
}

.activefiberTraceBtn {
  display: inline-flex;
  align-items: center;
  padding: 3px;
  border-radius: 5px;
  background: #e2e8f0;
  color: #ff9900;
}

.inActivefiberTraceBtn svg,
.activefiberTraceBtn svg {
  margin: 0px 3px;
}
