@import "../../design-tokens/globle/css-variables.scss";

.googleMapCont {
  width: 100%;
  height: inherit;
}
div.drawerContainer .diagAPICont {
  height: 90vh;
  align-items: center;
}
.mapLoaderCont {
  margin-top: 30px;
}
.mapLoaderCont img.mapLoading {
  width: 25%;
}
.mapLoaderCont div.MuiSnackbarContent-root {
  background-color: var(--white);
  color: var(--pureBlack);
  font-weight: 600;
  min-width: auto;
  max-width: 9vw;
}
.mapLoaderCont div.MuiSnackbarContent-message {
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoWinCont {
  background-color: var(--white);
  border-radius: 5px;
  padding: 5px 0px 5px 0px;
  min-width: none;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.infoWinCont .itemCont {
  padding: 5px 10px;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
  display: flex;
}
.infoWinCont .itemCont:hover {
  background-color: var(--primary10);
}
.infoWinCont .itemCont label {
  margin: 0px;
  cursor: pointer;
}
.mapDestinationDistanceTxt {
  // font-size: 14px !important;
  background-color: var(--white);
  padding: 5px;
  border-radius: 5px;
  border: solid var(--pureBlack);
  border-width: 1px;
}
div.errorMSG {
  display: flex;
  align-items: center;
}
div.errorMSG p {
  margin-bottom: 0px;
  margin-left: 5px;
}
div.popUpBodyCon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

p.drawingNumChip {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background: var(--background);
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

//GeoEdit.tsx start
.geoEditCanvasCont{
  border: 2px solid var(--greyMedium);
  margin: 2px;
}
.geoEditMenuCont {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--greyMedium);
  background-color: var(--primary10);
}
.geoEditMenuCont .itemsCont{
  display: flex;
  flex-direction: column;
}
.geoEditMenuCont .itemsCont svg {
  width: 25px;
  height: 25px;
}
.geoEditMenuCont .itemsCont svg:hover{
  color: var(--primary);
}
.geoEditMenuPopupcont{
  display: flex;
  flex-direction: column;
  margin: 10px;
  gap: 15px;
}
.geoEditsCont {
  border: 2px solid var(--greyMedium);
  background-color: var(--white);
  border-radius: 5px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 5px;
  gap: 8px;
}
#geoEditShortcutModal {
  font-size: 18px;
  font-family: $rogers-font;
  max-width: 350px
}
//GeoEdit.tsx end