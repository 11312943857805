.cardHeaderImg,
.workOrderCard img,
.workOrderCard svg {
  width: 40px;
  height: 30px;
  /* border-radius: 50%;
  border: 1px solid #dedede; */
}
.workOrderList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  justify-content: center;
}
.workOrderList span.MuiCardHeader-title {
  font-weight: 600;
}
.workOrderList span.MuiCardHeader-subheader {
  font-size: 10px;
  font-style: italic;
  font-weight: 700;
}

.workOrderList div.MuiCardHeader-root {
  padding: 5px 10px;
}
.workOrderList div.MuiCardContent-root {
  padding: 10px;
}

.workOrderCard {
  //changes by node team to increase width of work order
  //width: 23%;
  width: 26%;
  //changes by node team end
  border: 1px solid var(--greyLight);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 10px;
}
.workOrderCard:hover {
  background-color: #fae7e78f;
  cursor: pointer;
}
.workOrderCard p {
  margin-bottom: 0px;
}
.right-title {
  float: right;
}
.left-title {
  float: left;
}
.workOrderCard div.MuiCardActions-root {
  justify-content: center;
}
.woCardStatus {
  margin-bottom: 0px;
  text-align: center;
  color: var(--white);
  padding: 2px;
  font-weight: 600;
}
.cardInfo {
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  & > tbody {
    display: grid;
    gap: 8px;
    & > tr {
      display: grid;
      gap: 8px;
      grid-template-columns: 0.3fr 1fr;
      align-items: center;
      & > td:first-child {
        display: flex;
        justify-content: flex-start;
        margin-left: 8px;
        height: 100%;
        align-items: flex-start;
      }    
      & > td:not(first-child) {
       word-break: break-word;
       font-weight: 600;
      }
    }
  }
}

// table.cardInfo tr td:first-child {
//   width: 40%;
//   padding-right: 5px;
//   font-weight: 600;
// }
button.cardLink {
  font-size: 15px;
  background-color: transparent;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  color: var(--link);
}
button.cardLink:hover {
  background-color: var(--link);
  color: var(--white);
}
.workOrderList div.exceptionCont {
  border: none;
  box-shadow: none;
}

.vmCard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.vmCard a {
  text-decoration: none;
  font-size: large;
  color: #858590;
}
@media screen and (max-width: 544px) and (min-width: 320px) {
  .workOrderCard {
    width: 90%;
  }
  .vmCard {
    padding: 20px 0px;
  }
}

@media screen and (max-width: 769px) and (min-width: 544px) {
  .workOrderCard {
    width: 45%;
  }
}

@media screen and (max-width: 1144px) and (min-width: 769px) {
  .workOrderCard {
    width: 30%;
  }
}
