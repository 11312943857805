@import "../globle/css-variables.scss";

.outSpecState {
  color: var(--danger);
  font-weight: bold;
}
.inSpecState {
  color: var(--pureBlack);
}

.marginal {
  color: var(--orange);
  font-weight: bold;
}

.teleFiltersection button.custDDBtn {
  background-color: var(--black10);
  border: transparent;
  border-radius: 5px;
  display: flex;
  font-size: 15px;
  min-width: 35px;
  width: 35px;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
}
.telemetryActionCont {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.telemetryActionCont.summary {
  min-height: 40px;
  padding: 5px;
  margin: 5px 0px;
}

.nodeFilterCont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px;
}
.nodeFilterCont button.custDDBtn {
  font-size: 12px;
}

.nodeFilterContainer > * {
  margin-right: 16px;
}
.nodeFilters,
.nodeTeleActions {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: max-content;
  min-width: fit-content;
}

.nodeTeleActions .refreshCertiBtn {
  padding: 4px 15px;
}
.certiTableCont div.nodeTeleTableCont {
  min-height: 80vh;
}
div.nodeTelemetryCont div.certiPageCont button {
  padding: 5px;
}
div.nodeTelemetryCont div.certiPageCont .MuiTablePagination-toolbar {
  min-height: 25px;
}
.nodeTeleTableContFull {
  max-height: 85vh;
}
.nodeTeleActionMenu {
  background: transparent;
  border: none;
  transform: scale(1.5);
  margin-bottom: 6px;
  margin-left: 8px;
  &:hover {
    background-color: var(--black10);
    border-radius: 50%;
  }
}

.nodeTeleTable th {
  font-size: 12px;
}
.nodeTeleTable thead th:first-child {
  min-width: 150px;
}

table.nodeTeleTable td {
  color: var(--pureBlack);
  font-size: 12px;
  font-weight: 500;
  padding: 1px 5px !important;
  vertical-align: top;
}

table.nodeTeleTable td.largeValCol {
  min-width: 10vw;
}
table.nodeTeleTable td.medValCol {
  min-width: 6vw;
}

.nodeTeleTable td .cellNewLine {
  white-space: pre-line;
}
/* .nodeTeleTable td .cellNewLine > div {
  display: flex;
} */

.nodeTeleTable thead {
  box-shadow: 1px 1px 4px -2px;
}

.nodeTeleTable thead th {
  padding: 1px 5px;
  font-weight: 700;
  border-bottom: 0;
  z-index: 1;
}
.nodeTeleTable thead th.specAvailable {
  padding: 1px 5px;
  font-weight: 700;
  border-bottom: 0;
  z-index: 1;
}
.nodeTeleTable thead th:first-child {
  position: sticky;
  left: 0;
  padding: 1px 5px;
  z-index: 2;
}

thead.telemetryTabHead .tableCellContainer .custDDCont {
  display: block;
  margin: 0;
}
thead.telemetryTabHead .tableCellContainer .custDDCont button.custDDBtn {
  padding: 0;
}
thead.telemetryTabHead .tableCellContainer .custDDCont button.custDDBtn span {
  display: none;
}
.nodeTeleTable tbody tr:nth-child(even) {
  background-color: var(--black10);
}
.nodeTeleTable tbody tr:nth-child(odd) {
  background-color: var(--backgroundLight);
}
.nodeTeleTable thead th:nth-child(2){
  min-width: 150px;
}

.nodeTeleTable thead th:nth-child(2) {
  position: sticky;
  left: 0;
  padding: 1px 5px;
  z-index: 2;
}
.nodeTeleDataLoader {
  width: 30px;
  height: 15px;
}

.loaderContainer {
  width: 30px;
  height: 15px;
  margin-right: 0.5%;
}

.nodeTeleTable .dTabBody {
  min-height: 50vh;
}
.nodeTeleTable .dTabBody td:first-child {
  position: sticky;
  left: 0;
  padding-left: 10px;
}
.nodeTeleTable tbody tr:nth-child(even) td:first-child {
  background-color: #e5e5e5;
}
.nodeTeleTable tbody tr:nth-child(odd) td:first-child {
  background-color: var(--background);
}
.nodeTeleTable .dTabBody tr {
  height: auto;
}
.nodeTeleDeviceTablePaper {
  margin-top: 10px;
}

.nodeTopScroll {
  overflow-x: auto;
  width: 100%;
  height: 8px;
}
.nodeTopScroll::-webkit-scrollbar {
  border-radius: 5px;
  //display: none;
  //overflow: auto;
  height: 8px;
  width: 14px;
}
.nodeTopScroll::-webkit-scrollbar-thumb {
  background: var(--greyLight);
  border-radius: 5px;
}

.fullScreenBox {
  margin: 5px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.fullScreenBtn {
  background: var(--white);
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.fullScreenBtn svg {
  font-size: 30px;
}
.fill-window {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
}
.fullScreenBox span.label {
  font-weight: 600;
  margin-left: 20px;
  margin-right: 10px;
}
.fullScreenBox span.label:first-child {
  margin-left: 10px;
}

div.telemetryInfopopbox {
  background-color: var(--white);
  text-align: left;
  border-radius: 10px;
  margin: 20px 13px;
  width: 96%;
  border: 1px solid var(--greyLight);
  padding: 5px 15px 15px 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 5px;
  margin-bottom: 0px;
}

.tableCellContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 70px;
  justify-content: flex-end;
}

.cellHeaderContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.flapHistoryDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.successText {
  font-size: 14px;
  color: var(--good);
}
.failureText {
  font-size: 14px;
  color: var(--danger);
}

div#telemetrypopup {
  font-size: 16px;
  font-family: $rogers-font;
  max-width: 35vw !important;
  min-width: 375px;
}

div#telemetrymediumpopup {
  font-size: 16px;
  font-family: $rogers-font;
  max-width: 45vw !important;
}

// CLEARFLAPS and TELEMETRY INFO RELATED DESIGN

.telemetryClearFlapsBtn {
  background-color: var(--white);
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 3px 15px;
  font-size: 13px;
  font-weight: 600;
  flex-direction: row;
  margin-right: 1.5%;
}

.telemetryClearFlapsBtn:disabled {
  border-style: none;
}
.telemetryClearFlapsBtn:enabled:hover {
  background-color: var(--primary);
  color: var(--white);
}

.telemertyinfoBoxDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.telemertyinfoBoxDiv.summary {
  justify-content: flex-start;
  //padding-right: 41%;
  max-width: 730px;
  padding-left: 12px;
}

.telemertyinfoBoxDiv.summary > span {
  margin: 0px;
}

.telemertyinfoBoxDiv.summary .telemertyinfoBox,
.telemertyinfoBoxDiv.summary .telemertyinfoBoxSpan {
  font-size: 1rem;
  text-align: left;
}

.telemertyinfoBox {
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  flex-grow: 1;
  min-width: fit-content;
  margin-top: 10px;
  text-align: center;
}

.telemertyinfoBox.top {
  margin-left: 10%;
  font-size: 16px;
}

#totals {
  margin-left: 2%;
  margin-right: 2%;
}

.telemertyinfoBox.fail {
  color: var(--danger);
}

.telemertyinfoBoxDiv.fail {
  color: var(--danger);
}

.telemertyinfoBoxSpan {
  flex-grow: 1;
  font-size: 14px;
  min-width: fit-content;
  margin: 10px 5px 0px 5px;
  text-align: center;
}

.telemertyinfoBoxSpan.flaps {
  width: 230px;
  min-width: 230px;
  margin-left: 10%;
}

.responseHead {
  display: flex;
  flex-direction: row;
  margin-left: 5px;
}

.responseHead span:first-child {
  min-width: fit-content;
}
.responseHead span:last-child {
  width: 250px;
  min-width: 230px;
}

.clearFlapsResponseJson {
  flex-grow: 1;
  font-size: 14px;
  min-width: fit-content;
  margin-left: 20px;
  min-height: fit-content;
  max-height: 360px;
  height: 5%;
  // overflow-y: scroll;
  // overflow-x: unset;
}

.teleInfoDiv .card-header {
  border-radius: 0;
  display: flex;
  margin-bottom: -1px;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 0.3rem;
}

.teleInfoDiv .card-body {
  padding: 0.2rem 1rem;
}

div.teleInfoDiv svg {
  transform: scale(1.5);
}

.hidden {
  visibility: hidden;
  display: none;
}

.designMaintainer {
  flex-grow: 3;
  flex-direction: row;
}

.searchTeleInput {
  background-color: #fafafa;
}

.telmetryHead {
  display: flex;
  align-items: center;
}

.telmetryHead > span {
  text-align: center;
  line-height: 1.2;
}
.emtaCellHeaderContainer > span {
  text-align: center;
}
/* .tableCellContainer .custDDOptionsCont {
  zoom: 0.75;
  -moz-transform: scale(0.75);
}*/

.buttonDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.headerCell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonDiv > img {
  padding: 3.5px;
}
.dragged {
  border-left: 2px solid var(--primary);
}

.errorPopupTelemetry {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.errorPopUpLabel {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}

.errorPopUpLabelName {
  padding-right: 10px;
  width: 30%;
}

.descriptionName {
  width: 70%;
}

.avgOFDMA {
  padding-bottom: 5px;
  font-size: 14px;
  border-bottom: 1px solid var(--greyLight);
}

.avgNodeTable {
  padding-top: 20px;
}

.cmMacWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
}

span.idLink {
  color: var(--link);
  cursor: pointer;
  text-decoration: underline;
}
span.externalLink {
  color: var(--link);
  cursor: pointer;
  margin-left: 12px;
}
span.headerExternalLink{
  color: var(--link);
  cursor: pointer;
  margin-right: 10px;
}

.certificationsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.certifyBtnWrapper {
  display: flex;
  align-self: flex-end;
  padding-bottom: 20px;
}

.certifyBtn {
  background-color: var(--oceanBlue) !important;
  color: var(--white) !important;
  padding: 4px 24px !important;
}
.certifyBtn:disabled {
  cursor: not-allowed;
  background-color: var(--greyLight) !important;
  color: var(--black50) !important;
}
.certiTableCont {
  height: 85vh;
}

.certifyTableCellForDs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.certificationTableCell {
  display: flex;
  span > span:not(:last-child) {
    display: flex;
    white-space: pre-wrap;
    border-bottom: 1px solid #6c757d7a;
  }
}

.certificationTableCellForSpace {
  padding: 4px;
  white-space: pre-wrap;
}

.certifyTableSearchBar {
  width: 100%;
  border: none;
  margin-top: 5px;
  outline: none;
  background-color: transparent;
}

.data-table-container {
  .MuiTableCell-head {
    padding: 8px 12px !important;
  }
  .MuiTableCell-body {
    padding: 8px !important;
  }
  max-height: 100%;
  table {
    max-height: 100%;
  }
  table > tbody > tr > td {
    padding: 8px !important;
  }
}

.tableHeaderCertification {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-height: 8%;
  justify-content: space-between;
}

.tableHeaderTextCertification {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: pre;
}

.tableHeaderContainerCertification {
  background-color: var(--white);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 1px 1px 4px -2px;
}

.tablePaginationCertification {
  background-color: var(--white);
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 1px 1px 4px -2px;
}

.nodeCertWoPopup {
  // width: 200px;
  // margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 30vw;
  justify-content: center;
  /* align-items: center; */
  margin: auto;
}

.nodeCertWoPopup > div {
  margin: 10px 0px;
}

.nodeCertWOIDContainer {
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 50%;
}
.nodeCertWOIDContainer #dropdownBtn {
  background: transparent;
  color: var(--pureBlack);
  border: none;
  font-size: 15px;
  border-radius: 5px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: center;
}

.nodeCertSubmitBtnWrapper {
  text-align: center;
}

.nodeCertPopup {
  max-width: 30vw !important;
}
