@mixin codeStyleOverride{
    font-family: monospace;
    overflow-wrap: break-word;
    background: rgb(241, 241, 241);
    border-radius: 3px;
    padding: 1px 3px;
    color:unset;
}
div#NotiPopUp {
  max-width: 60%;
}
div#NotiPopUp .modal-header {
  padding: 10px;
}
div#NotiPopUp .modal-footer {
  padding: 5px;
}

div#NotiPopUp .modal-body {
  padding: 0px;
  background-color: var(--background);
  display: block;
}
div.notiCreateCont {
  padding: 15px;
  overflow: auto;
  max-height: 75vh;
}

div.notiItemsCont {
  overflow: auto;
  padding: 15px;
  max-height: 70vh;
}
div#NotiPopUp div.MuiBox-root {
  max-height: 60vh;
  overflow: auto;
  padding: 5px 20px;
}
div.ntfTabsCont {
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
div.ntfTabsCont .tabLabel {
  justify-content: center;
}
div.ntfTabsCont div.horizontalDeviceTab:hover {
  background-color: var(--primary10);
  cursor: pointer;
}
div.ntfTabsCont .custom--Tab,
div.ntfTabsCont .custom-selectedDeviceTab-Tab {
  width: 50%;
}
div.ntfTabsCont .custom-selectedDeviceTab-Tab {
  border-left: none;
  border-bottom: 2px solid var(--primary);
}

///////////////
.ntfHeaderCont {
  display: flex;
  justify-content: space-between;
}
.ntfListContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ntfListHeading {
  font-size: 12px;
  margin: 15px 0px;
  color: var(--greyDark);
}
.ntfListTitle {
  font-size: 14px;
  color: var(--black75);
  font-weight: 900;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.ntfListTitleRead {
  font-size: 14px;
  color: var(--greyDark);
  font-weight: 900;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.ntfListMessage {
  font-size: 12px;
  color: var(--black75);
  font-weight: 600;
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.ntfListTitleRead {
  font-size: 14px;
  color: var(--greyDark);
  font-weight: 400;
}
.ntfListMessageRead {
  font-size: 12px;
  color: var(--greyDark);
  font-weight: 400;
}
.ntfViewLink {
  cursor: pointer;
}
.ntfInfo {
  width: 100%;
  padding: 5px 0px;
}
.ntfInfoAdmin {
  width: 100%;
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  code {
    @include codeStyleOverride
  }
}

.ntfInfoAdmin section {
  width: 90%;
}
.ntfTS {
  font-size: 10px;
  font-weight: 700;
  color: var(--black75);
  float: right;
}
.ntfRTS {
  font-size: 10px;
  font-weight: 400;
  color: var(--greyDark);
}
.ntfMenu {
  display: flex;
}
.ntfForm label.form-label {
  font-size: 14px;
}
#ntfTitleInput,
.ntfBodyInput {
  font-size: 12px;
  padding-left: 5px;
}
.ntfStartEndDate {
  font-size: 12px;
  font-weight: 600;
}
.ntfMessage {
  margin: 8px;
}
.ntfDialog {
  width: 700px;
}

.notiCreateCont div.dateContainer {
  width: 100%;
  justify-content: center;
}
.notiCreateCont div.dateInnerView {
  margin-left: 20px;
}
.ntfTabsBar {
  padding-left: 20px;
}
.ntfBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.submitNtfBtn {
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
}

.ntfClose {
  position: absolute;
  float: right;
  margin-top: 5px;
  color: gray;
}
.ntfHeader {
  font-size: 1.5rem;
  text-align: center;
  margin: 10px;
  color: var(--color-primary);
  font-weight: 500;
  pointer-events: none;
}
.ntfItem {
  width: 20rem;
  display: contents;
}
.ntfIcon {
  font-size: 30px;
  padding: 5px 15px;
}

.ntfTitle {
  margin: 8px;
}

.notiContainer {
  position: absolute;
  border: none;
  border-radius: 5px;
  background-color: var(--white);
  width: 30%;
  top: 40px;
  right: 10px;
  padding: 10px 0px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 2000;
}

.notiContainer div.notiContHead {
  text-align: left;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 20px;
  align-items: center;
}

div.notiContHead .refreshBtn {
  margin-top: 0px;
  color: var(--link);
  background: transparent;
  border: 1px solid var(--link);
  box-shadow: none;
  padding: 0px 5px;
  margin-right: 10px;
  border-radius: 10px;
}
div.notiContHead .refreshBtn:hover {
  background-color: var(--link);
  color: var(--white);
}

.notiContainer div.searchOptionCont {
  max-height: 500px;
  overflow: auto;
}

#NotiPopUp div.confirmBodyCont {
  width: auto;
  max-height: 500px;
  overflow: auto;
}
.createNewNotiLink {
  border-radius: 10px;
  border: 1px solid var(--link);
  padding: 0px 5px;
}
.createNewNotiLink:hover {
  text-decoration: none;
  cursor: pointer;
  background-color: var(--link);
  color: var(--white);
}
.ntfHeadCard {
  padding: 10px 15px;
  background-color: var(--white);
  box-shadow: 0px 1px 5px var(--greyMedium);
  margin-bottom: 5px;
  code {
    @include codeStyleOverride
  }
}
.cardHeader {
  font-size: 14px;
  font-weight: 700;
}
.cardContent {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.ntfItemsCard {
  margin-top: 10px;
  padding: 10px 15px;
}
div .ntfItemsCard a.carousel-control-next polyline,
div .ntfItemsCard a.carousel-control-prev polyline {
  stroke: var(--primary) !important;
  stroke-width: 5;
}
div .ntfItemsCard a.carousel-control-next:hover,
div .ntfItemsCard a.carousel-control-prev:hover {
  background-color: var(--primary10),transparent !important;
  top: 180px;
  max-height: 10px;
}
div .ntfItemsCard a.carousel-control-next,
div .ntfItemsCard a.carousel-control-prev {
  top: 180px;
  max-height: 10px;
  width: 10%;
}
div.ntfCarousel img {
  height: 350px;
  width: 80%;
  margin: 0 auto;
  object-fit: contain;
}
div.ntfCarousel .carousel-indicators {
  margin-bottom: 0px;
}

div.ntfCarousel .carousel-indicators .active {
  background-color: var(--primary);
}
div.ntfCarousel .carousel-indicators li {
  background-color: var(--greyLight);
}
div.MuiDialog-root,
div.MuiPopover-root {
  z-index: 3000 !important;
}
.addItemsBtn {
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
  background-color: var(--link);
  display: flex;
  border: none;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 15%;
}
.addItemsBtn:hover {
  cursor: pointer;
  background-color: var(--white);
  color: var(--link);
  border: 1px solid var(--link);
}

div.notiCreateCont p {
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
}
.ntfItemsCont {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;
  .mainItemContainer{
    position: relative;
    margin-bottom: 25px;
    margin-top: 10px;
  }
}

////add item

.itemCardCont {
  background-color: var(--white);
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.itemCardCont span.MuiBadge-root {
  display: flex;
}
.itemCardCont span.MuiBadge-badge {
  padding: 0px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  height: 5px;
}
.itemCardBody {
  display: flex;
  width: 100%;
}
.itemInputCont {
  width: 85%;
}
.imagePrevCont {
  height: 50%;
  display: flex;
  align-items: center;
  padding: 20px 0.75rem;
  font-size: 14px;
  color: var(--gray);
}
.itemInputs {
  height: 50%;
  border-bottom: 1px solid var(--greyLight);
}
.itemInputs input {
  border: none;
  font-size: 14px;
}
.itemInputs input:focus {
  outline: none;
  box-shadow: none;
}
.imagePrevCont img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 10px;
}
.imageUploadCont {
  width: 15%;
  border-right: 1px solid var(--greyLight);
}
.imageUploadCont button {
  width: 100%;
  border: none;
  background-color: transparent;
  height: 150px;
}
div.notiCreateCont .dateInnerView {
  width: 35%;
}
div.notiBtnCont {
  display: flex;
}
div.notiBtnCont .sPopCloseBtn {
  width: auto;
  margin-right: 20px;
}
.errorFooterCont {
  justify-content: space-between;
}
div.errorFooterCont .errorMsg {
  margin-left: 20px;
  font-size: 15px;
  font-style: italic;
}

//// Rich text toolbar css
div.notiCreateCont .wrapper-class {
  border: 1px solid #ccc;
  border-radius: 5px;
  background: var(--white);
}
div.notiCreateCont .editor-class {
  border: none;
  padding: 5px;
  max-height: 25vh;
  min-height: 15vh;
}
div.notiCreateCont .toolbar-class {
  border: none;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0px;
  justify-content: center;
}

div.notiCreateCont .toolbar-class .rdw-option-wrapper {
  border: 1px solid var(--background);
  padding: 5px;
  width: 35px;
  height: 30px;
}
div.notiCreateCont .toolbar-class a {
  color: var(--black75);
}
div.notiCreateCont .toolbar-class a:hover {
  color: var(--black75);
  text-decoration: none;
}

div.notiCreateCont .toolbar-class .rdw-dropdown-optionwrapper {
  width: auto;
  overflow-y: auto;
}
div.notiCreateCont .toolbar-class .rdw-link-modal {
  height: auto;
}

//Media Queries
@media screen and (max-width: 900px) and (min-width: 550px) {
  .notiContainer {
    width: 45%;
  }
}
@media screen and (max-width: 549px) {
  .notiContainer {
    width: 75%;
  }
}


.main-div-drag {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 50%;
  top: -20px;
  background-color: white;
  z-index: 1;
}

.badgeImageConatiner{
  display: flex;
  flex-direction: column;
  align-items: center;
  .imageSize{
    white-space: nowrap;
    padding: 0 5px 0 5px;
    font-size: 12px;
    padding-left: 10px
    }
    

}

