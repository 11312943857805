@import "./globle/css-variables.scss";
.titleLabel {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 16px;
}
.emailTitleLabel {
  font-weight: 600;
  font-size: 16px;
}

.signatureBox {
  background: var(--oceanBlue10);
  width: 100%;
  height: 100px;
}

.modalSignatureBody {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.signatureBoxFooter {
  display: flex;
  justify-content: space-between;
}

.signatureBtn {
  background-color: transparent;
  font-weight: 600;
}

.dashedLine {
  border-top-style: dashed;
  border-color: var(--greyLight);
  margin: 20px 15px 0 15px;
  border-top-width: 2px;
}

.dashedLineContainer {
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashedLineContainer label {
  font-size: 20px;
  color: var(--greyLight);
  margin-top: 15px;
}

.modalDashedLine {
  width: 100%;
  border-top-style: dashed;
  border-color: var(--greyLight);
  border-top-width: 2px;
}

.inlineEmailContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
  align-items: center;
  align-content: center;
}
.emailRemoveBtn {
  font-size: 16px;
  background: none;
  border: none;
  padding-top: 0px;
  color: var(--black);
}
.emailInput {
  font-family: "Ted Next";
  font-size: 13px;
  border-radius: 5px;
  width: 250px;
}

.emailBtn {
  font-size: 13px;
  font-weight: 600;
  border: none;
  color: var(--link);
  padding-left: 0;
  align-self: flex-start;
  background-color: var(--white);
}

.moreBtnContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.radioGroupContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}

//image upload
.imageUploadContainer {
  background-color: var(--white);
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 6px 8px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 100%;  
  overflow: auto;
}
.imageUploadContainer span.MuiBadge-root {
  display: flex;
}
.imageUploadContainer span.MuiBadge-badge {
  padding: 0px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.imageUploadContainer .uploadSection {
  width: 15%;
  border-right: 1px solid var(--greyLight);
}
.imageUploadContainer .uploadSection button {
  width: 100%;
  border: none;
  background-color: transparent;
  height: 150px;
}
.imageUploadContainer .previewContainer {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 0.75rem;
  font-size: 14px;
  color: var(--gray);
}
.imageUploadContainer .previewContainer img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 3px;
}
.imageUploadContainer .previewContainer .badgeContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

//side tabs
.sideTabsCont {
  display: flex;
  flex-direction: row;
  background-color: var(--white);
}
.sideTabsCont .inactiveItemCont,
.sideTabsCont .activeItemCont,
.sideTabsCont .leftActiveItemCont,
.sideTabsCont .leftInactiveItemCont {
  // padding: 15px 30px;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.sideTabsCont .itemPadding {
  padding: 15px 30px;
}
.sideTabsCont .itemPaddingSmall {
  padding: 10px 15px;
}
.sideTabsCont .activeItemCont {
  border: 2px solid var(--greyLight);
  border-right: 0px solid var(--greyLight);
}
.sideTabsCont .inactiveItemCont {
  border-right: 2px solid var(--greyLight);
}
.sideTabsCont .leftActiveItemCont {
  border: 2px solid var(--greyLight);
  border-left: 0px solid var(--greyLight);
}
.sideTabsCont .leftInactiveItemCont {
  border-left: 2px solid var(--greyLight);
}
.sideTabsCont .itemCont label,
.sideTabsCont .leftItemCont label {
  margin: 0px;
  font-weight: 600;
}
.sideTabsCont .itemContSmall label,
.sideTabsCont .leftItemContSmall label {
  margin: 0px;
  font-weight: 600;
  font-size: 14px;
}
.sideTabsCont .itemCont svg {
  margin-right: 10px;
}
.sideTabsCont .itemContSmall svg {
  margin-right: 5px;
}
.sideTabsCont .leftItemCont svg {
  margin-left: 10px;
}
.sideTabsCont .leftItemContSmall svg {
  margin-left: 5px;
}
.showHide {
  display: flex;
}

//Drawer
.drawerContainer {
  display: flex;
  flex-direction: row;
  position: fixed;
  height: 100%;
  width: 95%;
  // transition: 500ms;
}
.drawerContainer .childrenStandardCont,
.drawerContainer .childrenFullCont,
.drawerContainer .menuIconCont,
.drawerContainer .closedContainer {
  // transition: 500ms;
}
.drawerContainer .closedContainer {
  width: 0%;
}
.drawerContainer .childrenStandardCont,
.drawerContainer .childrenFullCont,
.drawerContainer .contentFullScreenCont,
.drawerContainer .hiddenFullScreenCont {
  width: 100%;
  height: calc(100vh - 43px);
}
.drawerContainer .contentStandardCont,
.drawerContainer .contentFullScreenCont {
  background-color: var(--white);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  // transition: 500ms;
  z-index: 1;
}

.drawerContainer .hiddenFullScreenCont,
.drawerContainer .hiddenStandardCont {
  background-color: var(--white);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  transition: width 500ms;
  position: absolute;
  right: 0;
  z-index: 2;
}
.drawerContainer .hiddenStandardCont {
  width: 23%;
  height: calc(100vh - 43px);
}
.drawerContainer .closedHiddenContainer {
  position: absolute;
  // transition: width 500ms;
  right: 0;
  width: 0%;
}
.drawerContainer .contentStandardCont {
  width: 40%;
}
.drawerContainer .contentHeaderCont {
  /*  display: flex; */
  padding: 0px 10px;
  /*  justify-content: space-between; */
}
.drawerContainer .contentHeaderCont .spaceBetweenCont {
  /*  display: flex; */
  align-items: center;
}
.drawerContainer .contentHeaderCont .spaceBetweenCont .MuiDivider-vertical {
  height: 100%;
  margin: 0px 10px;
}
.drawerContainer .contentHeaderCont .leftIconsCont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 7px 0px;
  align-items: center;
}
.drawerContainer .contentHeaderCont .svg {
  width: 20px;
  height: 20px;
  // color: var(--primary);
  margin-right: 5px;
}
.drawerContainer .contentHeaderCont .svg:hover {
  color: var(--primary);
}
.drawerContainer .menuIconCont {
  position: absolute;
  border-radius: 25px;
  background-color: var(--white);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 7px;
  top: 70px;
  right: 10px;
}
.drawerContainer .menuIconCont svg {
  color: var(--primary);
  width: 30px;
  height: 30px;
}
.drawerContainer .menuIconCont:hover {
  background-color: var(--primary);
}
.drawerContainer .menuIconCont:hover svg {
  color: var(--white);
}

//Side Bar Tabs
.sideBarTabsCont {
  display: flex;
  background-color: var(--white);
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 81px);
}
.sideBarTabsCont .activeItemCont {
  border-right: 5px solid var(--primary);
  border-radius: 4px;
  background-color: var(--primary10);
}
.sideBarTabsCont .activeItemCont svg,
.sideBarTabsCont .inactiveItemCont svg {
  width: 20px;
  height: 20px;
  margin: 5px 5px 5px 10px;
}
.sideBarTabsCont .activeItemCont svg,
.sideBarTabsCont .inactiveItemCont svg:hover {
  color: var(--primary);
}
.sideBarTabsCont .activeItemCont svg path {
  fill: var(--primary);
}
.tabDataCont {
  padding: 5px 10px;
  width: -webkit-fill-available;
  overflow: auto;
  height: calc(100vh - 90px);
}
.sideBarTabs {
  padding: 5px 5px;
  border-left: 2px solid var(--primary10);
}
.sideBarTabs .MuiDivider-root {
  margin: 5px 0px;
}

#draggableDiv {
  position: absolute;
  z-index: 99;
  cursor: move;
}

//locker component
.lockerCont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 90vh;
}
.lockerCont .lockerIconCont {
  border: 1px solid var(--pureBlack);
  padding: 30px;
  margin-bottom: 30px;
  background-color: var(--pureBlack);
  border-radius: 50%;
}
.lockerIconCont svg {
  width: 200px;
  height: 200px;
  color: var(--primary);
}
.lockerCont input {
  padding: 5px 20px;
  font-size: large;
  border-radius: 15px;
  border: 2px solid var(--pureBlack);
  text-align: center;
}
.lockerCont input:focus {
  outline: none;
  border: 2px solid var(--primary);
}
.lockerCont label {
  font-size: large;
  font-weight: 700;
}

//Input History Component
.historyContainer {
  position: absolute;
  border: none;
  border-radius: 5px;
  background-color: var(--white);
  width: 30%;
  margin-top: 5px;
  padding: 10px 0px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 2000;
}
.historyContainer div.searchContHead {
  text-align: left;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 20px;
}
.historyContainer div.searchOptionCont {
  max-height: 170px;
  overflow: auto;
}
section.searchPOPUpInputCont .historyContainer {
  width: 60%;
}

@media screen and (max-width: 1000px) {
  .drawerContainer .childrenStandardCont {
    width: 0%;
  }
  .drawerContainer .contentFullScreenCont,
  .drawerContainer .contentStandardCont {
    width: 100%;
  }
}

// DROP FILE COMPONENT
.drop-file-input {
  position: relative;
  max-width: 400px;
  min-width: 200px;
  height: 200px;
  border: 2px dashed var(--primary);
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(149, 157, 165, 0.2) 0px 8px 24px; //var(--box-shadow);
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin: auto;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-input__label {
  text-align: center;
  color: #ccc; //var(--txt-second-color);
  font-weight: 600;
  padding: 10px;
}

.drop-file-input__label svg {
  width: 100px;
}

.drop-file-preview {
  margin-top: 30px;
}

.drop-file-preview p {
  font-weight: 500;
}

.drop-file-preview__title {
  margin-bottom: 20px;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  background-color: #f5f8ff; //var(--input-bg);
  padding: 15px;
  border-radius: 20px;
}

.drop-file-preview__item svg {
  width: 50px;
  margin-right: 20px;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drop-file-preview__item__del {
  background-color: var(--box-shadow);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity: 1;
}
