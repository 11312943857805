@import "../globle/css-variables.scss";

.textBoxPJI {
  font-family: "Ted Next";
  font-size: 10px;
  font-weight: auto;
  border-radius: 5px;
}

.dropDownPJI {
  font-size: 12px;
  font-weight: 500;
  width: auto;
  padding-left: 1px;
  padding-right: 10%;
  border-radius: 5px;
}

.pjiapplicationBoxWideContent {
  padding-top: 6.5px;
}

.pjiWOSection {
  display: "flex";
  justify-content: "start";
  column-gap: "20px";
}

.pjiapplicationBoxWide {
  width: 90.8px;
  margin-left: 10px;
  height: 79.7px;
  border: 3.44999px solid rgba(0, 0, 0, 0.14);
  box-sizing: border-box;
  border-radius: 11.5px;
  border-style: solid;
}

.pjiapplicationBoxWide:hover {
  color: var(--primary);
  border-color: var(--primary);
}

.pjiapplicationBoxWideContent p {
  font-weight: bold;
}

.refreshBtn-pji {
  // margin-top: 10px;
  // margin-bottom: 10px;
  position: relative;
  top: 23px;
  left: 20px;
  height: 30px;
  background-color: var(--primary);
  border: none;
  border-radius: 50%;
  color: var(--black75);
  box-shadow: 0px 0px 2px var(--black75);
  padding-bottom: 2.5px;
}

.toggleButtonContainer {
  display: "flex";
  justify-content: "space-between";
  font-size: "13px";
}

.selectorPJI {
  font-family: "Ted Next";
  font-size: 13px;
  width: auto;
  padding-left: 1px;
  font-weight: 600;
}

.selectPJI {
  font-size: 12px;
  font-weight: 500;
  width: auto;
  padding-left: 1px;
  padding-right: 10%;
  border-radius: 5px;
}

input[type="checkbox"]:checked {
  background: var(--primary);
  color: white;
}

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: lightgray;
  height: 19px;
  width: 19px;
  border: 1px solid white;
  border-radius: 20%;
  color: white;
  position: relative;
  bottom: 2.2px;
  vertical-align: middle;
}

input[type="checkbox"]:after {
  content: " ";
  position: relative;
  left: 45%;
  top: 30%;
  width: 15%;
  height: 40%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(50deg);
  display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
}

.task-box {
  border-radius: 5px;
  width: 75%;
  height: 25px;
  border-width: thin;
}

.task-container {
  padding: 15px 0 10px 0;
}

.overallPJI {
  font-family: "Ted Next";
  font-size: 13px;
}

.select {
  font-size: 12px;
  font-weight: 500;
  width: auto;
  padding-left: 1px;
  padding-right: 10%;
  border-radius: 5px;
}

.toggleButtons {
  background-color: white;
  color: var(--greyMedium);
  border-color: var(--greyMedium);
  font-size: 13px;
  font-family: "Ted Next";
  text-align: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.toggleButtons.disabled {
  background-color: white;
  color: var(--greyMedium);
  border-color: var(--greyMedium);
  font-size: 13px;
  font-family: "Ted Next";
  text-align: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.toggleButtons.active {
  background-color: var(--primary) !important;
  color: white;
  border-color: var(--greyMedium) !important;
  font-size: 13px;
  font-family: "Ted Next";
}
.toggleButtons:hover {
  background-color: var(--primary) !important;
  color: white;
  border-color: var(--greyMedium) !important;
  font-size: 13px;
  font-family: "Ted Next";
}

.nextButton,
.nextButton:hover {
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid var(--disabled);
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 13px;
}

.nextButton:disabled,
.nextButton.disabled {
  background-color: var(--disabled);
  color: var(--white);
  border: 1px solid var(--disabled);
  font-size: 13px;
}

.submitPJIBtn,
.submitPJIBtn:hover {
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid var(--disabled);
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 13px;
}

.submitPJIBtn:disabled,
.submitPJIBtn.disabled {
  background-color: var(--disabled);
  color: var(--white);
  border: 1px solid var(--disabled);
  font-size: 13px;
}

.pjiComments {
  font-family: "Ted Next";
  font-size: 13px;
}

.btnContainerPJI {
  padding: 10px 0 0 0;
}

.pjiFormContainer {
  padding: 25px 0 0 25px;
}

.pjiRefreshBtn {
  font-size: 12px;
  font-weight: 400;
  background-color: var(--link);
  color: var(--white);
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
}

label.pjiCheckBox {
  font-size: 16px;
  color: var(--black);
  margin: 0px 0px;
}
label.pjiCheckBox svg {
  font-size: 16px;
}

.checkbox-pji {
  justify-content: flex-start;
  align-items: flex-start;
}

.pjiCont {
  display: flex;
}

.pjiLink {
  font-size: 12px;
  font-weight: 500;
  background: none;
  border: none;
  // padding-left: 30px;
  cursor: pointer;
  text-decoration: underline;
  color: var(--link);
  position: relative;
  bottom: 1.5px;
  left: 40px;
}

.pjiWODropdown {
  display: "flex";
  justify-content: "start";
  column-gap: "20px";
}

.pjiToggleButtons {
  max-width: "150px";
  padding-top: "0.5%";
  padding-bottom: "0.5%";
}

.description {
  font-family: "Ted Next";
  font-size: 12px;
  padding-left: 5px;
  font-weight: auto;
  border-radius: 5px;
}
.description-form-input {
  font-family: "Ted Next";
  margin-bottom: 10px;
  font-size: 12px;
  padding-left: 5px;
  font-weight: auto;
  border-radius: 5px;
  width: 70%;
}

.manualWOinput {
  font-family: "Ted Next";

  font-size: 12px;
  padding-left: 5px;
  font-weight: auto;
  border-radius: 5px;
  width: 40%;
}

.form-inline-email {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.lop-input {
  width: 93%;
}

.removeButton,
.removeButton:hover {
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid var(--disabled);
  border-radius: 5px;
  padding: 5px 15px;
  margin-left: 10px;
  font-size: 13px;
  height: 32px;
}

.removeButton:disabled,
.removeButton.disabled {
  background-color: var(--disabled);
  color: var(--white);
  border: 1px solid var(--disabled);
  font-size: 13px;
}

.formLinkBtn {
  font-size: 12px;
  font-weight: 500;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--link);
  padding-left: 0;
  margin-left: 0;
}

.formRemoveBtn {
  font-size: 16px;
  background: none;
  border: none;
  padding-bottom: 14px;
  color: var(--black);
}

.signatureBox {
  background: #d9f0f8;
  width: 100%;
  height: 100px;

  border: none;
}

.appRadioBtn {
  font-family: "Ted Next";
  font-size: 13px;
  padding-left: 6px;
}

input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  position: relative;
  top: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}
.input-approve-group:focus-within label {
  color: var(--primary) !important;
  font-weight: 500 !important;
}
.input-decline-group:focus-within label {
  color: var(--primary) !important;
  font-weight: 500 !important;
}

.signatureBtn {
  border: none;
  background-color: transparent;
  color: var(--primary);
  font-weight: 600;
  padding: 0 20px 15px 20px;
}

.signatureBoxFooter {
  display: flex;
  justify-content: space-between;
}

.sigCanvas {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-signature-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.emailErrorMsg {
  color: var(--danger);
  font-weight: 600;
  font-size: 13px;
}

.reviewPJICont {
  font-size: "5px";
  margin: "3.5px";
  padding-left: 10px;
}

.pjiReviewLogo {
  position: relative;
  bottom: 1px;
}

.pjiErrorMsg {
  padding-bottom: 10px;
}

.PJIViewOnlyMargin {
  font-family: "Ted Next";
  font-size: 13px;
  padding-right: 30px;
  // padding-top: 20px;
}

.PJIViewOnlyInitialChecks {
  // width: 60%;
  padding-right: 30%;
  font-size: 12px;
}
.PJIViewOnlyText {
  padding-right: 20%;
  // width: 70%;
}
.PJIViewOnlyTitle {
  font-size: 13px;
  font-family: "Ted Next";
  font-weight: 600;
}

.PJIViewOnlyTitleSteps {
  font-size: 13px;
  font-family: "Ted Next";
  font-weight: 600;
  // margin-top: 10px;
}

.task-no-box {
  width: 75%;
  height: 25px;
  font-size: 13px;
  font-family: "Ted Next";
  // padding-left: 10px;
}
.PJIViewOnlyId {
  // align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.PJIViewOnlyMappedData {
  // display: flex;
  // flex-direction: row;

  justify-content: flex-end;
  padding-right: 50px;
}
.PJIViewOnlyMappedDataLabel {
  // flex-grow: 4;
  // display: "flex";
  width: 400px;
  // font-size: 13;
}
.PJIViewOnlyMappedDataButton {
  // flex-grow: 1;
  // display: "flex";
  justify-content: end;
}
.PJIViewOnlyData {
  display: "flex";
  flex-direction: row;
  // align-items: center;
  // justify-content: "space-between";
  // width: ;
}

.PJIViewOnlyDataVertical {
  display: "flex";
  flex-direction: column;
  align-items: center;
  justify-content: "space-between";
}
