@import "../../../../design-tokens/globle/css-variables.scss";

label.cpatFilter {
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 12px;
}
.dateAndDaysBackFilter {
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.dateFilter {
  margin: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
div.dateFilter .fromToDate,
div.fromToDate svg {
  margin-left: 5px;
}
.daysBack {
  margin-left: 5px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid var(--greyLight);
  padding: 2px;
  text-align: center;
}
.cpatDateFilter{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.cpatDateFilter .inputItemsCont{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-right: 15px;
}
.searchByFilter {
  margin: 5px;
}
section.radioBtnCont {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  flex-direction: row;
}
.cpatRadioButtons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0px 5px;
}
.cpatRadioButtons div.radioBtnContainer label {
  margin: 0px 10px 0px 5px;
}
div.radioBtnContainer label:hover,
div.radioBtnContainer input:hover {
  cursor: pointer;
}
div.cpatDropDown #testTypeDd {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyLight);
  font-weight: 300;
  font-size: 0.9em;
  justify-content: space-between;
  display: flex;
  margin-right: 20px;
  width: 250px;
  margin-bottom: 5px;
  align-items: center;
}

div.testTypeDropd .dropdown-menu {
  width: 250px;
}
.cpatDropDown {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.eventTextBox {
  width: 300px;
}
.codeTextBoxes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
}
.minCodeText {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 5px;
  width: 130px;
}
.maxCodeText {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  width: 130px;
}

div.levelFilter,
div.coverageFilter {
  margin: 5px;
  width: 20vw;
}
div.subLevelCont {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
}
div.subLevelCont label {
  width: 50%;
  margin-bottom: 0px;
}
div.subLevelCont div.levelInputCont {
  width: 50%;
}

div.levelInputCont .stepperInput button#stepperMapEleBtn,
div.levelInputCont .stepperInput button#stepperMapEleBtnDis {
  font-size: 14px;
  height: 25px;
  width: 25px;
}
div.levelInputCont .stepperInput input#stepperEleInput {
  font-size: 12px;
  padding: 0px 10px;
  height: 30px;
}

.searchCPATBtn button {
  border: none;
  background: var(--primary);
  color: var(--white);
  padding: 5px 10px;
  width: 100%;
  border-radius: 5px;
  font-size: 13px;
}

div.cpatTabCont .cpatEventsTable {
  max-height: 70vh;
  overflow: auto;
}

div.cpatTabCont .cpatEventsTable th {
  min-width: 90px;
}
