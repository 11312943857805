@import "../globle/css-variables.scss";

.container {
  width: 100%;
  display: contents;
}
.container #dropdownBtn {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 15px;
}
.container label {
  font-size: 17px;
}

.workOrderIDContainer {
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.workOrderIDContainer #dropdownBtn {
  background: transparent;
  color: var(--pureBlack);
  border: none;
  font-size: 15px;
}

.centeredOptionsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: center;
}
.centeredOptionsContainer input {
  display: block;
  width: 100%;
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  padding: 3px 5px;
  font-size: 15px;
}

.itemContainer {
  width: 200px;
}

.labelTxt {
  font-weight: 600;
  margin-bottom: 2px;
}

.confirmBtn {
  background: transparent;
  color: var(--primary);
  border-radius: 5px;
  border: 1px solid var(--primary);
  margin-top: 20px;
}

.refreshIcon {
  font-size: 25px;
  color: var(--greyMedium);
  margin-left: 3px;
}

.submitBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.submitBtnContainer button,
.submitBtnContainer button:hover,
.submitBtnContainer button:focus {
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid;
  padding: 5px 15px;
  font-size: 15px;
}

.submitButton:disabled,
.previewButton:disabled {
  background-color: var(--disabled);
  color: var(--white);
  border: 1px solid var(--disabled);
  font-size: 15px;
}

.submitBtnContainer .previewButton {
  margin-right: 10px;
}

.submitBtnContainer .noiseSubmitButton {
  margin-left: 10px;
}

.submitBtnContainer .noiseResubmitButton {
  margin-left: 10px;
}
