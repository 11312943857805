@import "../globle/css-variables.scss";
.cmDetailsPage {
  font-family: $rogers-font;
  background-color: var(--background);
}
.cmBasicDetailsCont {
  display: flex;
  border-bottom: 1px solid var(--greyLight);
}

.cmSMInfoCont,
.cmFullInfoCont {
  display: flex;
  padding: 10px;
  width: 95%;
}
.cmSMInfoCont {
  flex-direction: row;
  align-items: center;
}
.cmFullInfoCont {
  flex-direction: column;
}
.cmBasicDetailsCont .nodeTglBtnCont {
  width: 5%;
  justify-content: flex-end;
}
.cmDetailsPage header.accTabsBar {
  padding-top: 0px;
}

//(View Less / View More) Info CSS
.cmFullInfoCont .cmBasicDetails,
.cmSMInfoCont .cmBasicDetails {
  width: 30%;
  display: flex;
  margin-left: 20px;
  flex-direction: column;
}
.cmSMInfoCont hr {
  width: 2px;
  height: 75px;
}

.cmBasicDetails p.cmMacID {
  font-size: 22px;
}
.cmBasicDetails p.cmMacID span:nth-child(odd) {
  font-weight: 600;
}
.cmBasicDetails p.cmMacID span:nth-child(even) {
  font-weight: 700;
}
.cmBasicDetails p,
.cmSummaryDetailCont p {
  font-size: 14px;
}
.cmBasicDetails p,
.cmSummaryDetailCont p {
  margin-bottom: 5px;
  display: flex;
}
.cmBasicDetails p span:nth-child(odd),
.cmSummaryDetailCont p span:nth-child(odd) {
  font-weight: 600;
}
.cmBasicDetails p span:nth-child(even),
.cmSummaryDetailCont p span:nth-child(even) {
  font-weight: 400;
  margin-left: 5px;
}
.cmBasicDetails p span.divideSpan {
  margin: 0px 10px;
}
.cmSummaryDetailCont {
  display: flex;
  flex-direction: row;
  margin: 10px 20px;
}

.cmSummaryDetailCont div {
  width: 50%;
}
.cmSummaryDetailCont p span:nth-child(odd) {
  width: 35%;
}
.cmSummaryDetailCont p span:nth-child(even) {
  width: 65%;
}

//Channel Details Card CSS

.channelCard {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 10px 5px;
  background-color: var(--white);
}
.cmChDetailHead {
  font-size: 15px;
  margin-bottom: 5px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.cmChDetailBody {
  display: flex;
}
.cmSpecSumm {
  display: inline-block;
}
.cmSpecTableHead {
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
}
.cmChDetailBody .channelDataTable {
  min-width: 25vw;
}
p.cmChBasicDetail span:nth-child(odd) {
  font-weight: 600;
}
p.cmChBasicDetail span:nth-child(even) {
  font-weight: 400;
  margin-left: 5px;
}
span.failSpan {
  color: var(--danger);
}
span.marginalSpan {
  color: var(--marginal);
}
.channelDataTable {
  margin: 10px;
}
.channelDataTable th {
  background-color: #e2e8f0;
  padding-left: 5px;
  border: 1px solid var(--greyLight);
  font-size: 12px;
}
.channelDataTable td {
  font-weight: var(--topoTDFN);
}
.channelDataTable tbody tr:nth-child(odd) {
  background-color: var(--background);
}
.channelDataTable tbody tr:nth-child(even) {
  background-color: #e5e5e5;
}
.channelDataTable tbody td {
  border: 1px solid var(--greyLight);
  padding-left: 5px;
}
.channelDataTable tbody td.multiLineValue {
  white-space: pre;
}

///channel Chart CSS

.chChartCont {
  border-radius: 10px;
  display: flex;
  padding: 10px 5px;
  background-color: var(--white);
  margin-bottom: 10px;
  flex-direction: column;
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.chChartCont .chChartBody {
  margin-top: 15px;
  margin-right: 35px;
  font-size: 13px;
}
.chChartBody p {
  margin-bottom: 5px;
  text-align: center;
  font-weight: 700;
}

.chChartBody hr {
  margin: 10px 0px;
}
.chChartBody div.chartToolTipCont {
  padding: 10px;
  border-radius: 5px;
  background: var(--white);
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
div.chartToolTipCont .ttAttrValue {
  color: var(--link);
}

div.cmChSummaryDetails {
  display: flex;
  flex-direction: column;
}
div.cmChSummaryDetails table.channelDataTable {
  margin: 5px 0px;
}
div.cmChSummaryDetails th{
  font-weight: var(--topoTHFN);
}
div.cmChSummaryDetails td{
  font-weight: var(--topoTDFN);
}
div.legendCont {
  display: flex;
  font-size: 12px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
div.legendCont span {
  margin: 0px 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

div.legendCont span svg {
  margin-right: 5px;
}
div.legendCont span.scQamCh {
  color: var(--link);
}
div.legendCont span.ofdmCh {
  color: var(--purple);
}
