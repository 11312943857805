@import "../../../../design-tokens/globle/css-variables.scss";

.topoDiagActionCont {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.topoDiagActionCont .selectionChipsCont {
  margin-top: 0px;
}
.stopTeleBtn {
  background-color: transparent;
  color: var(--link);
  border: 1px solid var(--link);
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10vw;
}
.stopTeleBtn:hover {
  background-color: var(--link);
  color: var(--white);
  border: 1px solid var(--link);
}
.stopTeleBtn svg {
  margin-left: 5px;
  font-size: 15px;
}
.teleBtnLoader {
  width: 35px;
}

.topoDiagDetail,
.cmBasicDetailCont {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 10px 5px;
}
.topoDiagDetail p {
  margin-bottom: 10px;
  text-align: center;
  font-weight: 600;
}
.topoDiagDetail hr,
.cmBasicDetailCont hr {
  margin-bottom: 10px;
}

.teleSummaryDetails {
  display: flex;
  flex-direction: row;
}

.teleSummaryDetails section {
  display: flex;
  width: 50%;
  margin-bottom: 10px;
  flex-direction: column;
}
.teleSummaryDetails span:first-child {
  font-weight: 600;
}
.teleSummaryDetails span:last-child {
  font-weight: 500;
}
.teleSummaryStat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
}
.teleSummaryStat section {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  width: 30%;
}
.teleSummaryStat section span:first-child {
  font-size: 15px;
  font-weight: 600;
}
.teleDiagGreen {
  color: var(--success);
  border: 1px solid var(--success);
}
.teleDiagRed {
  color: var(--danger);
  border: 1px solid var(--danger);
}
.teleDiagBlack {
  color: var(--pureBlack);
  border: 1px solid var(--pureBlack);
}
.warnTelyText {
  color: var(--warning);
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: italic;
}
.unplottedCMCont {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 10px 5px;
}
.unplottedCMCont p {
  margin-bottom: 5px;
  font-weight: 600;
}
.clusterCMCont {
  font-family: $rogers-font;
  font-size: 11px;
  font-weight: 500;
  max-height: 200px;
  overflow-y: auto;
}
.clusterMarker {
  font-family: $rogers-font;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  max-width: 70px;
  flex-wrap: wrap;
  background: transparent;
  padding: 3px;
  margin: 1px;
  color: var(--greyLight);
  border-radius: 5px;
  border: 1px solid var(--greyLight);
  /* box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); */
}
.clusterMarker span.clusterCMType {
  margin: 2px;
  display: flex;
  min-width: 25px;
  align-items: center;
}
.clusterMarker span.clusterCMType svg {
  margin-right: 3px;
}

.unplottedCMCont span.infoWarning {
  font-style: italic;
  color: var(--warning);
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
}
.unplottedCMCont span.infoWarning svg {
  margin-right: 5px;
}
.unplottedCMCont table.channelDataTable {
  margin: 5px 0px;
}
.unplottedCMCont table.channelDataTable th,
.clusterCMCont table.channelDataTable th {
  font-size: 11px;
  font-weight: var(--topoTHFN);
}
.unplottedCMCont table.channelDataTable td,
.clusterCMCont table.channelDataTable td {
  font-weight: var(--topoTDFN);
}
.unplottedCMCont table td.cmMacLink,
.clusterCMCont table td.cmMacLink {
  color: var(--link);
  cursor: pointer;
}
.unplottedCMCont table tr td:last-child,
.clusterCMCont table tr td:last-child {
  text-align: center;
  padding-left: 0px;
}

.legendBtnCont {
  display: flex;
  justify-content: flex-end;
}
.legendBtnCont .legendBtn {
  border-width: 0;
  padding: 0;
  background-color: transparent;
  color: var(--link);
  font-style: italic;
  margin-bottom: 5px;
  font-weight: 600;
}
.legendTableOpen {
  transition: 500ms;
  max-height: 500px;
}
.legendTableClose {
  transition: 500ms;
  max-height: 0px;
  overflow: hidden;
}

.cmBasicHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  align-items: center;
}
.cmBasicHeader svg {
  margin-right: 5px;
  font-size: 20px;
  color: var(--link);
}
.cmBasicDetailCont span.cmBasicDetail {
  margin-bottom: 5px;
  font-size: 13px;
}
.cmBasicDetailCont b {
  font-weight: 500;
  margin-right: 3px;
}
.cmChannelsStat {
  overflow: auto;
}
.cmChannelsStat .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  font-weight: 600;
  cursor: pointer;
}
.cmChannelsStat .card-body th,
.cmChannelsStat .card-body td {
  font-size: 11px;
}
td.failedText {
  color: var(--primary);
  font-weight: 600;
}
td.marginalText {
  color: var(--marginal);
  font-weight: 600;
}
td.passText {
  color: var(--success);
  font-weight: 600;
}
.cmChannelsStat .card-body table.dataTableDetails {
  min-width: auto;
}
.filterTabCont {
  margin: 5px 0px;
}
.diagOptionsCont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: flex-start;
  justify-content: flex-end;
}
.diagOptionsCont section {
  margin-left: 5px;
  color: var(--primary);
  box-shadow: 0px 1px 10px -1px rgba(218, 41, 28, 0.2),
    0px 1px 1px 0px rgba(218, 41, 28, 0.2),
    0px 1px 3px 0px rgba(218, 41, 28, 0.2);
  border-radius: 5px;
  border: none;
  padding: 5px;
  flex-direction: row;
  align-items: center;
  display: flex;
}
.diagOptionsCont section.filterActive {
  color: var(--white);
  background-color: var(--primary);
}
.diagOptionsCont svg {
  font-size: 20px;
}

//Advance Filter CSS
div#advFilterPopUp {
  max-width: 60vw;
}

div#advFilterPopUp div.modal-content {
  max-height: 90vh;
}
.advFilterPopHead {
  background-color: var(--primary);
  color: var(--white);
  font-weight: 700;
  padding: 10px;
  font-size: 14px;
  align-items: center;
}
.advFilterPopHead .advActionGroup {
  display: flex;
  justify-content: space-between;
}
.advFilterPopHead .advActionGroup button.custDDBtn {
  background-color: var(--white);
}
.advFilterPopHead .advActionGroup div.custDDOption {
  color: var(--primary);
}

.advFilterPopFoot {
  display: flex;
  justify-content: space-between;
}
div.advFilterPopFoot .advActionGroup {
  display: flex;
  align-items: center;
}
div.advFilterPopFoot .advActionGroup p {
  margin-bottom: 0px;
  display: flex;
  margin-right: 10px;
  align-items: center;
}
div.advFilterPopFoot .advActionGroup p span {
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: block;
  color: var(--primary);
  min-width: 50px;
  border: 0.5px solid var(--primary);
  cursor: pointer;
}

div.advActionGroup p span:first-child {
  border-radius: 5px 0px 0px 5px;
}
div.advActionGroup p span:last-child {
  border-radius: 0px 5px 5px 0px;
}
div.advActionGroup p span.activeCondSpan {
  color: var(--white);
  background: var(--primary);
}

div#advFilterPopUp div.modal-footer {
  padding: 5px;
  font-size: 14px;
}
div.advFilterCont {
  padding: 5px;
  overflow-y: auto;
}
div.advFilterCont hr {
  margin-bottom: 5px;
}
div.advFilterCont .channelCard {
  margin: 0px;
}
.advFilterCont .cmChDetailHead,
.advFilterBody,
.advFilterBody button.custDDBtn,
p.advfilterRow input.MuiInputBase-input {
  font-size: 12px;
}
.advFilterBody {
  padding: 0px 10px;
}

.advFilterCont .chFilterCont {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
.chFilterCont section.channelCard {
  width: 49%;
}

button.applyAdvFilterBtn {
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}
button.clearAdvFilterBtn {
  background: transparent;
  color: var(--primary);
  border: none;
  font-style: italic;
  font-weight: 600;
}
p.advfilterRow {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
p.advfilterRow .filterGroup {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 15px;
}
span.filterComparator {
  margin: 0px 10px;
  font-weight: 500;
}
span.labelGroup {
  width: 35%;
  display: flex;
  justify-content: space-between;
}

.advFilterBody div.custDDOptionsCont {
  min-width: 7%;
}
.advFilterBody div.custDDOption {
  min-height: 25px;
}
p.advfilterRow button.MuiIconButton-root {
  padding: 0px;
}
p.advfilterRow button.MuiIconButton-root svg {
  height: 15px;
  width: 15px;
}
p.advfilterRow .numStepperCont {
  margin-right: 10px;
}

div.summaryAdvFilters .numStepperCont {
  width: 7%;
}
div.chFilterCont .numStepperCont {
  width: 17%;
}
.chFilterCont input.partialCheckBox {
  position: static;
  height: 20px;
  width: 20px;
}
span.labelGroup,
span.filterLabel {
  font-weight: 500;
}
.advFilterBody div.searchContHead {
  display: flex;
}

div.cmBasicInfoCont .cmTabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 14px;
  justify-content: space-evenly;
  border: 1px solid #dee2e6;
  border-radius: 30px;
  background-color: var(--background);
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
