@import "../globle/css-variables.scss";
.mapContainer {
  width: 100%;
  height: 500px;
}
.mapMarkerGreen {
  color: var(--success);
  font-size: 30px;
  cursor: pointer;
}
.mapMarkerRed {
  color: var(--danger);
  font-size: 30px;
  cursor: pointer;
}
.markerDetailTable {
  background-color: var(--white);
  color: var(--pureBlack);
}
table.markerDetailTable th {
  width: 125px;
}
.areaViewCont {
  padding: 10px;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.filterCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filterCont hr {
  height: 30px;
}
div.searchNeigh {
  border: 1px solid var(--black75);
  width: auto;
  border-radius: 5px;
}
div.searchNeigh input,
div.searchNeigh button {
  height: 25px;
  border: none;
}
.neighViewCont {
  display: flex;
}
.neighViewCont svg {
  margin-top: 0px;
}
.neighViewCont .tableView:first-child,
.neighViewCont .mapView:last-child {
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--white);
}
#searchNeighBtn {
  padding: 0px 15px;
  background-color: var(--primary);
  border-radius: 4px;
}
.neighFilterCont {
  display: flex;
  width: 75%;
  align-items: center;
  justify-content: space-between;
}
.ddFilterCont {
  display: flex;
  align-items: center;
}
.neighTableCont {
  background-color: var(--white);
  margin-top: 20px;
  border-radius: 5px;
  overflow: auto;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.neighTable {
  font-size: 14px;
  min-width: 1150px;
  width: 100%;
}
.nTabBody tr:nth-child(odd) {
  background-color: var(--background);
}
.nodeTabBody tr:nth-child(odd) {
  background-color: var(--background);
}
.neighLinkCell {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.neighLinkCell:hover {
  text-decoration: none;
}
.neighLinkCell img,
.neighLinkCell svg {
  margin-left: 5px;
}
.failLinkState {
  color: var(--danger);
}
.successLinkState {
  color: var(--orange);
}
.passLinkState {
  color: var(--success);
}
.failLinkState:hover,
.passLinkState:hover,
.successLinkState:hover {
  text-decoration: underline;
  cursor: pointer;
}
div#customPopUp .cmSummaryCont {
  overflow-x: auto;
}
////// neighbourhood Map view

.neighMapContainer {
  height: 500px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  background-color: var(--white);
  margin-top: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.markerCont svg {
  position: absolute;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -100%);
}
.tapMarkerCont svg {
  position: absolute;
  width: 25px;
  height: 25px;
  transform: translate(-50%, -50%);
}

.homeCont svg {
  position: absolute;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -100%);
}
.neighLoaderCont {
  display: flex;
  align-items: center;
  height: 85vh;
  justify-content: center;
}
.neighLoaderCont img {
  width: 100px;
}

/* 
@media screen and (max-width: 1290px) and (min-width: 901px) {
  .neighFilterCont {
    width: 65%;
  }
}
@media screen and (max-width: 900px) and (min-width: 768px) {
  .neighFilterCont {
    width: 55%;
  }
}
@media screen and (max-width: 767px) and (min-width: 374px) {
  .filterCont {
    display: block;
  }
  .filterCont hr {
    display: none;
  }
  div.searchNeigh {
    width: fit-content;
  }
  .neighFilterCont {
    display: flex;
    width: 100%;
    margin-top: 15px;
  }
}
 */
