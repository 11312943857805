@import "../globle/css-variables.scss";
// @import "~bootstrap/scss/bootstrap";

.modal-90w {
  min-width: 40%;
  font-family: "Ted Next";
  min-height: 500px;
  max-height: 800px;
  overflow-y: hidden;
}

.monthly-modal-90w {
  min-width: 40%;
}

.close-monthly {
  background-color: var(--white);
  box-shadow: none;
  border-color: none;
  border-style: none;
  color: var(--primary);
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  right: 10px;
  top: 10px;
}

.monthly-modal-90w .modal-content {
  padding: 4%;
  padding-bottom: 0;
}

.monthly-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 3%;
}

#align-center {
  width: 80%;
}

#monthly-title {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 30px;
}
#monthly-subtitle {
  font-size: 12px;
}
.monthly-question {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  margin-bottom: 10px;
}

.monthly-why {
  font-size: 12px;
  padding-left: 5px;
  font-weight: auto;
  border-radius: 5px;
  margin-bottom: 35px;
}
.textarea {
  font-family: $rogers-font;
  font-size: 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-family: inherit;
  font-size: inherit;
  padding: 1px 6px;
  width: 80%;
  margin-bottom: 35px;
  text-align: left;
}
.submit-monthly {
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 13px;
  font-weight: 600;
}

.cancel-monthly {
  background-color: var(--white);
  text-decoration: none;
  box-shadow: none;
  border-color: none;
  border-style: none;
  font-size: 13px;
  padding-left: 17.5px;
  padding-right: 17.5px;
  color: var(--black75);
  font-weight: 600;
}

.monthly-dont-ask {
  background-color: var(--white);
  text-decoration: underline;
  box-shadow: none;
  border-color: none;
  border-style: none;
  font-size: 13px;
  color: var(--link);
  font-weight: 600;
  padding-left: 17.5px;
  padding-right: 17.5px;
}

.radio {
  color: var(--primary);
}

//FOR RATING////////
.ratingRadioGrpContainer {
  display: flex;
  flex-direction: "row";
  justify-content: space-between;
  align-items: center;
}

.ratingRadioGrpContainer p {
  font-size: 12px;
}

////////////////////////////////////

.container {
  display: flex;
  flex-direction: "row";
  justify-content: space-between;
  align-items: center;
}

.container p {
  font-size: 12px;
}

.item-container {
  align-items: "center";
}

.icon-monthly-rating {
  background-color: var(--white);
  box-shadow: none;
  border-color: none;
  border-style: none;
  width: moderateScale(20);
  height: moderateScale(20);
}

.text-monthly-rating {
  font-size: moderateScale(11);
  color: var(--black75);
  font-family: $rogers-font;
}
//DONE FOR RATING

.fbHeader {
  background-color: var(--primary);
  font-size: 16px;
  color: var(--white);
  padding: 2% 2% 2% 3%;
  font-family: $rogers-font;
  font-weight: bold;
}

.open {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-10%, 40%);
  background-color: var(--white);
  color: var(--primary);
  border: none;
  border-radius: 15px;
}

.open :hover {
  background-color: var(--primary);
}

.close {
  color: var(--white);
  font-size: 20px;
}

.close:hover {
  color: var(--white);
}

#myP {
  font-family: "Ted Next";
  font-size: 11px;
  font-weight: 300;
  line-height: 14px;
  margin-bottom: 2%;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.form-label {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
}

.selector {
  font-family: "Ted Next";
  font-size: 13px;
  width: auto;
  padding-left: 1px;
  font-weight: 600;
}

.select {
  font-size: 12px;
  font-weight: 500;
  width: auto;
  padding-left: 1px;
  padding-right: 10%;
  border-radius: 5px;
}

.description {
  font-family: "Ted Next";
  font-size: 12px;
  padding-left: 5px;
  font-weight: auto;
  border-radius: 5px;
}

.submission {
  display: flex;
  flex-direction: row;
  flex-flow: row-reverse;
  font-family: "Ted Next";
  height: 25px;
  align-items: center;
  margin-bottom: 30px;
}

.reset {
  background-color: var(--white);
  text-decoration: none;
  box-shadow: none;
  border-color: none;
  border-style: none;
  font-size: 13px;
  padding-left: 17.5px;
  padding-right: 17.5px;
  color: var(--link);
  font-weight: 600;
}

.submit {
  background-color: var(--lightGreen);
  border: none;
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
}

.submit:hover {
  background-color: var(--success);
  border: none;
}

.custom-file {
  font-size: 12px;
  font-family: "Ted Next";
  width: 60%;
}

.custom-file-input {
  cursor: pointer;
}

.feedback-tabs {
  font-family: "Ted Next";
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.feedback-tabs.navbar-nav.nav-tabs .nav-item {
  padding: 0;
  color: var(--color-secondary);
}

.feedback-tabs.navbar-nav.nav-tabs .nav-item.nav-link {
  border: none;
  padding: 2%;
  font-weight: 600;
  color: var(--pureBlack);
  opacity: 0.75;
  font-size: 14px;
  margin-right: 4%;
}

.feedback-tabs.navbar-nav.nav-tabs .nav-item.nav-link.active {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: var(--primary);
  text-decoration-thickness: 5px;
  font-weight: 700;
  opacity: 0.75;
}

.feedback-tabs.navbar-nav.nav-tabs {
  border: none;
  padding-top: 0;
}

.feedback-tabs.navbar-nav.nav-tabs.tab-content {
  height: 100%;
  padding: 0;
  margin: 0;
}

#feedback-container .modal-body {
  padding: 0;
}

#monthly-feedback-container .modal-body {
  padding: 0;
}
#edit-feedback-container .modal-body {
  padding: 0;
}
#comments-feedback-container .modal-body {
  padding: 0;
}

.form {
  padding: 1rem;
  padding-top: 0;
  margin-right: 1rem;
  padding-bottom: 0;
}

.fade.tab-pane {
  padding: 0;
}

.feedback-container {
  padding-bottom: 0;
  max-height: 42vh;
  overflow-y: scroll;
  font-family: "Ted Next";
  padding-right: 1rem;
  padding-left: 1rem;
}

.feedback-card.card {
  width: 100%;
  border: none;
}

.feedback-card {
  display: flex;
  direction: row;
  font-size: 12px;
  padding: 2%;
  // padding-left: 1%;
}
.feedback-title {
  margin-bottom: 0px;
}
.feedback-title p {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
  color: var(--pureBlack);
}
.feedback-title span {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--black75);
}

.attachment-link {
  background: none;
  border: none;
  color: var(--link);
  font-weight: 600;
}

.feedback-photo {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  object-fit: cover;
  margin-right: 3%;
}

.feedback-submission {
  margin-bottom: 0;
  opacity: 0.75;
}

.feedback-type {
  margin-bottom: 7.5px;
}

.feedback-body {
  width: 100%;
}

.icons-row {
  display: flex;
  flex-direction: row;
}

.icons-container {
  position: absolute;
  right: 0;
}

.icon {
  margin-right: 8px;
  height: 12px;
  cursor: pointer;
}

.card .historyHR {
  padding-top: 0px;
  margin: 0px;
  margin-left: 2%;
  margin-right: 2%;
}

.attachment-group {
  display: flex;
  flex-direction: column;
}

.attached-file {
  color: var(--link);
  font-size: 12px;
  font-weight: 600;
  font-family: "Ted Next";
  line-height: 15px;
  margin: 0;
  max-width: 85%;
  overflow: hidden;
}

.attached-file-container {
  padding-top: 15px;
  padding-left: 10px;
}

option .options {
  color: var(--primary);
}

.attachment {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 50%;
  padding-bottom: 1%;
}

.attachment Button {
  opacity: 0.75;
  border: none;
  color: var(--link);
  background-color: var(--white);
  font-size: 12px;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0;
}
.attachment Button:hover {
  opacity: 0.75;
  border: none;
  color: var(--link);
  background-color: var(--white);
  font-size: 12px;
}

.attachment-image {
  width: 100px;
  height: 100px;
}

hr {
  margin-bottom: 0;
}

svg {
  cursor: pointer;
}

.edit {
  padding: 0px;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
}

.edit:hover {
  background-color: none;
}

.comments-container {
  min-height: 100px;
  // overflow-y: scroll;
}

.comments-button {
  display: flex;
  flex-direction: row;
  background: none;
  border: none;
  color: var(--pureBlack);
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.comments-button-text {
  font-size: 10px;
  font-family: "Ted Next";
}

.comment-card {
  display: flex;
  flex-direction: column;
}
.comment-card-upper {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  display: flex;
  direction: column;
  font-size: 12px;
  padding-left: 1%;
  padding: 3%;
  margin: 10px;
  background-color: rgba(229, 229, 229, 0.5);
}
.comment-card-date {
  align-self: flex-end;
  font-size: 10px;
  margin-right: 10px;
}

.comment-text {
  display: flex;
  flex-direction: column;
}

.comment-text p {
  padding-bottom: 0;
  margin-bottom: 0;
}

.comment-card-date p {
  margin-bottom: 0;
}

.edit-form .form-text {
  margin-bottom: 10px;
}

.submit-edit {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.create-comment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 1rem;
}

.send-icon {
  color: var(--link);
  height: 34px;
  width: 34px;
}

.send-icon-button {
  background-color: var(--white);
  border-width: 0;
}
.send-icon-button:hover {
  background-color: var(--white);
  border-width: 0;
}
.send-icon-button::after {
  background-color: var(--white);
  border-width: 0;
}
.send-icon-button:active {
  background-color: var(--white);
  border-width: 0;
}
.send-icon-button::selection {
  background-color: var(--white);
  border-width: 0;
}

.back-button {
  color: var(--link);
  font-weight: 500;
  font-family: "Ted Next";
  font-size: 12px;
  padding: 0;
  margin: 0;
  padding-bottom: 12px;
  background-color: var(--white);
  text-decoration: none;
  box-shadow: none;
  border-color: none;
  border-style: none;
  font-size: 13px;
  padding-left: 1rem;
  padding-top: 1rem;
}

.errorMessage {
  font-style: italic;
  padding-top: 30px;
  color: var(--primary);
  font-weight: 600;
  padding-bottom: 0;
}

.hrMonthly {
  align-self: center;
  border-bottom: 1px solid darkGrey;
  margin-bottom: 10px;
}

//ANIMATIONS
#text {
  opacity: 0;
  transition: 1s;
  color: var(--success);
  align-self: center;
}

#text.fade-in {
  opacity: 1;
  transition: 1s;
}

#text.hide {
  display: none;
}

.old-attachments {
  color: var(--link);
  padding-top: 5px;
  padding-bottom: 5px;
}

.old-attachments-container {
  display: none;
  padding-bottom: 10px;
  flex-direction: column;
}

#no-attachment {
  margin-bottom: 0;
}

#tooltip {
  z-index: 100000 !important;
}

#tooltip-popup {
  padding-bottom: 0;
  margin-bottom: 0;
}

.feedBackBugContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.show-more-less{
  font-size: 12px;
}
.feedback-description{
  margin:0
}
