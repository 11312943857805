@import "../globle/css-variables.scss";

.historyRootContainer {
  background-color: var(--greyLight);
}
.filterRootContainer {
  padding: 10px;
  display: flex;
  border-radius: 5px;
  border-width: 2px;
  flex-direction: "row";
  font-family: $rogers-font;
  border-color: var(--greyLight);
  background-color: var(--white);
}
.filterText {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--pureBlack);
}
.filterTitle {
  font-size: 14px;
  font-weight: 600;
  color: var(--pureBlack);
}
.filterContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
}
.filterRow {
  display: flex;
  flex-direction: "row";
  margin-top: 5px;
  align-items: center;
}
.filterRowItem {
  display: grid;
  flex-direction: "column";
}
.filterBtn {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.applyFilersBtn {
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
}
.inputContainer {
  width: 250px;
  margin-right: 20px;
}
.inputField {
  font-family: "Ted Next";
  font-size: 12px;
  padding-left: 5px;
  font-weight: auto;
  border-radius: 5px;
}
div.testTypeCont #testTypeDd {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyLight);
  font-weight: 400;
  justify-content: space-between;
  display: flex;
  margin-right: 20px;
  width: 250px;
  margin-bottom: 15px;
  align-items: center;
}
div.testTypeDd .dropdown-menu {
  width: 100%;
}
div.testTypeDropd .dropdown-menu {
  max-height: 130px;
}
.dateContainer {
  display: flex;
  flex-direction: "row";
  width: 300px;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
}
.dateInnerView {
  width: 145px;
}
.fromToRootContainer {
  padding: 10px;
  display: flex;
  flex-direction: "row";
  font-family: $rogers-font;
  background-color: var(--white);
}
.fromToDate {
  border-radius: 5px;
  margin-top: 0px;
  border: 1px solid var(--greyLight);
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.fromToDate:hover {
  color: var(--primary);
  border: 1px solid var(--primary);
}
.fromToDateLabel {
  font-size: 14px;
  font-weight: 400;
  color: var(--greyMedium);
}

//////history
.historyRootContainer {
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border-width: 2px;
  flex-direction: "row";
  font-family: $rogers-font;
  border-color: var(--greyLight);
  background-color: var(--white);
}
.loader {
  padding-top: 100px;
}
.historyResultCont {
  margin: 0px 0px;
}
