@import "../globle/css-variables.scss";

.FeedBackBtnContainer {
    display: flex;
    justify-content: space-between;
    color: var(--pureBlack);
}

.FeedBackBtnContainer #dropdown-basic{
    background: transparent;
    color: var(--pureBlack);
    border: 1px solid var(--greyLight);
    box-shadow: 0 0 0 0.1rem rgb(109, 140, 206); //copied this from Select workoreder id
}
///////

  .checkboxContainer label {
    flex: 1;
    margin-right: 30px;
    font-size: 15px;
  }
  
  .titleLabel {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  
  .viewFBContainer {
    align-self: center;
    margin: 25px;
  }
  .viewFBContainer .titleLabel {
    font-weight: 700;
    font-size: 16px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .viewFBContainer .textLabel {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .viewFBContainer .carouselContainer {
    width: 700px;
  }
  
  .viewFBContainer .carouselContainer a.carousel-control-next:hover,
  .viewFBContainer .carouselContainer a.carousel-control-prev:hover {
    background-color: var(--primary10);
  }
  
  .viewFBContainer .carouselContainer a.carousel-control-next polyline,
  .viewFBContainer .carouselContainer a.carousel-control-prev polyline {
    stroke: var(--primary) !important;
    stroke-width: 5;
  }
  
  
  