.row {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid white;
  }
  
  .filemanager-content form .row:nth-child(even) {
    background-color: #f2f2f2; /* Grey shade for even rows */
  }
  