@import "../../../../design-tokens/globle/css-variables.scss";

.tree-container {
    width: 100vw;
    height: 100vh; 
  }

  .zoomBtnContainer {
    position: absolute;
    flex-direction: column;
    bottom: 20px;
    right: 20px;
    background-color: var(--white);
    padding: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color:var(--greyLight);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .labelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .label {
    margin: 15px;
    font-size: 20px;
    font-weight: 700;
    color: var(--greyDark);
  }

  .labelMedium {
    font-size: 15px;
    font-weight: 700;
    color: var(--greyDark);
  }

  .basicHeaderIcon{
    width: 20px;
    height: 20px;
    margin-left: 10px;
    color: var(--link);
  }
  .basicHeaderIcon:hover{
    color: var(--primary);
  }

  .node__root > circle {
    fill: var(--primary);
  }
  
  .node__branch > circle {
    fill: var(--orange);
  }
  
  .node__leaf > circle {
    fill: var(--white);
  }