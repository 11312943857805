@import "../globle/css-variables.scss";div#modalBody {
  font-size: 16px;
  font-family: $rogers-font;
  padding: 20px;
  margin: auto;
}
#WOTabsBar {
  box-shadow: none;
}
.WOpopUp .modal-content {
  height: 820px;
}

.WOpopUp .modal-body {
  padding-right: 20px;
}
.WODetailColumnsContainer {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.WODetailColumn {
  flex: auto;
  flex-direction: column;
  display: inline-flex;
  width: 100%;
  box-sizing: border-box;
  max-height: inherit;
}

.WODetailRow {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: inherit;
}

.WODetailColumnInfo .ddButton button {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyLight);
  font-weight: 400;
  justify-content: space-between;
  display: flex;
  width: 25%;
  align-items: center;
  min-width: 120px;
  margin-right: 20px;
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
}

@media screen and (min-width: 993px) {
  div#modalBody {
    font-size: 1em;
  }
  .WOpopUp .modal.modal-content {
    max-width: 600px;
    max-height: 820px;
  }
}

@media screen and (min-width: 426px) and (max-width: 992) {
  // tablet size
  .WOpopUp .modal.modal-content {
    max-width: 80%;
    max-height: 80%;
  }
  div#modalBody {
    font-size: 0.85em;
  }
}

@media screen and (max-width: 425px) {
  // mobile size
  .WOpopUp .modal.modal-content {
    max-width: 100%;
    max-height: 100%;
  }
  .WOpopUp div#modalBody {
    font-size: 0.7em;
  }
}

.WODetailRow header,
p#headerP,
#attachLabel,
.WODetailRow .titleLabel {
  margin-bottom: 6px;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
}

div.WODetailColumnInfo > * {
  margin-left: 20px;
}

.WODetailColumnInfo p {
  min-height: 24px;
}

.submission#WOStatusSubmit {
  padding-right: 40px;
}

.WODetailColumn header {
  margin-bottom: 6px;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
}

.WODetailColumnInfo {
  margin: 5px;
  min-width: 250px;
}
.WODetailColumnInfo .ddButton.dropdown > button {
  height: 30px;
}

.WOpopUp button.MuiTabs-root {
  min-width: 250px;
}

p.midMargin {
  padding-left: 10px;
}

// .WODetailColumnInfo .dropdown-menu.show {
//   width: 400px;
// }

.addtDropDown {
  min-width: min-content;
  width: 350px;
  padding: 0px;
}

.WODetailColumnInfo table.dataTableDetails {
  margin-top: 10px;
  margin-bottom: 10px;
  table-layout: auto;
  width: 650px;
  min-width: 300px;
  max-width: 800px;
}

.WODetailColumnInfo .DdCont {
  margin: 5px;
}

.actionButton {
  background-color: var(--white);
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 3px 40px;
  font-size: 16px;
  font-weight: 900;
  flex-direction: row;
  margin: 20px;
  margin-top: 10px;
  max-height: 32px;
}
.actionButton:disabled {
  border: none;
}
.warnText {
  margin: 13px 20px 20px;
  vertical-align: middle;
  color: var(--primary);
  font-weight: 600;
}

.hiddenDiv {
  visibility: hidden;
  margin: none !important;
  height: 0px !important;
}

.productListItem {
  display: flex;
  flex-direction: row;
  padding: 10px;
  font-size: 13px;
  font-weight: 600;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: inherit;
  width: auto;
}
.productListItem > span {
  min-width: fit-content;
  padding-right: 15px;
}

.tableText {
  width: 95%;
}

.trashIconSpan {
  width: 5%;
  margin: 5px;
}

.errorTableRow {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mandatoryAst {
  color: var(--danger);
}
