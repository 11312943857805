@import "./design-tokens/globle/css-variables.scss";
.App {
  display: flex;
  flex-direction: row;
  width: 100%;
}

////drawer section
.drawerOpenSec {
  background: var(--black75);
  width: 15%;
  position: fixed;
  display: flex;
  height: 100%;
  color: var(--white);
  z-index: 1200;
  transition: 500ms;
  flex-direction: column;
}
.drawerCloseSec {
  background: var(--black75);
  width: 5%;
  display: flex;
  position: fixed;
  z-index: 1200;
  height: 100%;
  color: var(--white);
  transition: 500ms;
  flex-direction: column;
}
section.drawerCloseSec .drawerBtn {
  justify-content: center;
}
section.drawerOpenSec .drawerBtn {
  justify-content: end;
}
.drawerBtn {
  color: var(--white);
  display: flex;
  padding: 6px;
  border: none;
  font-size: 30px;
  background: transparent;
}
section.drawerCloseSec .drawerBtn:hover {
  background-color: var(--greyMedium);
}
section.drawerOpenSec .drawerBtn svg:hover {
  background-color: var(--greyMedium);
  border-radius: 10px;
}

section.drawerCloseSec .MuiDivider-root,
section.drawerOpenSec .MuiDivider-root {
  background: var(--white);
  width: 100%;
  height: 1px;
}
.sideTabLinkCont,
.active-sideTabLinkCont {
  padding: 10px;
}
section.drawerCloseSec .sideTabLinkCont,
section.drawerCloseSec .active-sideTabLinkCont {
  text-align: center;
}
.sideTabLinkCont svg,
.active-sideTabLinkCont svg {
  font-size: 30px;
  width: 30px;
  fill: var(--white);
  height: 30px;
}
.sideTabLinkCont span,
.active-sideTabLinkCont span {
  font-size: 15px;
  margin-left: 10px;
  color: var(--white);
}
.sideTabLinkCont:hover,
.active-sideTabLinkCont {
  background-color: var(--greyMedium);
}

.sideTabLinks a:hover {
  text-decoration: none;
}

///app -section
.appCont {
  width: inherit;
}
.headerSec {
  margin-left: $drawer-close-width;
  transition: 500ms;
}
.headerSmSec {
  margin-left: $drawer-open-width;
  transition: 500ms;
}
.App-header {
  background-color: var(--primary);
  font-family: $rogers-font;
  position: fixed;
  width: 95%;
  height: 43px;
  z-index: 1000;
  transition: 500ms;
}
.App-sm-header {
  background-color: var(--primary);
  font-family: $rogers-font;
  position: fixed;
  width: 85%;
  height: 43px;
  z-index: 1000;
  transition: 500ms;
}
.dummyDiv {
  height: 43px;
}
.bcBar {
  background-color: #dddddd;
}
.disabledHistorySec {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}
.disabledHistorySec:hover {
  color: var(--greyDark);
}
.historySec {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  color: var(--white);
  cursor: pointer;
}
.historySec:hover {
  color: var(--greyLight);
}
.historySec .font {
  font-weight: 600;
}
.disableFont {
  color: var(--greyMedium);
}
.bcBar a,
.bcBar li {
  font-family: $rogers-font;
  font-weight: 500;
  color: var(--link);
  text-decoration: none;
  font-size: 13px;
  padding: 1px 2px;
  cursor: pointer;
}
.bcBar a:hover {
  text-decoration: none;
}

li.MuiBreadcrumbs-separator {
  margin: 0px;
}
nav.MuiBreadcrumbs-root {
  margin-left: 25px;
}
.mBar {
  background-color: var(--white);
  height: 25px;
}
.mBar li {
  height: 21px;
}
.mBar ul {
  list-style-type: none;
  display: inline-flex;
  font-family: $rogers-font;
  padding-left: 5px;
  vertical-align: initial;
  margin-bottom: 0px;
}
.mBar a {
  margin-left: 20px;
  text-decoration: none;
  color: var(--pureBlack);
}
.mBar a:hover {
  text-decoration: none;
  color: var(--primary);
}
.selectedTab {
  background-color: var(--white);
  border-bottom: 2px solid var(--primary);
  font-weight: 500;
}
.selectedTab li {
  color: var(--primary);
}
.main-section {
  background-color: var(--white);
  margin-left: $drawer-close-width;
  transition: 500ms;
}
.main-sm-section {
  background-color: var(--white);
  margin-left: $drawer-open-width;
  transition: 500ms;
}

header.fullDialogCont {
  position: relative;
  height: 55px;
  background-color: var(--primary) !important;
  color: var(--white) !important;
  padding-right: 0px !important;
  font-family: $rogers-font;
}
header.fullDialogCont .fullDialogBar {
  display: flex;
  min-height: 55px;
  align-items: center;
  justify-content: space-between;
}
button:disabled {
  cursor: not-allowed;
  background-color: var(--greyLight);
  color: var(--black50);
}
div.modal {
  z-index: 2500;
}
span.blockSpan {
  display: block;
}
.copyClipboardBtn {
  margin: 0px 0px 5px 5px;
  font-size: 20px;
  color: var(--iconColor);
}

.MuiTooltip-popper {
  z-index: 4000 !important;
}
@media screen and (max-width: 769px) and (min-width: 551px) {
  .sideTabLinkCont svg,
  .active-sideTabLinkCont svg {
    width: 22px;
  }
  .sideTabLinkCont span,
  .active-sideTabLinkCont span {
    font-size: 12px;
    margin-left: 5px;
  }
}
@media screen and (max-width: 550px) and (min-width: 320px) {
  .drawerBtn {
    display: none;
  }
  .drawerCloseSec {
    width: 10%;
    margin-top: 43px;
  }
  .sideTabLinkCont svg,
  .active-sideTabLinkCont svg {
    width: 22px;
  }
  .headerSec {
    margin-left: 0px;
  }
  .App-header {
    width: 100%;
  }
  .main-section {
    margin-left: 10%;
  }
  .dropdown-menu {
    font-size: 12px;
  }
}

.emtaTelemetryTable th {
  font-size: 13px;
}

.emtaTelemetryTable td {
  font-size: 13px;
  vertical-align: top;
  font-weight: 500;
  color: var(--pureBlack);
  padding: 1px 5px !important;
}

.emtaTelemetryTable td .cellNewLine {
  white-space: pre-line;
}

.emtaTelemetryTable thead {
  box-shadow: 1px 1px 4px -2px;
}

.emtaTelemetryTable thead th {
  padding: 5px;
  font-weight: 700;
  border-bottom: 0;
  z-index: 1;
}
.emtaTelemetryTable thead th.specAvailable {
  padding: 5px;
  font-weight: 700;
  border-bottom: 0;
  z-index: 1;
}
.emtaTelemetryTable thead th:first-child {
  position: sticky;
  left: 0;
  /*   min-width: 10vw;
  max-width: 15vw;
  background-color: aqua; */
  // padding-left: 25px;
  z-index: 2;
}

.emtaTelemetryTable .dTabBody td:first-child {
  padding: 1px 5px !important;
}

.emtaTelemetryTable tbody tr:nth-child(even) {
  background-color: var(--black10);
}
.emtaTelemetryTable tbody tr:nth-child(odd) {
  background-color: var(--backgroundLight);
}

.emtaTelemetryTable .dTabBody {
  min-height: 50vh;
}
.emtaTelemetryTable .dTabBody td:first-child {
  position: sticky;
  left: 0;
}
.emtaTelemetryTable .dTabBody tr {
  height: auto;
  white-space: nowrap;
}
.emtaTelemetryTable tbody tr:nth-child(even) td:first-child {
  background-color: #e5e5e5;
}
.emtaTelemetryTable tbody tr:nth-child(odd) td:first-child {
  background-color: var(--background);
}

.highlightedRow {
  background-color: bisque !important;
  td:first-child {
    background-color: bisque !important;
  }
}

#hideNodeDetails {
  display: none;
}

#hideNodeDetails1 {
  visibility: hidden;
}

.cmtsMainPage {
  min-height: calc(100vh - var(--headerHeight));
  background-color: var(--background);
}
.cmtsTableContainer {
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
  // grid-template-rows: auto;
  // gap: 40px;
  // width: 100%;
  // padding: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  // align-items: center;
  // padding: 10px 0px;
}

.cmtsSectionTitle {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  margin: 5px 20px;
  padding: 5px 0px;
  border-bottom: 1px solid var(--black10);
}

.cmtsTableTitle {
  font-size: 16px;
  padding: 5px 0px;
  margin: 5px 0px;
  font-weight: 600;
  border-bottom: 1px solid var(--black10);
}

// under  cmtsTableContainer all divs order is important
.cmtsTableContainer > div {
  flex-basis: 33%;
  padding: 10px 20px;
}

.cmtsTable {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
}

// banded rows for cmts table
.cmtsTable tbody tr:nth-child(odd) {
  background-color: var(--black10);
}
.cmtsTable tbody tr td {
  padding: 0px 8px;
  border: 1px solid var(--white);
  // text-align: start;
  vertical-align: middle;
  min-width: 200px;
}

.cmtsMainPageSec {
  padding: 16px;
}
.cmtsMainPageSec > div > div {
  padding: 0px;
}

.cmtsBanner {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--black10);
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px;
}

.cmtsBannerBtnsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 40px;
}

.cmtsBannerLabelContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
  div:last-child span:last-child {
    font-weight: 600;
    color: var(--primary);
  }
}

.cmtsBannerLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.cmtsBannerLabelText {
  font-size: 14px;
  font-weight: 600;
}

.cmtsBannerBtns {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.cmtsTableContainer {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
}

.cmtsTableTitle {
  font-weight: bold;
  margin-bottom: 10px;
}

.cmtsTable {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.cmtsTable td {
  padding: 4px 8px;
  word-wrap: break-word;
  border: 1px solid #e0e0e0;
  max-width: 50px;
}

.cmtsTabSec {
  min-height: auto !important;
  button.MuiTab-root {
    min-height: auto;
  }
  .MuiTabs-indicator {
    background-color: var(--primary);
  }
  background-color: var(--white);
  border-bottom: 1px solid var(--black10);
}
