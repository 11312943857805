@import "../../../design-tokens/globle/css-variables.scss";

.tabDataCont div.dataCard {
  margin: 10px 1px 1px 1px;
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.tabDataCont div.card-body {
  margin: 5px;
  padding: 1px;
}

.tabDataCont div.card-header,
.tabDataCont div.card-footer {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  overflow-x: auto;
}

.activeMapEleCont .dataTableDetails thead td {
  background-color: #e2e8f0;
  border: 1px solid var(--greyLight);
  font-size: 12px;
  padding-left: 5px;
}
.activeMapEleCont .dataTableDetails th,
.activeMapEleCont .dataTableDetails td {
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: var(--topoTDFN);
}
.activeMapEleCont .dataTableDetails thead td,
.activeMapEleCont .dataTableDetails th{
  font-weight: var(--topoTHFN);
}
.activeMapEleCont td{
  font-weight: var(--topoTDFN);
}

.activeMapEleCont table.dataTableDetails {
  min-width: auto;
}
p.smtListCell {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
}
p.smtListCell span {
  display: flex;
  align-items: center;
  margin: 5px;
  justify-content: space-around;
}
span.locatorVal {
  display: flex;
  align-items: center;
}
div.siteComponentsTable table.dataTableDetails td svg,
span.locatorVal svg {
  margin-left: 5px;
}

///search Filter
.topoSearchCont {
  display: flex;
  flex: 1;
  align-items: center;
  // min-width: 80%;
  justify-content: space-between;
}
.tpSearchInput {
  align-items: center;
  border: 1px solid var(--primary);
  border-radius: 28px;
  width: 80%;
  align-self: center;
}
.tpSearchInput input#searchEleInput {
  border: none;
  border-radius: 25px;
  height: 25px;
  font-size: 13px;
  padding: 0px 10px;
}
.tpSearchInput input#searchEleInput:focus,
.tpSearchInput #searchMapEleBtn:focus {
  box-shadow: none;
}
.tpSearchInput #searchMapEleBtn {
  border: none;
  border-radius: 0px 25px 25px 0px;
  background-color: var(--primary);
  padding: 3px 10px;
}

.mapFilters {
  margin-top: 10px;
  margin-left: 5px;
}
.filterCBCont input {
  margin-right: 5px;
}
.filterCBCont label {
  margin-bottom: 0px;
}

.searchTypeCont {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.searchTypeCont label.selectTxtChipCont,
.searchTypeCont label.selectChipTxtChecked {
  margin-left: 0px;
}
span.addressSearchBtn {
  background: var(--primary);
  border-radius: 5px;
  margin: 0px 20px 0px 0px;
  padding: 4px 7px;
  cursor: pointer;
}
span.addressSearchBtn svg {
  color: var(--white);
}
svg.addressSearchIcon {
  fill: var(--white);
  height: 20px;
  width: 20px;
}

//Advance Search
div.advSearchCont .advSearchTabsBar button.MuiTab-root {
  font-size: 13px;
  font-weight: 600;
  min-width: 100px !important;
  min-height: 25px;
}

div.advSearchCont .advSearchTabsBar .MuiTabs-root {
  min-height: 25px;
}

div.advSearchCont .MuiBox-root {
  padding: 5px 0px;
}

//address Search
div.addressSearchCont div.diagAPICont {
  height: auto;
}

section.addressSearchHead {
  display: flex;
  align-items: center;
  background: var(--primary10);
  padding: 10px;
  font-size: 13px;
}
span.closeBtn {
  font-size: 20px;
  display: flex;
  color: var(--black50);
  margin-right: 10px;
}
span.headerTxt {
  font-size: 14px;
  font-weight: 700;
}
.adSearchInputCont {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
}

.adSearchInputCont section {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 5px;
}

.adSearchInputCont span {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.adSearchInputCont span:nth-child(even) {
  margin-left: 10px;
}
.adSearchInputCont span label {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 500;
}
.adSearchInputCont span input {
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  font-size: 13px;
  padding: 2px 5px;
  min-height: 30px;
  box-shadow: 1px 0px 5px -2px var(--black50);
}
.adSearchInputCont span input:focus-visible,
div.addSearchFilter input:focus-visible {
  box-shadow: none;
  outline: none;
}
.adSearchInputCont span .custDDCont {
  margin-right: 0px;
  width: 50%;
}
.adSearchInputCont span .custDDCont .custDDBtn {
  font-size: 13px;
  min-height: 30px;
  font-weight: 500;
}
.adSearchInputCont span div.custDDOption {
  min-height: 25px;
}
.adSearchInputCont section.addSearchBtnCont {
  display: block;
  font-size: 14px;
  font-weight: 600;
}

section.addSearchBtnCont p {
  margin: 5px 0px;
}
section.addSearchBtnCont button.addSearchBtnCont {
  border: none;
  background: var(--primary);
  color: var(--white);
  padding: 5px 10px;
  width: 100%;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}
button.addSearchBtnCont svg {
  margin-left: 10px;
}

.adSearchInputCont span.errorMsg {
  width: 100%;
  margin-left: 0px;
  margin-bottom: 10px;
}

div.addSearchResultCont {
  display: flex;
  flex-direction: column;
  max-height: 35vh;
  font-size: 11px;
  padding: 0px 10px;
  overflow-y: auto;
}

div.addSearchResultCont p.addressLink {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px;
  border-bottom: 1px solid var(--greyLight);
}
div.addSearchResultCont p.addressLink:hover {
  cursor: pointer;
  background-color: var(--primary10);
}

div.addSearchResultCont svg {
  font-size: 15px;
}

.addSearchFilterCont {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
.addSearchFilterCont p {
  display: flex;
  font-size: 13px;
  font-weight: 500;
  margin: 5px 10px;
  justify-content: space-between;
  align-items: center;
}
.addSearchFilterCont p span:nth-child(even) {
  background: var(--primary);
  color: var(--white);
  padding: 2px 5px;
  border-radius: 5px;
}
div.addSearchFilter {
  display: flex;
  align-items: center;
  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 3px 5px;
  margin: 5px 10px;
}
div.addSearchFilter svg {
  width: 10%;
  color: var(--primary);
}
div.addSearchFilter input {
  border: none;
  font-size: 13px;
  width: 90%;
}

//header
.topoSearchCont .headerTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.topoSearchCont .zoomCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--greyMedium);
  border-radius: 25px;
  padding: 0px 7px;
  margin-right: 10px;
}
.topoSearchCont .zoomCont svg,
.topoSearchCont .zoomCont svg:hover {
  width: 19px;
  height: 19px;
  cursor: default;
}
.topoSearchCont .zoomCont label {
  margin: 0px;
  font-weight: 600;
  margin-right: 3px;
  font-size: 15px;
}

.filterGroupCont {
  margin: 10px 1px 1px 1px;
  border-radius: 5px;
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.filterSecHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--greyLight);
  padding: 5px;
}
.filterSecLabel {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}

//TopoSettings.tsx
.topoSettingCont {
  margin-right: 10px;
}
.topoSettingDropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.topoSettingDropdown:hover {
  color: var(--primary);
}
.topoSettingDropdown svg {
  width: 20px;
  height: 20px;
}
.topoSettingCont #popoverCont {
  padding: 5px 0px;
}
.topoSettingCont #popoverCont .popoverItemsCont {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.topoSettingCont #popoverCont .popoverItemsCont svg {
  margin-left: 5px;
  width: 15px;
  height: 15px;
}
.topoSettingCont #popoverCont .popoverItemsCont:hover {
  background-color: var(--primary10);
}

//GponSpliceMapTable.tsx
.gponSpliceMapTableColorCell {
  color: var(--white);
  padding: 2px;
  font-weight: 600;
}
//End of GponSpliceMapTable.tsx
