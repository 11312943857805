@import "../../../../design-tokens/globle/css-variables.scss";
.mduMapCont {
  width: 100%;
  height: calc(100vh - 43px);
}
.mapLoaderCont {
  margin-top: 30px;
}
.mapLoaderCont img.mapLoading {
  width: 15%;
}
.mapLoaderCont div.MuiSnackbarContent-root {
  background-color: var(--white);
  color: var(--pureBlack);
  font-weight: 600;
  min-width: auto;
  max-width: 15vw;
}
.mapLoaderCont div.MuiSnackbarContent-message {
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mduZoomLevelCont {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--white);
  border-radius: 25px;
  padding: 0px 7px;

}
.mduZoomLevelCont svg{
  color: var(--white);
}
.mduZoomLevelCont label{
  margin: 0px;
  font-weight: 600;
  margin-right: 3px;
  font-size: 15px;
  color: var(--white);
}
