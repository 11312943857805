@import "../globle/css-variables.scss";
.diagnosticsCont,
.WHTCont {
  width: 100%;
  display: inline-flex;
  font-family: $rogers-font;
}

///LeftPanel Css
.leftSection {
  display: flex;
  flex-direction: column;
  width: 20%;
  background-color: var(--white);
  text-align: center;
  min-height: 600px;
  transition: 500ms;
}
main.main-section .leftSection {
  width: 20%;
}
main.main-sm-section .leftSection {
  width: 25%;
}

.toggleleftSection {
  display: flex;
  flex-direction: column;
  width: 90px;
  background-color: var(--white);
  text-align: center;
  min-height: 600px;
  transition: 500ms;
}

.hideshowicon {
  background-color: transparent;
  border-color: transparent;
  position: relative;
  align-self: auto;
  left: 50%;
  top: -25px;
}

.toggleSectionBtn {
  background-color: transparent;
  border-color: transparent;
  position: relative;
  align-self: auto;
  padding: 10px 20px 10px 0px;
}

.diagSearch {
  border: 1px solid var(--greyLight);
  border-radius: 8px;
  padding-left: 5px;
  display: inline-flex;
  align-self: auto;
  margin: 5px;
  width: auto;
  position: relative;
  top: -50px;
}

.diagSearch .MuiInputBase-root {
  width: 100%;
}
.diagSearchIcon {
  align-self: center;
  color: var(--greyLight);
}
div.WHTCont .deviceTabs {
  margin-top: 10px;
}
.deviceTabs {
  margin-top: 20px;
}
.deviceTabs .MuiDivider-root {
  background-color: var(--background);
  height: 2px;
}
.blockSpan {
  display: block;
}
.deviceTabBox {
  display: inline-flexbox;
}

.deviceTabBox:hover {
  background-color: var(--background);
}

.deviceIcons {
  background-color: transparent;
  border: transparent;
  margin-right: 10px;
}

.deviceIcons:hover .showLabel {
  visibility: visible;
  width: 165px;
}

.deviceIcons img {
  width: 35px;
  height: 35px;
}
.tooltip-inner {
  text-align: left;
  max-width: 100%;
  background-color: var(--black75);
}
.showLabel {
  visibility: hidden;
  background-color: var(--white);
  color: var(--black75);
  border-radius: 5px;
  border-color: var(--black75);
  border-style: solid;
  border-width: 1px;
  padding: 5px 0;
  z-index: 1;
}
.subLabels {
  text-align: left;
  font-family: $rogers-font;
  font-weight: 600;
  padding-left: 5px;
  padding-right: 5px;
}
.horizontalDeviceTab {
  text-align: center;
}

.horizontalDeviceTab,
.horizontalDiagTab {
  width: 100%;
  background-color: transparent;
  border: transparent;
  padding: 5px;
}
.horizontalDiagTab .tabCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.horizontalDiagTab .tabLabel {
  width: 75%;
}
.tabLabel {
  display: flex;
  color: var(--black75);
  font-size: 13px;
  font-weight: 700;
}
.tabLabel img,
.tabLabel svg {
  margin-right: 10px;
}
.tabSubLabel {
  display: flex;
  color: var(--black75);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
}
.tabSubSubLabel {
  margin-bottom: 0px;
  font-size: 12px;
}
.custom--Tab,
.custom-selectedDeviceTab-Tab {
  display: flex;
}
div.deviceTabs .custom--Tab:hover,
div.deviceTabs .custom-selectedDeviceTab-Tab:hover,
div.deviceTabs .custom-selectedDeviceTab-Tab {
  background-color: var(--background);
}

.deviceActionCont {
  display: flex;
  justify-content: center;
}
.deviceTabMenu-vertical {
  background-color: transparent;
  border: none;
}

.deviceTabMenu-vertical {
  font-size: 20px;
  color: var(--greyLight);
}
.deviceTabMenu-vertical:active {
  background-color: var(--greyLight);
  color: var(--white);
}

.custom-selectedDeviceTab-Tab {
  border-left: 5px solid var(--primary);
}
.diagActionsListCont ul {
  margin-bottom: 0px;
  list-style-type: none;
  padding-left: 0px;
}
.diagActionsListCont li {
  padding-left: 30px;
}
.diagActionsListCont button {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 5px;
  border-bottom: 2px solid var(--background);
  font-size: 11px;
  font-weight: 600;
  text-align: left;
}
.diagActionsListCont li:first-child button {
  border-top: 2px solid var(--background);
}
.diagActionsListCont li:last-child button {
  border-bottom: none;
}
.device-true-Action,
.device-selected-Pod {
  background-color: #e2e8f0;
}
.device-true-Action button,
.device-selected-Pod button {
  color: var(--link);
}

///right panel Css
.rightSection,
.rightPanel {
  margin-left: 20px;
}
main.main-section .rightSection,
.rightPanel {
  width: 80%;
}
main.main-sm-section .rightSection {
  width: 75%;
}
.rightToggleSection {
  width: 100%;
  margin-left: 20px;
}
.diagTabs {
  width: 100%;
  display: flex;
  background-color: var(--white);
}
.diagTabs .custom--Tab,
.diagTabs .custom-selectedDeviceTab-Tab {
  width: 33%;
}
.diagTabs .tabLabel,
.diagTabs .tabSubLabel {
  display: block;
}
.diagTabs .custom-selectedDeviceTab-Tab {
  border: none;
}
.diagTabs .custom-selectedDeviceTab-Tab .tabCont {
  background-color: var(--background);
  border-radius: 25px;
  padding: 5px;
}
.diagTabs .custom--Tab .tabLabel,
.diagTabs .custom--Tab .tabSubLabel {
  color: var(--black50);
}
.diagTabs .custom-selectedDeviceTab-Tab .tabLabel,
.diagTabs .custom-selectedDeviceTab-Tab .tabSubLabel {
  color: var(--pureBlack);
}
.leftTabCont {
  display: inline-flex;
}

.diagViewCont {
  float: right;
  margin-top: 20px;
  display: flex;
}
.diagViewCont span,
.neighViewCont span {
  background: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  font-size: 25px;
  display: flex;
  padding: 5px;
  color: var(--black75);
  cursor: pointer;
}
.diagViewCont svg {
  margin-top: 0px;
}
.diagViewCont .tableView:first-child,
.diagViewCont .tabView:last-child {
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--white);
}

section.rightPanel .diagResult {
  margin-top: 0px;
  background-color: var(--white);
  min-height: 700px;
}
.diagResult {
  margin-top: 0px;
}

.verticaltabCont {
  display: inline-flex;
  flex-direction: row;
}

.verticalDeviceTab {
  width: 100%;
  background-color: transparent;
  border: transparent;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.verticalDeviceTab svg {
  font-size: 20px;
}
.deviceStatusCont {
  display: flex;
  justify-content: space-between;
}
.deviceOverallStat {
  display: flex;
  align-items: center;
}
.deviceOverallStat span:last-child {
  margin: 0px 5px;
}
p.deviceOverallStat .overallStat {
  font-weight: 700;
}
.showLabel {
  visibility: hidden;
  width: 150px;
  color: rgba(51, 51, 51, 0.8);
  text-align: center;
  border-radius: 6px;
  margin: auto;
  position: absolute;
}
span.tabStatusCont {
  display: flex;
  align-items: center;
}
span.tabStatusCont svg,
span.tabStatusCont img {
  font-size: 15px;
  margin-left: 5px;
}
////device Diagnostics Css
.detailDiagCont {
  min-height: 500px;
  background-color: var(--white);
}
.deviceData,
.detailDiagTabs {
  background-color: var(--white);
  padding: 5px 25px;
}
.diagCoData {
  padding: 10px 5px;
}
.deviceData p,
.diagCoData p {
  margin-bottom: 0px;
}
.deviceData p {
  margin-left: 16px;
}
.deviceData p span {
  font-size: 12px;
}
.deviceData p span:first-child,
.diagCoData p span:first-child {
  font-weight: 600;
}
.deviceData p span:last-child,
.diagCoData p span:last-child {
  font-weight: 500;
}

.deviceOverallStat span.deviceStatusTxtCont {
  display: flex;
  align-items: center;
}

.detailDiagTabs .nav-tabs {
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid var(--background);
}
.detailDiagTabs .nav-link.active {
  color: rgba(51, 51, 51, 0.8);
  font-size: 12px;
  font-weight: 700;
  border-color: var(--background) var(--background) var(--white);
}
.detailDiagTabs .nav-link {
  margin-bottom: -2px;
  color: var(--greyMedium);
  font-size: 12px;
  font-weight: 600;
  border: 2px solid transparent;
}

///cmSummary Css
.cmSummaryCont {
  margin-top: 10px;
}
div.dataCard {
  border-bottom: 1px solid var(--background);
  border-top: none;
  border-left: none;
  border-right: none;
}
div.dataCard .card-body {
  overflow-x: auto;
  padding: 5px;
}
.cmSummaryCont .accordion > .card:not(:last-of-type) {
  border-bottom: 1px solid var(--background);
  border-top: none;
  border-left: none;
  border-right: none;
}
.cmSummaryCont .card-header {
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: var(--white);
  border-bottom: 1px solid var(--background);
}
.cmSummaryCont .card-header span {
  font-size: 12px;
  font-weight: 600;
}
.cmSummaryCont .card-header svg {
  font-size: 20px;
}
.cmSummaryCont div.collapse {
  overflow: auto;
}

table.dataTableDetails {
  width: 100%;
  // min-width: 800px;
  display: table;
}
.dataTableDetails th {
  font-size: 15px;
  font-weight: 600;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid var(--greyLight);
  background-color: #e2e8f0;
}
div.docsisEventCont .dataTableDetails th.smallCol {
  width: 10%;
}
div.docsisEventCont .dataTableDetails th.vSmallCol {
  width: 5%;
}
div.docsisEventCont .dataTableDetails th.mediumCol {
  width: 16%;
}
div.docsisEventCont .dataTableDetails th:last-child {
  width: 45%;
}
.dataTableDetails tbody tr:nth-child(odd) {
  background-color: var(--background);
}
.dataTableDetails td {
  font-size: 12px;
  font-weight: 500;
  display: table-cell;
  padding-left: 10px;
  padding-right: 10px;
  // word-break: break-word;
  border: 1px solid var(--greyLight);
  vertical-align: text-top;
}
.dataTableDetails td.cellNewLine {
  white-space: pre-line;
}

.dataTableDetails td.cellNewLine span {
  display: block;
}

th.fixedCol {
  width: 40%;
}

th#nameCol,
th#valueCol {
  width: 25%;
} /*
th#statusCol,
th#thresholdCol {
  width: 20%;
} */
td.attValueCell {
  word-break: break-all;
}
.dataTableDetails .sectionTitle {
  background-color: var(--background);
  font-weight: 600;
}

///cmDownstreamCh css
div.dsChTabsCard .card-header {
  display: flex;
  padding: 0px 10px;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--white);
  border-bottom: 1px solid var(--background);
}
div.dsChTabsCard .custom--Tab {
  width: 20%;
}
div.dsChTabsCard .tabLabel {
  display: block;
}
div.dsChTabsCard .custom--Tab .tabLabel {
  font-size: 12px;
  font-weight: 500;
}
div.dsChTabsCard .custom-selectedDeviceTab-Tab .tabLabel {
  font-size: 12px;
  font-weight: 600;
}
div.dsChTabsCard .custom-selectedDeviceTab-Tab {
  width: 20%;
  border-bottom: 3px solid rgba(0, 0, 0, 0.5);
  border-left: none;
}
div.dsChTabsCard .card-body {
  padding: 0px;
  overflow-x: auto;
}

div.dsChTabsCard .card-body div.MuiBox-root {
  padding: 2px 10px;
}

////ofdmChannelData css

.dsChDataAcc p {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 600;
}

.ofdmChUnCorrectables,
.ofdmChDetails {
  margin-top: 5px;
}
.ofdmPreChDetails {
  margin-top: 5px;
}

.dsChDataAcc {
  display: flex;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e2e8f0;
  padding: 5px 10px;
  margin-bottom: 5px;
  cursor: pointer;
  min-width: 800px;
}
.failState {
  color: var(--danger) !important;
  display: block;
}
.marginalPassState {
  color: var(--warning) !important;
  display: block;
}
.passState {
  color: var(--success);
  display: block;
}
.skipState {
  color: var(--skip) !important;
  display: block;
}

///wireless Subscriber Css
.wsCenterContainer {
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wsContainer {
  margin: 15px 0px;
  display: flex;
}
.searchCSubInfoCard {
  padding: 20px;
  align-items: center;
  margin-top: 10%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.searchSubInfoCard {
  padding: 20px;
  align-items: center;
}
.wsLable {
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
}
.wsInput {
  border: none;
  border-bottom: 1px solid var(--background);
  margin-right: 20px;
}
.wsInput:focus {
  outline: none !important;
  border-radius: 5px;
}
.wsBtn {
  background: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px;
}
.wsBtn:active {
  transform: translateY(2px);
}
.wsErrorMessage {
  margin-left: 15px;
  margin-bottom: 0px;
  margin-top: 10px;
  color: var(--primary);
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
}
.wsResultMessage {
  margin-left: 15px;
  margin-bottom: 0px;
  color: var(--greyLight);
  font-size: 14px;
  font-weight: 500;
}
.formattedData {
  color: var(--pureBlack);
  font-size: 15px;
  font-weight: 500;
  padding-right: 10px;
  padding-bottom: 5px;
  font-family: $rogers-font;
  margin-top: -25px;
}
.noFormating {
  padding: 0%;
  margin: 0%;
}
.inlineError {
  text-align: left;
  color: var(--primary);
}
.centerFlexBox {
  display: flex;
  align-items: center;
}
.cmtsMismatchMSG {
  display: flex;
  align-items: center;
  color: var(--warning);
  font-weight: 700;
}

@media screen and (max-width: 1240px) and (min-width: 1024px) {
  .leftSection {
    width: 25%;
  }
  .rightSection {
    width: 73%;
  }
  .rightToggleSection {
    width: 90%;
  }
  .deviceTabs {
    margin-top: 90px;
  }
  div.dsChTabsCard .custom--Tab,
  div.dsChTabsCard .custom-selectedDeviceTab-Tab {
    width: 25%;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  div.dsChTabsCard .custom--Tab,
  div.dsChTabsCard .custom-selectedDeviceTab-Tab {
    width: 30%;
  }
  .leftSection {
    width: 16%;
  }
  .rightSection {
    width: 82%;
  }
  .rightToggleSection {
    width: 87%;
  }
  .tabLabel {
    justify-content: center;
  }
  .tabSubLabel {
    display: none;
  }
  .leftTabCont {
    display: block;
  }
  .verticalDeviceTab {
    padding: 10px 0px;
    padding-left: 15px;
  }
  .deviceTabs {
    margin-top: 50px;
  }
  .deviceIcons {
    margin-right: 0px;
  }
  .diagActionsListCont li {
    padding-left: 0px;
  }
}
@media screen and (max-width: 767px) and (min-width: 374px) {
  div.dsChTabsCard .custom--Tab,
  div.dsChTabsCard .custom-selectedDeviceTab-Tab {
    width: 30%;
  }
  .tabLabel {
    justify-content: center;
    display: none;
  }
  .tabSubLabel {
    display: none;
  }
  .leftTabCont,
  .diagTabs,
  .deviceStatusCont {
    display: block;
  }
  .rightSection {
    margin-left: 15px;
    width: 75%;
  }
  .diagTabs .custom--Tab,
  .diagTabs .custom-selectedDeviceTab-Tab {
    width: 100%;
  }
  .verticalDeviceTab {
    padding: 10px 0px;
    padding-left: 15px;
  }
  .deviceTabs {
    margin-top: 180px;
  }
  .deviceIcons {
    margin-right: 0px;
  }
  .diagActionsListCont button {
    font-size: 10px;
  }
  .diagActionsListCont li {
    padding-left: 0px;
  }
  .hideshowicon {
    display: none;
  }
  section.leftSection .deviceActionCont {
    display: none;
  }
  div.MuiBox-root {
    padding: 15px;
  }
}
.diagErrorCont {
  display: flex;
  justify-content: center;
}

.oduDetailsCont {
  min-height: 500px;
  background-color: var(--white);
  padding: 10px 20px;
}
.oduDetailsCont .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: var(--white);
  border-bottom: 1px solid var(--background);
}
