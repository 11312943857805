.workOrderWdt {
  padding: 10px 30px;
  min-height: 200px;
}
.workOrderWdt div.diagErrorCont {
  margin-top: 15px;
}
div.MuiFormGroup-root.MuiFormGroup-row {
  display: inline-flex;
  margin-left: 10px;
}

.workOrderSearch {
  border: 1px solid var(--primary);
  border-radius: 20px;
  padding-left: 10px;
  width: 40%;
  display: block;
  float: right;
  margin: 15px;
}

.searchWOBtn {
  background-color: transparent;
  border: none;
  color: var(--primary);
}
.searchInput-WO {
  border: none;
  border-radius: 0px 20px 20px 0px;
}
.searchInput-WO:focus {
  outline: none;
  box-shadow: none;
}
@media screen and (max-width: 544px) and (min-width: 320px) {
  .workOrderSearch {
    float: none;
    width: auto;
    margin: 10px;
  }
  .workOrderWdt {
    padding: 5px 15px;
  }
  .workOrderList {
    margin-top: 10px;
  }
}
