@import "../../../design-tokens/globle/css-variables.scss";

.annotationBlock td {
  font-size: 10px;
  font-weight: 500;
  display: table-cell;
  padding: 2px;
  color: var(--greyMedium);
  //word-break: break-all;
  border: 1px solid var(--greyLight);
  vertical-align: text-top;
  min-width: 40px;
}

.eolBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  padding: 2px;
}

.tombstone {
  color: var(--greyMedium);

  border-radius: 20px;
  border: 1px solid var(--greyLight);
  padding: 3px;
}
.tombstone div {
  color: var(--greyMedium);
  text-align: center;
  font-size: 9px;
}
.tombstoneSpan {
  color: var(--greyMedium);
  text-align: center;
  font-size: 9px;
  margin: 7px;
}
