@import "../globle/css-variables.scss";

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.checkboxContainer label {
  flex: 1;
  margin-right: 30px;
  font-size: 15px;
}
.titleLabel {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 16px;
}
.txtFieldLabel {
  font-weight: 600;
  font-size: 16px;
}

.datePickerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.datePickerContainer .itemContainer {
  width: 170px;
}
.datePickerContainer #dropdownBtn {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 15px;
}

.viewSRContainer .titleLabel {
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.viewSRContainer .textLabel {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;
}

.dropdownContainer #dropdownBtn {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyLight);
  border-radius: 5px;
  justify-content: space-between;
  display: flex;
  width: 30%;
  align-items: center;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 6px 12px;
}

.viewSRMainContainer {
  align-self: center;
  margin: 25px;
  min-width: 450px;
}
.viewSRMainContainer .seniorFormBtnContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 25px;
}
.viewSRMainContainer .seniorFormBtn,
.viewSRMainContainer .seniorFormBtn:hover {
  background-color: var(--link);
  color: var(--white);
  border: 1px solid;
  padding: 5px 15px;
  font-size: 15px;
}
.viewSRMainContainer .carouselContainer {
  width: 700px;
}

.viewSRMainContainer .carouselContainer a.carousel-control-next:hover,
.viewSRMainContainer .carouselContainer a.carousel-control-prev:hover {
  background-color: var(--primary10);
}

.viewSRMainContainer .carouselContainer a.carousel-control-next polyline,
.viewSRMainContainer .carouselContainer a.carousel-control-prev polyline {
  stroke: var(--primary) !important;
  stroke-width: 5;
}

.exceptionBoxCont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
