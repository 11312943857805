.portDataRow:nth-child(odd) {
  background-color: var(--background);
}

.portDataRow td {
  font-size: 12px;
  font-weight: 500;
  display: table-cell;
  padding: 3px;
  //word-break: break-all;
  border: 1px solid var(--greyLight);
  vertical-align: text-top;
  min-width: 60px;
  max-width: 80px;
}

.dataTableDetails th {
  font-size: 12px;
  font-weight: 600;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid var(--greyLight);
  background-color: #e2e8f0;
}

.dataTableDetails #valueCol {
  width: 70%; 
}