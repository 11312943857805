@import "../../../design-tokens/globle/css-variables.scss";

.numMarker {
  font-weight: 500;
  padding: 1px 2px;
  background-color: var(--black50);
  border-radius: 2px;
}
.poleStrandLabel {
  font-weight: 700;
  padding: 4px;
}
.powerSupplyLabel{
  font-weight: 400;
  padding: 4px;
}
