.node-tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  border: 1px solid #dee2e6;
  border-radius: 30px;
  font-size: 14px;
  background-color: var(--background);
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.node-tabs .navbar-nav.nav-tabs {
  border-radius: 20px;
  border: 1px solid #dee2e6;
  color: var(--color-secondary);
}

div.tabDataCont .nav-tabs .nav-item.show .nav-link,
div.tabDataCont .nav-tabs .nav-link.active {
  color: red;
  font-weight: 600;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

div.tabDataCont .nav-tabs .nav-link {
  width: 50%;
  text-align: center;
  border: 1px;
  border-radius: 20px;
  color: var(--color-primary);
}

.commentsTopHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}
.totalComments {
  flex-basis: 50%;
  font-weight: 600;
  font-size: 14px;
}
.commentFeatures {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-basis: 50%;
}
.addRefreshFeature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.refreshComments {
  background-color: transparent;
  color: var(--primary);
  border: 1px solid #da291c;
  border-radius: 15px;
}

.refreshComments .svg {
  font-size: 15px;
}

.addComment {
  margin-right: 3px;
  margin-left: 10px;

  background-color: transparent;
  color: var(--primary);
  border: 1px solid #da291c;
  border-radius: 15px;
}

.nodeCommentCardDetail {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  padding: 3px 0px;
}

.commentsHeader {
  display: flex;
  font-size: 12px;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
}

.commentsDescription {
  position: relative;
  margin-bottom: 5px;
}

.commentsDescription span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.textView {
  position: absolute;
  right: 0px;
  margin-top: 6px;
  cursor: pointer;
}

.drawerButton {
  color: #000000;
  border: none;
  background: transparent;
}

.commentsFeatures {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.editButton,
.deleteButton {
  background-color: transparent;
  color: var(--primary);
  border: 1px solid #da291c;
  border-radius: 15px;
  margin-right: 3px;
  padding: 4px 6px;
}
.editDeleteButton {
  width: 50%;
}

.editButton svg,
.deleteButton svg {
  font-size: 15px;
}

.popUpSubmitCancelButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popUpSubmitButton {
  border: 1px solid transparent;
  background-color: #da291c;
  color: var(--background);
  border-radius: 6px;
  flex-basis: 10%;
}

.popUpCancelButton {
  background-color: transparent;
  color: var(--primary);
  border: 1px solid #da291c;
  border-radius: 6px;
  flex-basis: 10%;
}

.addCommentErrorMsg {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.memoHeader {
  display: flex;
  font-size: 13px;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
}

.memoDescription {
  font-size: 13px;
  position: relative;
  margin-bottom: 5px;
}
