.formContainer {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.DropdownContainer {
  color: var(--pureBlack);
  // margin-bottom: 20px;
}
.DropdownContainer #dropdownBtn {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyLight);
  margin-bottom: 20px;
}

.inlineUnitContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
  align-items: center;
  align-content: center;
}
.unitRemoveBtn {
  font-size: 16px;
  background: none;
  border: none;
  padding-top: 0px;
  color: var(--black);
}
.unitInput {
  font-family: "Ted Next";
  font-size: 13px;
  border-radius: 5px;
  width: 150px;
}

.unitBtn {
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid;
  padding: 5px 15px;
  font-size: 15px;
}

.addUnitBtn {
  font-size: 13px;
  font-weight: 600;
  border: none;
  color: var(--link);
  padding-left: 0;
  align-self: flex-start;
  background-color: var(--white);
}

.emailBtn {
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid;
  padding: 5px 15px;
  font-size: 15px;
}

.viewNoiseContainer {
  align-self: center;
  margin: 25px;
  min-width: 450px;
}

.viewNoiseContainer .titleLabel {
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.viewNoiseContainer .textLabel {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;
}

.viewNoiseContainer .carouselContainer {
  width: 700px;
}

.viewNoiseContainer .carouselContainer a.carousel-control-next:hover,
.viewNoiseContainer .carouselContainer a.carousel-control-prev:hover {
  background-color: var(--primary10);
}

.viewNoiseContainer .carouselContainer a.carousel-control-next polyline,
.viewNoiseContainer .carouselContainer a.carousel-control-prev polyline {
  stroke: var(--primary) !important;
  stroke-width: 5;
}
