@import "../globle/css-variables.scss";
.faCmGW {
  font-size: 14px;
  font-weight: 600;
}
.faCmLink {
  font-size: 12px;
  font-weight: 600;
  //margin-left: 16px;
  padding-top: 4px;
}
.tooltipText {
  color: var(--link);
  cursor: pointer;
  font-weight: 600 !important;
}
.failedAttrTooltip {
  font-size: 14px;
}
.tableTypeThree {
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}
.tableTypeThree th {
  font-weight: 100;
  border-bottom: 1px solid black;
}
.tableTypeTwo {
  width: 200px;
}

.tooltipText:hover {
  box-shadow: var(--greyLight) 3px;
  color: blue;
}

.nbaPopUp {
  padding: 20;
  display: flex;
}
//next best action
.subAttribute {
  font-family: "TedNext-Bold";
  font-size: 14;
  margin-top: 20;
  display: block;
}
.action {
  margin-top: 5;
  display: block;
}
.state {
  font-family: "TedNext-Bold";
}
.tableValue {
  font-size: 12;
  font-family: "TedNext-SemiBold";
}
.stateView {
  margin-top: 5;
  flex: 1;
  display: "flex";
  flex-direction: "row";
}
.preCheckTitle {
  margin-top: 10;
  font-family: "TedNext-Bold";
  display: block;
}
