@import "../../../../design-tokens/globle/css-variables.scss";

.powertraceCont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: flex-start;
  justify-content: flex-end;
}
.powertraceCont section {
  margin-right: 5px;
  color: var(--primary);
  box-shadow: 0px 1px 10px -1px rgba(218, 41, 28, 0.2),
    0px 1px 1px 0px rgba(218, 41, 28, 0.2),
    0px 1px 3px 0px rgba(218, 41, 28, 0.2);
  border-radius: 5px;
  border: none;
  padding: 5px;
  flex-direction: row;
  align-items: center;
  display: flex;
}

span.inActivePT {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 5px;
  background: #e2e8f0;
  color: var(--greyMedium);
  cursor: pointer;
}
span.activePT {
  display: inline-block;
  padding: 3px;
  border-radius: 5px;
  background: #e2e8f0;
  color: var(--primary);
  cursor: pointer;
}
