@import "../globle/css-variables.scss";
.nodeinfoclam {
  margin-bottom: 18px;
}
.entityNum {
  display: flex;
  align-items: center;
}
.entityNum img {
  margin-right: 5px;
}

.custNodeInfoCard {
  padding: 30px;
  font-family: $rogers-font;
  margin-bottom: 1px;
}

.nodeBasicInfo {
  display: inline-flex;
  width: 100%;
}
.nodeBasicInfo div {
  width: 25%;
  text-align: center;
}
.nodeBasicInfo header {
  margin-left: 20px;
  margin-bottom: 6px;
}
.nodeBasicInfo header {
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
}
.nodeBasicInfo span {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
.nodeBasicInfo svg {
  width: 18px;
  margin-right: 20px;
}
.nodeBasicInfo p {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 5px;
}

.nodeDetailsDiv span {
  display: block;
  text-align: left;
  float: left;
}

.nodeDetailsDiv p {
  display: inline-flex;
}

.nodeOverviewAccordion {
  width: 100%;
  display: inline-flex;
}

.nodeOverviewAccordion svg {
  transform: scale(1);
}
.nodeOverviewInfo .card-header span {
  font-weight: 600;
  margin-left: 44%;
  margin-right: -4%;
  text-align: center;
}
.nodeCertificationHistory .card-header span {
  font-weight: 600;
  margin-left: 29%;
  margin-right: -20%;
  text-align: center;
}
.nodeOverviewAccordion .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nodeOverviewAccordion .card-body {
  padding: 0;
}
.nodeOverviewInfo {
  width: 100%;
  margin-right: 10px;
}

.nodeOverviewInfoMT {
  width: 65%;
  margin-right: 10px;
}
.nodeCertificationHistory {
  width: 35%;
}
.nodeCertificationHistory .refreshCertiBtn {
  padding: 2px 6px;
  margin: 4px;
  background-color: var(--link) !important;
  color: var(--white) !important;
  float: right;
}
.custNodeInfoCard {
  padding: 25px;
  font-family: $rogers-font;
  margin-top: 10px;
  margin-left: 15px;
  min-height: 260px;
}
.custNodeInfoCard header {
  margin: 2px 3px 5px;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.nodeCertCard {
  padding: 25px;
  font-family: $rogers-font;
  margin-top: 10px;
  margin-left: 15px;
  min-height: 260px;
}
.nodeCertCard header {
  margin: 2px 3px 5px;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  text-align: center;
}
.nodeCertRefreshBtn {
  position: absolute;
  right: 0;
}
.nodeCertCard .refreshBtn {
  margin-top: 0px;
  background-color: var(--background);
  border: none;
  border-radius: 4px;
  color: var(--black75);
  box-shadow: 0px 0px 2px var(--black75);
}
.nodeCertMainHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nodeCertHeader {
  // display: flex;
  // justify-content: center;
  // position: relative;
}
.nodeCertRefresh {
  text-align: end;
}
.nodeCertdiagError {
  display: flex;
  justify-content: center;
}

table.nodeCertTable {
  // width: 100%;
  // display: table;
  // border-collapse: separate;
  // box-sizing: border-box;
  // text-indent: initial;
  // border: 1px solid #e0e0e0;
  // margin-top: 6px;
  // text-align: center;
  width: 100%;
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  margin: 10px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(224, 224, 224);
  border-image: initial;
}
table.nodeCertTable th {
  font-size: 14px;
  font-weight: 600;
  width: "50%";
  padding-left: "4px";
}
table.nodeCertTable td {
  font-size: 14px;
  font-weight: 400;
  // word-break: break-word;
  // vertical-align: top;
}

table.nodeCertTable td.emailId {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
}
th.nodeCertBorder,
td.nodeCertBorder {
  border-right: 1px solid rgb(224, 224, 224);
}
td.nodeCertTdSC {
  border-top: 1px solid rgb(224, 224, 224);
  line-height: 1.43;
  padding-left: 4px;
  //border-right: 1px solid #e0e0e0;
}
td.nodeCertTdSC.nodeCertTdFC {
  cursor: pointer;
  color: #006acc;
  padding-left: 4px;
}

.nodeCertTdSC > span {
  display: inline;
}

.nodeScoreChipsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

// td span.box {
//   margin: 1px;
//   //float: left;
//   height: 20px;
//   width: 40px;
//   // margin-bottom: 15px;
//   border-radius: 15px;
//   //clear: both;
//   align-items: center;
//   margin-right: 5px;
//   padding: 2px 10px;
//   color: var(--white);
//   font-size: 11px;
//   font-weight: 700;

//   // max-width: 46px;
//   // color: var(--white);
//   // font-size: 11px;
//   // font-weight: 700 !important;
//   // display: flex;
//   // align-items: center;
//   // border-radius: 15px;
//   // margin-right: 5px;
//   // padding: 2px 10px;
// }
// .red {
//   background-color: var(--danger);
//   border: 1px solid var(--danger);
// }
// .green {
//   background-color: var(--success);
//   border: 1px solid var(--success);
// }
// .yellow {
//   background-color: var(--warning);
//   border: 1px solid var(--warning);
// }
section.nodeCertiDetailsSec {
  display: flex;
  flex-direction: column;
  width: 35%;
}
section.nodeCertiDetailsSec > p {
  padding-bottom: 8px;
}

section.nodeScoreDetailsSec {
  width: 35%;
}

.nodeFullCertiCont {
  background-color: var(--background);
}
.nodeFullCertiCont div.viewFullCertiInfoCont {
  margin-bottom: 0px;
}
.nodeFullCertiCont section.deviceStatusCount p {
  padding: 10px 5px;
  width: 80px;
}
.nodeFullCertiCont section.deviceStatusCount p span:first-child {
  font-size: 25px;
}
.nodeFullCertiCont header.accTabsBar {
  margin-top: 0px;
  box-shadow: 0px 3px 1px -1px #00000040;
}
.nodeFullCertiInfoCont {
  font-size: 18px;
  font-weight: 400;
  padding: 20px;
  font-family: $rogers-font;
  display: flex;
}
.nodeFullCertiInfoCont h4 {
  font-size: 28px;
  font-weight: 700;
}
.nodeFullCertiInfoCont p {
  padding-bottom: 8px;
  margin-bottom: 0px;
}
.nodeFullCertiInfoCont span:nth-child(odd) {
  font-weight: 600;
}

//Media Queries
@media screen and (max-width: 689px) and (min-width: 320px) {
  .nodeOverviewAccordion {
    display: block;
  }
  .nodeBasicInfo {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .nodeBasicInfo p {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .nodeBasicInfo div {
    width: auto;
  }
  .nodeOverviewInfo {
    width: 100%;
  }
  .nodeCertificationHistory {
    width: 100%;
  }
}

@media screen and (max-width: 1189px) and (min-width: 690px) {
  .nodeOverviewAccordion {
    display: block;
  }
  .nodeOverviewInfo {
    width: 100%;
  }
  .nodeCertificationHistory {
    width: 100%;
  }
}
