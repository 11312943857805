.widgetSection {
  position: absolute;
  margin-top: 310px;
  padding: 10px 40px;
  transition: 500ms;
}
.widgetSectionMT {
  padding: 10px 40px;
  transition: 500ms;
}
main.main-section .widgetSection,
main.main-section .widgetSectionMT {
  width: calc(100vw - 7%);
}
main.main-sm-section .widgetSection,
main.main-sm-section .widgetSectionMT {
  width: calc(100vw - 17%);
}
.widgetSection a.nav-link,
.widgetSectionMT a.nav-link {
  width: 50%;
  text-align: center;
}

.widgetSection a.nav-item.nav-link.active,
.widgetSectionMT a.nav-item.nav-link.active {
  background-color: var(--primary);
  color: #fff;
  font-weight: 600;
  text-decoration: underline;
}
.widgetSection a.nav-link.nav-item,
.widgetSectionMT a.nav-link.nav-item {
  color: var(--primary);
  background-color: #fff;
}

.widgetSection nav.nav.nav-tabs,
.widgetSectionMT nav.nav.nav-tabs {
  border: 1px solid #999999;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.widgetSection div.tab-content,
.widgetSectionMT div.tab-content {
  border: 1px solid #999999;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
}
.widgetLabel {
  font-weight: 600;
}
.widgetRefresh {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.widgetRefresh button.refreshBtn {
  margin-top: 0px;
}
.widgetSection div.diagAPICont,
.widgetSectionMT div.diagAPICont {
  margin-top: 70px;
}
@media screen and (max-width: 544px) and (min-width: 320px) {
  .widgetSection a.nav-link,
  .widgetSectionMT a.nav-link {
    padding: 0.5rem;
  }
  .widgetSection {
    width: auto;
    padding: 5px 20px;
  }
}
@media screen and (max-width: 1439px) {
  .widgetSection {
    // margin-top: 190px;
    margin-top: 220px;
    //margin-top: 320px; //changes added by node team to increase widget margin
  }
}
