@import "../globle/css-variables.scss";

.towerViewsearchContainer {
  display: flex;
  align-items: center;
}
.searchTN {
  border: 1px solid var(--black75);
  width: auto;
  border-radius: 5px;
}
.towerViewSearchInput {
  height: 30px;
}

// Css below is conflicting with ODU cells table in Diagnostics tab and margining it to the left 20px.
// .failState {
//   margin-left: 20px;
// }
