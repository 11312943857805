@import "../globle/css-variables.scss";.hsiCertiCont,
.fullCertiCont {
  background-color: var(--background);
}
.hsiCertiCont {
  padding: 30px;
}
.backBtn {
  background: transparent;
  border: none;
  padding: 8px 0px;
  font-size: 13px;
  font-weight: 600;
}

.certiInfoCont {
  font-size: 18px;
  font-weight: 400;
  background-color: var(--white);
  padding: 20px;
  margin-bottom: 30px;
  font-family: $rogers-font;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.viewCertiInfoCont,
.viewFullCertiInfoCont,
.techInfo {
  font-size: 18px;
  font-weight: 400;
  padding: 20px;
  margin-bottom: 30px;
  font-family: $rogers-font;
  display: flex;
  align-items: center;
}
.techInfo,
.viewCertiInfoCont {
  background-color: var(--white);
}
.techInfo section,
.viewCertiInfoCont section {
  width: 50%;
}
div.hsiCertiDetails .techInfo section {
  height: 250px;
}
.fullCertiCont div.viewFullCertiInfoCont {
  margin-bottom: 0px;
}
div.viewCertiInfoCont section.deviceStatusCount div {
  display: flex;
  justify-content: center;
}
.certiInfoCont h4,
.viewCertiInfoCont h4,
.viewFullCertiInfoCont h4 {
  font-size: 28px;
  font-weight: 700;
}
.certiInfoCont p,
.viewCertiInfoCont p,
.viewFullCertiInfoCont p {
  margin-bottom: 0px;
}

.certiInfoCont span:nth-child(odd),
.viewCertiInfoCont span:nth-child(odd),
.viewFullCertiInfoCont span:nth-child(odd) {
  font-weight: 600;
}
.saveCertiBtn {
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 1px;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-right: 20px;
}
.restartCertiBtn {
  background-color: var(--link) !important;
  color: var(--white) !important;
  border: 1px solid var(--link) !important;
  border-radius: 1px;
  text-transform: uppercase;
  padding: 2px 20px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  //margin-left: 10px;
}
.restartCertiBtn:disabled {
  background-color: var(--greyLight) !important;
  border: 1px solid var(--greyLight) !important;
  color: var(--black50) !important;
}
.certiDeviceLabel {
  font-size: 16px;
  font-weight: 700;
  padding: 25px 15px;
  margin-bottom: 0px;
}
section.deviceStatusCount h5 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
section.deviceStatusCount div {
  display: flex;
}
section.deviceStatusCount p {
  padding: 10px;
  border-radius: 10px;
  margin-right: 10px;
  text-align: center;
  width: 110px;
}
.fullCertiCont section.deviceStatusCount p {
  padding: 10px 5px;
  width: 80px;
}
section.deviceStatusCount p.passCount {
  border: 1px solid var(--green);
  color: var(--green);
}
section.deviceStatusCount p.failCount {
  border: 1px solid var(--danger);

  color: var(--danger);
}
section.deviceStatusCount p.skipCount {
  border: 1px solid var(--warning);
  color: var(--warning);
}
section.deviceStatusCount p.normCount {
  border: 1px solid var(--black75);
  color: var(--black75);
}
section.deviceStatusCount p.disabledCount {
  border: 1px solid var(--greyLight);
  color: var(--greyLight);
}
section.deviceStatusCount p span:first-child {
  display: block;
  font-size: 25px;
  font-weight: 600;
}
.fullCertiCont section.deviceStatusCount p span:first-child {
  font-size: 25px;
}
section.deviceStatusCount p span:last-child {
  font-size: 15px;
  font-weight: 600;
}
img.certiLoaderIMG {
  width: 50%;
  margin-top: 25%;
  align-self: center;
}
section.techRemarkInfo p {
  display: flex;
}
div.wifiCertiDetails section.techRemarkInfo span:first-child {
  width: 60%;
}
div.wifiCertiDetails section.techRemarkInfo span {
  width: 40%;
}
div.hsiCertiDetails section.techRemarkInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
div.hsiCertiDetails section.techNote {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
section.techRemarkInfo h3,
section.techNote h5 {
  font-size: 18px;
  font-weight: 700;
  color: var(--black75);
}
section.techRemarkInfo h5 {
  font-size: 16px;
  font-weight: 400;
  color: var(--black75);
}
section.techNote p {
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--black50);
  margin-right: 25px;
  padding: 10px;
  border-radius: 5px;
}
.verticalLocationTab {
  width: 100%;
  background-color: transparent;
  border: transparent;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.verticalLocationTab svg {
  font-size: 20px;
}
.locationInfoCont {
  padding: 20px;
}
.locationInfoCont p {
  margin-bottom: 5px;
  font-size: 18px;
}
.locationInfoCont span:first-child {
  font-weight: 600;
}
.locationInfoCont span:last-child {
  font-weight: 400;
}
section.locationDetailsCont {
  margin: 20px;
}
section.locationDetailsCont div {
  display: flex;
  justify-content: center;
}
section.locationDetailsCont p {
  padding: 10px;
  border: 1px solid var(--black50);
  border-radius: 10px;
  margin: 10px;
  text-align: center;
  width: 20%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
section.locationDetailsCont span {
  display: block;
  margin-bottom: 10px;
  color: var(--black75);
}
section.locationDetailsCont span img {
  height: 45px;
}
section.locationDetailsCont span:first-child {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 600;
  margin-top: 20px;
}
section.locationDetailsCont span:last-child {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 700;
}
.confirmBtn {
  background: transparent;
  color: var(--primary);
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid var(--primary);
}
.fullCertiCont header.accTabsBar {
  margin-top: 0px;
  box-shadow: 0px 3px 1px -1px #00000040;
}
.errorCont {
  display: flex;
  justify-content: center;
}
