@import "../../../../design-tokens/globle/css-variables.scss";

section.eleSearchFilters {
  display: flex;
  flex-wrap: wrap;
}

section.eleSearchFilters div.selectChipCont,
section.eleSearchFilters div.selectChipContChecked {
  justify-content: center;
  min-width: 100px;
  margin-bottom: 5px;
  margin-right: 5px;
}

label.selectChipTxtChecked,
label.selectTxtChipCont {
  font-size: 12px;
  margin-left: 0px;
}

div.renSearchCont {
  font-size: 15px;
  margin: 10px 0px;
}
div.renSearchCont section {
  border: 1px solid var(--primary);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.renSearchCont input {
  border: none;
  margin-left: 5px;
  border-radius: 10px;
  width: 100%;
}
div.renSearchCont input:focus {
  outline: none;
}
div.renSearchCont button {
  border: none;
  background-color: var(--primary);
  color: var(--white);
  padding: 3px 10px;
  border-radius: 0px 10px 10px 0px;
}

div.coordSearchCont {
  font-size: 14px;
  margin: 10px 0px;
}
div.coordSearchCont section {
  border: 1px solid var(--primary);
  border-radius: 10px;
  display: flex;
  align-items: center;
}
div.coordSearchCont input {
  border: none;
  margin-left: 5px;
  border-radius: 10px;
  width: 100%;
}
div.coordSearchCont input:focus {
  outline: none;
}
div.coordSearchCont button {
  border: none;
  background-color: var(--primary);
  color: var(--white);
  padding: 4px 10px;
  border-radius: 0px 10px 10px 0px;
}

div.searchResult {
  margin-top: 10px;
  font-size: 12px;
}
div.searchResult .channelDataTable {
  margin: 10px 0px;
  width: 100%;
}

div.searchResult .diagAPICont {
  height: 30vh !important;
}

div.searchResult .channelDataTable .rensNID {
  color: var(--link);
  cursor: pointer;
}

div.elementSearchDdCont {
  min-height: 200px;
}
div.elementSearchDropDown {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  padding: 5px;
}
div.elementSearchDropDown span {
  margin-right: 5px;
}
div.elementSearchDropDown div.custDDOption {
  font-size: 14px;
}
div.elementSearchDropDown div.optionsList {
  max-height: 130px;
}
div.elementSearchDdCont div.custDDCont {
  width: fit-content;
}
