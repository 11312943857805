@import "../globle/css-variables.scss";
///OverViewTab
.overviewContainer {
  width: 100%;
  display: inline-flex;
}
.accountInfoCont {
  width: 70%;
}
.techSupportCont {
  width: 30%;
}

////CustOverView
.custInfoCard {
  padding: 15px;
  font-family: $rogers-font;
  margin-top: 10px;
}
.custNodeInfoCard {
  //padding: 15px;
  font-family: $rogers-font;
  //margin-bottom: 10px;
}
.custBasicInfo {
  display: inline-flex;
  width: 100%;
}
.custBasicInfo div {
  width: 50%;
}
.custBasicInfo header {
  margin-left: 20px;
  margin-bottom: 5px;
}
.custBasicInfo header {
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
}
.custBasicInfo span {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
.custBasicInfo svg {
  width: 18px;
  margin-right: 20px;
}
.custBasicInfo p {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 5px;
}
.custTeleNum span:nth-child(even) {
  margin-right: 30px;
}
.custServiceArea span,
.custServiceAddressCurr span,
.custServiceAddressOth span {
  display: block;
}
.custServiceArea p,
.custServiceAddressCurr p {
  display: inline-flex;
}

////Device OverView
.deviceTablePaper {
  margin-top: 20px;
  //box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.deviceTable {
  font-family: $rogers-font;
  font-size: 14px;
  min-width: 800px;
}
.deviceTabLabel {
  padding: 10px 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1px;
}
.dTabHead,
.pTabHead,
.nTabHead {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
thead.dTabHead th,
thead.pTabHead th,
thead.nTabHead th {
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  color: var(--black75);
}
thead.nTabHead th {
  padding-bottom: 5px;
  color: var(--black75);
}

thead.dTabHead th:first-child,
thead.pTabHead th:first-child,
thead.dTabHead td:first-child,
thead.pTabHead td:first-child,
thead.nTabHead th:first-child,
thead.nTabHead td:first-child {
  padding-left: 25px;
}
thead.dTabHead th:last-child,
thead.dTabHead td:last-child {
  width: 8%;
  padding-right: 25px;
}
.dTabHead td,
.pTabHead td {
  padding: 5px;
  text-align: left;
}

.nodeTabHead {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  overflow: hidden;
  white-space: nowrap;
}
thead.nodeTabHead th {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--black75);
}
thead.nodeTabHead th {
  padding-bottom: 5px;
  color: var(--black75);
}
thead.nodeTabHead th:first-child,
thead.nodeTabHead td:first-child {
  padding-left: 15px;
}

.nodeTabBody td:first-child {
  padding-left: 15px;
}
.nodeTabBody td {
  padding: 10px;
  padding-left: 10px;
  overflow: hidden;
  white-space: nowrap;
}

.sInput {
  border: 0px;
  width: 80%;
}
.sInput:focus {
  outline: none;
}
#deviceModelDd {
  background: transparent;
  color: var(--pureBlack);
  border: transparent;
  font-weight: 500;
}
.dTabBody tr {
  height: 45px;
}
.dTabBody td:first-child,
.nTabBody td:first-child {
  padding: 5px;
  padding-left: 25px;
}
.dTabBody td:last-child {
  padding: 5px;
  padding-right: 25px;
}
.dTabBody td,
.nTabBody td {
  padding: 5px;
}
.deviceMAC {
  border: 1px solid var(--greyLight);
  border-radius: 20px;
  padding: 2px 10px;
}
.deviceTabMenu-horizontal,
.deviceSortBtn {
  background-color: transparent;
  border: none;
}

.deviceTabMenu-horizontal {
  font-size: 20px;
  color: var(--greyLight);
  border-radius: 50%;
}
.deviceTabMenu-horizontal:active {
  background-color: var(--greyLight);
  color: var(--white);
}

div.deviceActionMenu ul {
  padding: 5px;
}
div.deviceActionMenu li {
  font-size: 14px;
  font-weight: 500;
  padding: 3px 5px;
  padding-bottom: 0px;
}
div.deviceActionMenu li.runTest {
  color: var(--success);
}
div.deviceActionMenu li.runAction {
  color: var(--danger);
}
.disabledMenuItem {
  pointer-events: none;
  opacity: 0.5;
}
.deviceTabFooter {
  height: 30px;
}
.noDevices {
  text-align: center;
  font-size: 18px;
  padding: 20px;
}

////OverViewTS

////RECO ENGINE

.troubleShootCardOne {
  padding: 25px;
  font-family: $rogers-font;
  margin-top: 10px;
  margin-left: 15px;
  min-height: 260px;
}
.troubleShootCardOne header {
  margin: 2px 3px 5px;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  text-align: center;
}
.refreshInfo {
  font-size: medium;
  float: right;
  font-style: normal;
  cursor: pointer;
  color: var(--link);
}

span.noteText {
  font-size: 14px;
  margin: "15px 0px 20px 0px";
  color: #000000de;
}

table.troubleshootDetails {
  width: 100%;
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border: 1px solid #e0e0e0;
  margin-top: 6px;
  text-align: center;
}
table.troubleshootDetails th {
  font-size: 14px;
  font-weight: 600;
  width: "50%";
  padding-left: "4px";
}

th.recoBorder {
  border-right: 1px solid rgb(224, 224, 224);
}
.recoTestTdFC {
  border-top: 1px solid rgb(224, 224, 224);
  line-height: 1.43;
  cursor: pointer;
  color: #006acc;
  padding-left: 4px;
  border-right: 1px solid rgb(224, 224, 224);
}
.recoTestTdSC {
  border-top: 1px solid rgb(224, 224, 224);
  line-height: 1.43;
  padding-left: 4px;
}
.legendToolTip {
  font-size: 14px;
}

table.troubleshootDetails td {
  font-size: 14px;
  font-weight: 400;
  word-break: break-all;
  // vertical-align: top;
}
// technician profile

.userImg {
  width: 32px;
}

.profileConatiner {
  padding: 10px;
  display: flex;
  justify-items: center;
  min-width: 300px;
  color: var(--pureBlack);
  font-family: $rogers-font;
  background-color: #fae7e78f;
}
.profileConatiner img,
.profileConatiner div.MuiAvatar-root {
  width: 50px;
  height: 50px;
}
.technNameId li:first-child {
  font-weight: 700;
  font-size: 14px;
  color: var(--pureBlack);
}
li.techBasicDetails {
  display: flex;
  align-items: center;
}
span.infoSeprator {
  width: 10%;
  text-align: center;
}
li.techBasicDetails span.popoverInfoSpanVal {
  width: 40%;
  text-align: left;
}
.clearCookiesBtn {
  display: flex;
  color: var(--primary);
  border-radius: 5px;
  cursor: pointer;
  padding: 2px 5px;
  align-items: center;
  justify-content: center;
}
.clearCookiesBtn:hover {
  background: var(--primary);
  color: var(--white);
}
.clearCookiesBtn:hover svg {
  color: var(--white) !important;
}

.popoverInfoOne {
  padding: 0px 10px 0px 0px;
}
.popoverInfoOne ul {
  list-style-type: none;
  padding-inline-start: 10px;
  // cursor: pointer;
}
.popoverInfoOne ul li {
  line-height: 2.5em;
  cursor: default;
}
.popoverInfo {
  background-color: #fae7e78f;
  padding-left: 7px;
  margin-top: -16px;
  position: relative;
}
.popoverInfoLeftSpan {
  min-width: 29%;
  float: left;
  font-size: 12px;
  font-weight: 600;
}
.popoverInfoUl li {
  clear: both;
}

.popoverInfo ul {
  list-style-type: none;
  padding-inline-start: 10px;
  // cursor: pointer;
  padding-bottom: 10px;
  // font-weight: 600;
}
.profileConatiner ul {
  list-style-type: none;
  padding-inline-start: 10px;
}

.popoverInfoSpanVal {
  font-weight: 500;
  font-size: 12px;
  color: var(--pureBlack);
}
.popoverInfoSpanValExt {
  font-size: "16px";
  margin-bottom: "10px";
  font-style: normal;
  font-family: $rogers-font;
  vertical-align: top;
  color: var(--disabled);
  font-size: 16px;
}
.liFirstSpan {
  float: left;
  min-width: 17%;
  filter: invert(51%) sepia(7%) saturate(543%) hue-rotate(166deg)
    brightness(86%) contrast(93%);
  padding-left: 7px;
}
.liFirstSpanPref {
  width: 20px;
}
.noLegends {
  color: var(--pureBlack);
  cursor: default;
}

.recoBtnCont {
  text-align: center;
  margin: 15px 0px;
}

//Media Queries
@media screen and (max-width: 689px) and (min-width: 320px) {
  .overviewContainer {
    display: block;
  }
  .accountInfoCont {
    width: 100%;
  }
  .techSupportCont {
    width: 100%;
  }
  .troubleShootCardOne,
  .scribblePadCard {
    margin-left: 0px;
  }
  .scribblePadCard {
    width: 100%;
  }
  .custBasicInfo {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .custBasicInfo p {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .custBasicInfo div {
    width: auto;
  }
}

@media screen and (max-width: 1005px) and (min-width: 690px) {
  .overviewContainer {
    display: block;
  }
  .accountInfoCont {
    width: 100%;
  }
  .techSupportCont {
    width: 100%;
  }
  .troubleShootCardOne,
  .scribblePadCard {
    margin-left: 0px;
  }
  .scribblePadCard {
    width: 100%;
  }
}
