@import "../globle/css-variables.scss";

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.checkboxContainer label {
  flex: 1;
  margin-right: 30px;
  font-size: 15px;
}
.titleLabel {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 16px;
}
.txtFieldLabel {
  font-weight: 600;
  font-size: 16px;
}

.datePickerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.datePickerContainer .itemContainer {
  width: 200px;
  margin-bottom: 20px;
}

.viewEPRContainer {
  align-self: center;
  margin: 25px;
  min-width: 450px;
}

.viewEPRContainer .titleLabel {
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.viewERPContainer .textLabel {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--oceanBlue10);
}
.imgContainer .dashedLine {
  border-top-style: dashed;
  border-color: var(--greyLight);
  border-top-width: 2px;
  width: 90%;
  margin-bottom: 25px;
}

.exceptionBoxCont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
