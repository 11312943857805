@import "../globle/css-variables.scss";

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.checkboxContainer label {
  flex: 1;
  margin-right: 30px;
  font-size: 15px;
}

.titleLabel {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 16px;
}

.radioBtnContainer {
  align-items: center;
}
.radioBtnContainer label {
  font-size: 15px;
  margin: 0 0 5px 10px;
}

.viewLOPContainer {
  align-self: center;
  margin: 25px;
}
.viewLOPContainer .titleLabel {
  font-weight: 700;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.viewLOPContainer .textLabel {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--oceanBlue10);
}
.imgContainer .dashedLine {
  border-top-style: dashed;
  border-color: var(--greyLight);
  border-top-width: 2px;
  width: 90%;
  margin-bottom: 25px;
}

.custTypeCont {
  display: flex;
  align-items: center;
}

.custTypeCont .labelTxt {
  margin-right: 10px;
}

.custTypeCont .workOrderIDContainer {
  width: 20%;
}

.emailDataCont {
  display: flex;
  justify-content: center;
}
