.RiserRepairContainer {
    color: var(--pureBlack);
}

.RiserRepairContainer #txtFieldLabel {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 5px;
}

.dropDownContainer {
    width: 180px;
}

.editBtn {
    cursor: pointer;
    color: rgb(0, 0, 255);
    margin-left: 40%;
}

.editBtn:hover {
    opacity: 50%;
}


.RiserRepairContainer #dropdownBtn {
    background: transparent;
    color: var(--pureBlack);
    border: 1px solid var(--greyLight);
    border-radius: 5px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 6px 12px;
    margin-bottom: 8px;
  }

  .RiserRepairContainer .orgUnitDropDown #dropdownBtn {
    max-width: 200px;
    overflow-x: hidden;
  }

.formContainer {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
}



.viewRRFContainer {
    align-self: center;
    margin: 25px;
    justify-content: space-between;
  }


.viewRRFContainer .titleLabel {
  font-weight: 700;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.viewRRFContainer .textLabel {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}