@import "../globle/css-variables.scss";

.historyRootContainer {
  background-color: var(--greyLight);
}

.filterRootContainer {
  padding: 10px;
  display: flex;
  border-radius: 5px;
  border-width: 2px;
  flex-direction: "row";
  font-family: $rogers-font;
  border-color: var(--greyLight);
  background-color: var(--white);
}

.boxTitleContainer {
  padding: 10px;
  display: flex;
  border-radius: 5px;
  border-width: 2px;
  flex-direction: "row";
  font-family: $rogers-font;
  border-color: var(--greyLight);
  background-color: var(--white);
  font-weight: 600;
  font-size: 12px;
}

.filterTitle {
  font-size: 14px;
  font-weight: 600;
  color: var(--pureBlack);
}

.filterContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
}

.filterRow {
  display: flex;
  flex-direction: "row";
  margin-top: 1px;
  align-items: center;
}

.filterRowItem {
  display: grid;
  flex-direction: "column";
}

.filterBtn {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterSwapBtn {
  flex: 2;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 10px;
  padding-left: 10px;
}

.topSearchBtn {
  flex: 2;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-top: 10px;
  margin-right: 20px;
}

.applyFilersBtn {
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
}

.inputContainer {
  width: 250px;
  margin-right: 10px;
}

.freqContainer {
  width: 450px;
  margin-right: 10px;
}

.intervalContainer {
  width: 50px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.inputField {
  font-family: "Ted Next";
  font-size: 12px;
  color: var(--pureBlack);
  padding-left: 5px;
  font-weight: auto;
  border-radius: 5px;
  border: 1px solid var(--greyLight);
}
.rpdInputField {
  font-family: "Ted Next";
  font-size: 12px;
  color: var(--pureBlack);
  padding-left: 5px;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid var(--greyMedium);
}

div.testTypeCont #testTypeDd {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyLight);
  font-weight: 400;
  justify-content: space-between;
  display: flex;
  margin-right: 20px;
  width: 250px;
  margin-bottom: 15px;
  align-items: center;
}

div.deviceTypeCont #deviceTypeDd {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyMedium);
  font-weight: 400;
  justify-content: space-between;
  display: flex;
  margin-right: 20px;
  width: 250px;
  margin-bottom: 15px;
  align-items: center;
}

div.deviceTypeFreqCont #deviceTypeFreqDd {
  background: transparent;
  color: var(--pureBlack);
  border: 1px solid var(--greyMedium);
  font-weight: 400;
  justify-content: space-between;
  display: flex;
  margin-right: 20px;
  width: 450px;
  margin-bottom: 15px;
  align-items: center;
}

div.testTypeDd .dropdown-menu {
  width: 100%;
}

div.deviceTypeDd .dropdown-menu {
  width: 100%;
}

div.testTypeDropd .dropdown-menu {
  max-height: 130px;
}

div.deviceTypeDropd .dropdown-menu {
  max-height: 130px;
}

.dateContainer {
  display: flex;
  flex-direction: "row";
  width: 300px;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
}

.dateInnerView {
  width: 145px;
}

.fromToRootContainer {
  padding: 10px;
  display: flex;
  flex-direction: "row";
  font-family: $rogers-font;
  background-color: var(--white);
}

.fromToDate {
  border-radius: 5px;
  margin-top: 0px;
  border: 1px solid var(--greyLight);
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.fromToDate:hover {
  color: var(--primary);
  border: 1px solid var(--primary);
}

.fromToDateLabel {
  font-size: 14px;
  font-weight: 400;
  color: var(--greyMedium);
}

//////history
.historyRootContainer {
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border-width: 2px;
  flex-direction: "row";
  font-family: $rogers-font;
  border-color: var(--greyLight);
  background-color: var(--white);
}

.loader {
  padding-top: 100px;
}

.historyResultCont {
  margin: 0px 0px;
}

.tableTopCont {
  margin: 10px 10px 10px 10px;
  padding: 10px 10px 10px 10px;
  font-weight: 600;
  font-size: 14px;
}

.activationInputContainer {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  border-radius: 5px;
  border-width: 2px;
  flex-direction: "row";
  font-family: $rogers-font;
  border-color: var(--greyLight);
  background-color: var(--white);
}


.cardContentLabel {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  font-weight: 600;
  font-size: 12px;
}

.cardContentText {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  font-weight: 400;
  font-size: 14px;
  
}

.cardContentContainer {
  width: 150px;
  margin-right: 20px;
}

.phubContainer {
  width: 200px;
  margin-right: 20px;
}

.swVersionContainer {
  width: 250px;
  margin-right: 20px;
}

.cardAccessContentContainer {
  width: 220px;
  margin-right: 20px;
}

.cardNodeInputContainer {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  display: flex;
  border-radius: 5px;
  border-width: 2px;
  flex-direction: "row";
  font-family: $rogers-font;
  border-color: var(--greyLight);
  background-color: var(--white);
}

.titleLabel {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  font-size: 12px;
}

.titleCard {
  padding-top: 10px;
  font-weight: 600;
  font-size: 12px;
}

.subDataCard {
  padding-top: 25px;
  font-weight: 600;
  font-size: 12px;
}

.inputChipContainer {
  margin-left: 0px;
  margin-right: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.titleChipCard {
  padding-top: 5px;
}
.rpdRootContainer {
  padding: 10px;
  display: flex;
  border-radius: 5px;
  border-width: 2px;
  flex-direction: "row";
  font-family: $rogers-font;
  border-color: var(--greyLight);
  background-color: var(--white);
}
.rpdContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
}
.rpdRow {
  display: flex;
  flex-direction: "row";
  margin-top: 1px;
  align-items: center;
}
.rpdInputContainer {
  width: 250px;
  margin-right: 10px;
}
.inputDeviceContainer {
  width: 150px;
  margin-right: 10px;
}
.rpdFormLabel {
  font-size: "12px";
  font-weight: 500;
  margin: "0px";
  color: var(--pureBlack);
}