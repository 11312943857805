:root {
  --primary: #da291c;
  --primary10: #fae7e78f;
  --primary20: rgba(218, 41, 28, 0.2);
  --danger: #dc3545;
  --error: #dc3545;
  --success: #00985f;
  --warning: #ffbf3f;
  --skip: #e77c40;
  --background: #f2f2f2;
  --backgroundLight: #f2f2f2bf;
  --pureBlack: #000000;
  --black50: #00000080;
  --black75: #000000bf;
  --black10: #0000001a;
  --greyLight: #bbbdc0;
  --greyMedium: #808284;
  --greyDark: #414042;
  --brightGray: #3c4257;
  --infoCurtain: #8ac3ff;
  --skyBlue: #4782e5;
  --purple: #9b4e9b;
  --pink: #fdadad;
  --primary10: #da291c1a;
  --lightGreen: #28a820;
  --disabled: #6c757d;
  --white: #ffffff;
  --creamWhite: #fafafa;
  --veryGood: #28a745;
  --good: #28a745;
  --marginal: #ffc107;
  --link: #1a73e9;
  --maroonRed: #a72856;
  --warningCurtainText: #63480c;
  --warningCurtain: #f9f0db;
  --oceanBlue: #079de6;
  --oceanBlue10: #d9f0f8;
  --iconColor: #5f6368;
  --orange: #fc9003;
  --clickService: #3984c4;
  --clickService60: rgba(57, 132, 196, 0.6);
  --CPAT: #6bd030;
  --nika: #fc9003;
  --nika60: rgba(252, 144, 3, 0.6);
  --orange: #fc9003;
  --success50: #00985f80;
  --danger50: #dc354580;
  --warning50: #ffbf3f80;
  --headerHeight: 43px;
  --lightBlue: #3BE6FF;
  //Topology table data font weight
  --topoTHFN: 650;
  --topoTDFN: 600;
}

$rogers-font: Ted Next !default;
$drawer-open-width: 15%;
$drawer-close-width: 5%;
$header-height: 43px;
