@import "../globle/css-variables.scss";

div.stbBasicDetails {
  display: flex;
  background-color: var(--background);
  justify-content: space-between;
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  border-bottom: 2px solid var(--greyLight);
}

section.nodeDetailsCont,
section.nodeDetailsCont div,
section.teleActionCont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

section.nodeDetailsCont div.teleScores p {
  min-width: 150px;
}

section.nodeDetailsCont hr {
  height: 15px;
}
section.nodeDetailsCont p {
  margin-bottom: 0px;
  min-width: 100px;
}
section.nodeDetailsCont p span,
section.teleActionCont span:first-child {
  margin-right: 5px;
}

section.nodeDetailsCont p span:first-child,
section.teleActionCont span:first-child {
  font-weight: 600;
}

section.teleActionCont .custDDBtn,
section.teleActionCont .refreshCertiBtn {
  font-size: 13px;
}

div.stbTeleTabCont {
  background: var(--background);
  padding: 5px;
  //overflow-x: hidden;
}

div.stbTeleTabCont .nodeFilterCont {
  padding: 0px;
}

div.stbTeleTabCont .nodeFilterCont button.custDDBtn {
  font-size: 12px;
}
div.stbTeleTabCont .dTabBody span.failLinkState {
  cursor: pointer;
}

.stbTelemetryTable th {
  font-size: 12px;
}
.stbTelemetryTable th div.stbCellHeaderContainer {
  line-height: 1.2;
  justify-content: space-between;
}
.stbTelemetryTable th div.stbCellHeaderContainer span {
  text-align: left !important;
}
.stbTelemetryTable td {
  font-size: 12px;
  vertical-align: top;
  font-weight: 500;
  padding: 1px 5px;
  color: var(--pureBlack);
}

.stbTelemetryTable td .cellNewLine {
  white-space: pre-line;
}

.stbTelemetryTable thead {
  box-shadow: 1px 1px 4px -2px;
}

.stbTelemetryTable thead th {
  padding: 1px 5px;
  font-weight: 700;
  border-bottom: 0;
  z-index: 1;
}
.stbTelemetryTable thead th.specAvailable {
  padding: 1px 5px;
  font-weight: 700;
  border-bottom: 0;
  z-index: 1;
}
.stbTelemetryTable thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}

.stbTelemetryTable .dTabBody td:first-child,
.stbTelemetryTable .dTabBody td:last-child {
  padding: 1px 5px !important;
}

.stbTelemetryTable tbody tr:nth-child(even) {
  background-color: var(--black10);
}
.stbTelemetryTable tbody tr:nth-child(odd) {
  background-color: var(--backgroundLight);
}

.stbTelemetryTable .dTabBody {
  min-height: 50vh;
}
.stbTelemetryTable .dTabBody td:first-child {
  position: sticky;
  left: 0;
}
.stbTelemetryTable tbody tr:nth-child(even) td:first-child {
  background-color: #e5e5e5;
}
.stbTelemetryTable tbody tr:nth-child(odd) td:first-child {
  background-color: var(--background);
}
.stbTelemetryTable .dTabBody tr {
  height: auto;
  white-space: nowrap;
}

div.stbTeleTabCont button.telemetryClearFlapsBtn {
  background-color: var(--background);
  font-size: 14px;
  margin-right: 0px;
}

div.stbAveragesCont p {
  margin-bottom: 10px;
  font-size: 14px;
}
div.stbAveragesCont p span:first-child {
  font-weight: 600;
  margin-right: 5px;
}
table.stbTelemetryTable thead .custDDCont {
  margin: 0px;
  display: block;
}
table.stbTelemetryTable thead button.custDDBtn {
  display: none;
  padding: 0px;
}
table.stbTelemetryTable thead button.custDDBtn span {
  display: none;
}
/* table.stbTelemetryTable thead .custDDCont .custDDOptionsCont {
  right: auto !important;
} */
