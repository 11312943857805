@import "../globle/css-variables.scss";.filemanager {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f2f2f2;
  font-family: $rogers-font;
  flex: 1;
}

.filemanager > * {
  margin: 10px 10px 0px 0px;
}
.filemanager-header {
  display: flex;
  align-items: center;
  margin: 20px 20px 0px 20px;
}
.navBarContainer {
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
}

.navBarContainer > * {
  margin: 10px 10px 0px 0px;
}
.navBarCard {
  min-width: 150px;
}

.folderNavigationBar {
  margin: 10px;
  width: 25%;
}
.filemanager-content {
  display: flex;
  flex: 1;
  align-items: flex-end;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
}

.filemanager-content > .MuiCard-root {
  width: 100%;
}
.filemanager-content .refreshCont {
  min-height: 46px;
}

.filemanager-file-row {
  word-wrap: break-word;
  min-width: 100px;
  font-weight: 500;
}

.filemanager-table,
.filemanager-table tr,
.filemanager-table th,
.filemanager-table td {
  padding: 10px;
  border: none;
}

.filemanager-table th {
  font-weight: 800;
  font-size: 14px;
}

//FILE UPLOAD
.fileUploadTopContainer {
  display: flex;
  flex-direction: column;
  margin: 15px;
}
.fileUploadContainer {
  background-color: var(--white);
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 6px 8px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 100%;
}
.fileUploadContainer span.MuiBadge-root {
  display: flex;
}
.fileUploadContainer span.MuiBadge-badge {
  padding: 0px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.fileUploadContainer .uploadSection {
  width: 50px;
  border-right: 1px solid var(--greyLight);
}
.fileUploadContainer .uploadSection button {
  border: none;
  background-color: transparent;
  height: 50px;
  width: 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.fileUploadContainer .uploadSection button svg {
  display: inline-block;
  vertical-align: middle;
}
.fileUploadContainer .previewContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0.75rem;
  font-size: 14px;
  color: var(--gray);
}
.fileUploadContainer .previewContainer img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 3px;
}
.fileUploadContainer .previewContainer .badgeContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  justify-content: space-between;
  min-width: 250px;
  padding-right: 10px;
  margin: 5px;
}

.fileUploadContainer .previewContainer .badgeContent label {
  max-width: 200px;
  word-wrap: break-word;
}

.mandatoryTitle {
  margin: 5px;
}

.filemanager-upload {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 20px;
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  width: 100%;
}

.uploadButton {
  font-weight: 600;
  margin-bottom: 10px;
}

.fileUploadPopUp {
  max-width: 29vw !important;
  min-width: 200px !important;
}
.uploadBody {
  display: flex;
  flex-direction: column;
  margin-left: 3vw;
  margin-right: 3vw;
}
// SIMPLE LIST VIEW

.simpleListView {
  display: flex;
  flex-direction: column;
}

.fileSearch {
  border: 1px solid var(--primary);
  border-radius: 20px;
  padding-left: 10px;
  width: 60%;
  margin: 5px;
}

.fileSearch input {
  height: 25px;
  padding: 0px;
}

.nameHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.reloadButton {
  margin-bottom: 10px;
}

.file-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 900px) and (min-width: 544px) {
  .navBarCard {
    width: 612px;
  }
}
@media screen and (max-width: 544px) and (min-width: 320px) {
  .fileSearch {
    float: none;
    width: auto;
    margin: 10px;
  }
  .filerWdt {
    padding: 5px 15px;
  }
  .fileList {
    margin-top: 10px;
  }

  .navBarCard {
    width: 100%;
  }
}
