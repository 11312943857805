@import "../../../../design-tokens/globle/css-variables.scss";

.nikaTypeSelectionCont {
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.nikaFilterTabCont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.selectionChipsCont {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  flex-wrap: wrap;
  align-self: flex-start;
  padding: 5px;
}

.radiusInputCont {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nikaRadiusLabel {
  font-weight: 600;
  margin: 0px;
  margin-right: 10px;
}

.nikaMarkerLabel {
  font-weight: 600;
}

.nikaClusterMarkerLabel {
  font-weight: 700;
}

.nikaSearchFilter {
  display: flex;
  align-items: center;
  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 3px 5px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.nikaSearchFilter input {
  outline: none;
  border: none;
  font-size: 13px;
}