@import "./design-tokens/globle/css-variables.scss";
@font-face {
  font-family: "Ted Next";
  src: url(./resources/fonts/TedNext-Light.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Ted Next";
  src: url(./resources/fonts/TedNext-LightItalic.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Ted Next";
  src: url(./resources/fonts/TedNext.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Ted Next";
  src: url(./resources/fonts/TedNext-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Ted Next";
  src: url(./resources/fonts/TedNext-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Ted Next";
  src: url(./resources/fonts/TedNext-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Ted Next";
  src: url(./resources/fonts/TedNext-SemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Ted Next";
  src: url(./resources/fonts/TedNext-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Ted Next";
  src: url(./resources/fonts/TedNext-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
body {
  margin: 0;
  font-family: $rogers-font !important;
  color: var(--pureBlack) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
